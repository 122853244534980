import React, { useState, useRef, useEffect, Fragment } from "react";
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";
import "./hosppatientrecord.css";

import { useParams, useNavigate, Link, useLocation, } from "react-router-dom";
import DatePicker from "react-date-picker";
import axios from "axios";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import hospitalbed from "../../assets/images/icons/hospital-room.png";
import { Card, ListGroup, ListGroupItem } from "react-bootstrap";
import "./reacttable.css";
import moment from "moment";
import AddBp from "../vitals/AddBp";
import AddSp from "../vitals/AddSp";
import AddPulse from "../vitals/AddPulse";
import AddTemp from "../vitals/AddTemp";
import VisitorUpdate from "./VisitorUpdate";
import AddSugar from "../vitals/AddSugar";
import GlucoseVitals from "../vitals/GlucoseVitals";
import VitalsBP from "../vitals/VitalsBP";
import VitalsChol from "../vitals/VitalsChol";
import VitalsSp from "../vitals/VitalsSp";
import VitalsPulse from "../vitals/VitalsPulse";
import VitalsTemp from "../vitals/VitalsTemp";
import Inference from "./Inference/Inference";
import ProcedureHistory from "./ProcedureHistory";
import Chronic from "../HospSearchPatient/Chronic/Chronic";
import AddObs from "../Observations/AddObs";
import ViewObs from "../Observations/ViewObs";
import AddSymptoms from "../Symptoms/AddSymptoms";
import ViewSymptoms from "../Symptoms/ViewSymptoms";
import AddAller from "../Allergy/AddAller";
import Comorbidities from "../HospSearchPatient/Como/Comorbidities";
import Diagnosis from "../Findings/Diagnosis";
import ViewAller from "../Allergy/ViewAller";
import EditRemark from "./EditRemark";
import AddDetails from "./AddDetails";
import { useAlert } from "react-alert";
import WeightAdd from "./WeightAdd";
import HeightAdd from "./HeightAdd";

import { APIURL } from "../../Global";
import { Suspense } from "react";
import AddCholestrol from "../vitals/AddCholestrol";
import AddBmi from "../vitals/AddBmi";
import VitalsBmi from "../vitals/VitalsBmi";

import AddRemarkReport from "./AddRemarkReport";
import AddVisitor from "./AddVisitor";
import TreatPlan from "./TreatmentPlan/TreatPlan";
import BloodAdd from "./BloodAdd";
import Therapy from "./Therapy";
import TestScan from "./TestScan/TestScan";
import Procedure from "./Procedure/Proceduure";
import CallforActionPat from "../doctor/CallforActionPAt";
import MaritalStatus from "./MaritalStatus";
function PatientRecord(props) {
const login_datas = JSON.parse(sessionStorage.getItem("logindatas"))
    ? JSON.parse(sessionStorage.getItem("logindatas"))
    : null;
  const [imgErrorShow, setImgErrorShow] = useState(false);
  const [height, setHeight] = useState();
  const [heightnew, setHeightNew] = useState();
  const [blood, setBlood] = useState();
  const [bloodnew, setBloodNew] = useState();
  const [maritalStatus, setMaritalStatus] = useState(false);
  const [addVisitor, setAddVisitor] = useState(false);
  const [weight, setWeight] = useState();
  const [weightnew, setWeightNew] = useState();
  const [age, setAge] = useState();
  const [summary, setSummary] = useState("");
  const [addChol, setAddCholestrol] = useState(false);
  const [historyEditShow, setHistoryEditShow] = useState(false);
  const [medEditShow, setMedEditShow] = useState(false);
  const [flag, setFlag] = useState(0);
  const [visit1, setVisit1] = useState(false);
  const [ip, setIP] = useState(true);
  const [therapy, setTherapy] = useState(false);
  const [treat, setTreat] = useState(false);
  const [testScan, setTestScan] = useState(false);
  const [pro, setPro] = useState(false);
  const [flag2, setFlag2] = useState(0);
  const [complaintEditShow, setComplaintEditShow] = useState(false);
  const [historyAddShow, setHistoryAddShow] = useState(false);
  const [medAddShow, setMedAddShow] = useState(false);
  const [complaintAddShow, setComplaintAddShow] = useState(false);
  const [addBmi, setAddBmi] = useState(false);
  const [bmiShow, setBmiShow] = useState(false);
  const [familyData, setFamilyDatas] = useState("");
  const [famHistory, setFamHistory] = useState();
  const [medHistory, setMedHistory] = useState();
  const location = useLocation();
  const { showTherapy } = location.state || {};
  // var matches = location.pathname.match(/\/id\/([0-9]+)\/mob\/([0-9]+)/);
  const id = location.pathname.match(/\/id\/([0-9]+)/)[1];
 const mob = location.pathname.split('/').pop()?location.pathname.split('/').pop():""
  const alert = useAlert();
  const [refresh, setRefresh] = useState(false);
  const [observationsShow, setObservationsShow] = useState(false);
  const [userDetails, setuserDetails] = useState();
  const [name, setName] = useState();
  const [gender, setGender] = useState();
  const [imgUrl, setImgUrl] = useState();
  const [weightAddShow, setWeightAddShow] = useState(false);
  const [heightAddShow, setHeightAddShow] = useState(false);
  const [bloodAddShow, setBloodAddShow] = useState(false);
  const [maritalAddShow, setMaritalAddShow] = useState(false);
  const [procedureUpdate, setProcedureUpdate] = useState(false);
  const [isOnline, setIsOnline] = useState(false);
  const [next_visit, setNext_visit] = useState("");
  const [visit, setVisit] = useState("");
  const [next, setNext] = useState(null);
  const [addPulse, setAddPulse] = useState(false);
  const [addTemp, setAddTemp] = useState(false);
  const [addSp, setAddSp] = useState(false);
  const [addBp, setAddBp] = useState(false);
  const [addSugar, setAddSugar] = useState(false);
  const [rptImg, setRptImg] = useState();
  const [cellid, setcellId] = useState();
  const [rptModalShow, setRptModalShow] = useState(false);
  const [item1, setItem] = useState([]);
  const [visitorDatas, setVisitorDatas] = useState([]);
  const [followUP, setFollowUP] = useState([]);
  const [assignData, setAssignData] = useState([]);
  const [visitorDatas1, setVisitorDatas1] = useState([]);
  const [addShow, setAddShow] = useState(false);
  const [viewShow, setViewShow] = useState(false);
  const [remarkViewShow, setRemarkViewShow] = useState(false);
  const [detailData, setDetailData] = useState("");
  const [editSuccess, setEditSuccess] = useState(false);
  const [sizeShow, setSizeShow] = useState(false);
  const [validShow, setValidShow] = useState(false);
  const [contentShow, setContentShow] = useState(false);
  const [uploadShow, setUploadShow] = useState(false);
  const [searchAssign, setSearchAssign] = useState("");
  const [displayDoctor, setDisplayDoctor] = useState(false);
  const [doctorId, setDoctorId] = useState("");
  const [doctorName, setDoctorName] = useState("");
  const [assignList, setAssignList] = useState([]);

  const [submitMsg, setSubmitMsg] = useState("");
  const [submitMsg1, setSubmitMsg1] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [deleteNextMsg, setDeleteNextMsg] = useState("");
  const [procedureMsg, setProcedureUpdateMsg] = useState("");
  const [procedureShow, setProcedureShow] = useState(false);
  const [render, setRender] = useState(false);
  const [reRender, setReRender] = useState(false);

  
 
  const [deleteNextVisitConfirmMsg, setDeleteNextVisitConfirmMsg] =
    useState("");
  const [deleteNextVisitConfirmModalShow, setDeleteNextVisitConfirmModalShow] =
    useState(false);
  const [errorSubmit, setErrorSubmit] = useState(false);
  const [errorSubmit1, setErrorSubmit1] = useState(false);
  const [glucoseShow, setGlucoseShow] = useState(false);
  const [bpShow, setBpShow] = useState(false);
  const [pulseShow, setPulseShow] = useState(false);
  const [tempshow, setTempShow] = useState(false);
  const [spshow, setSpShow] = useState(false);
  const [cholesterolShow, setCholesterolShow] = useState(false);
  const [inferenceShow, setInferenceShow] = useState(false);
  const [observationsArray, setObservationsArray] = useState([]);
  const [symptoms, setSymptoms] = useState([]);
  const [symptomsShow, setSymptomsShow] = useState(false);
  const [timing] = useState(200);
  const [isBooped, setIsBooped] = useState(false);
  const [allergyArray, setAllergyArray] = useState([]);
  const [allergyShow, setAllergyShow] = useState(false);
  const [errorNextModalShow, setErrorNextModalShow] = useState(false);
  const [nextErr, setNextErr] = useState("nil");
  const [messagesShow, setMessagesShow] = useState(false);
  const [procedureRefresh, setProcedureRefresh] = useState(false);
  const [scanRefresh, setScanRefresh] = useState(false);
 const[refreshVisitor,setRefreshVisitor]=useState(false);
 const userData = JSON.parse(sessionStorage.getItem("logindatas"));
 const role = userData.role

  const [errorMessage, setErrorMessage] = useState("");
  const [features, setFeatures] = useState(JSON.parse(sessionStorage.getItem("features")))
  const navigate = useNavigate();
   const [cardView, setCardView] = useState(false);
  const [cartEditView, setCardEditView] = useState(false);
  const visitorId = visitorDatas.length > 0 ? visitorDatas[0].id : null;
  const ref1 = useRef();
  const ref3 = useRef();
  const ref5 = useRef();
  const ref6 = useRef();
  const ref7 = useRef();
  const ref8 = useRef();
 const reference=location.state?location.state.ref:""
  useEffect(() => {
    if (showTherapy) {
        setTherapy(true);
        setIP(false);
    } else {
        setTherapy(false);
        setIP(true);
    }
}, [showTherapy]);
  
  useEffect(() => {
    
    const handleKeyDown = (e) => {
      if (e.altKey && e.key === 'c') {
          e.preventDefault()
          setObservationsShow(false);
          setSymptomsShow(false)
          setAllergyShow(false)
          setAddSugar(false)
          setAddBmi(false)
          setAddCholestrol(false)
          setAddBp(false)
          setAddPulse(false)
          setAddTemp(false)
          setAddSp(false)
          setGlucoseShow(false)
          setBmiShow(false)
          setCholesterolShow(false)
          setBpShow(false)
          setPulseShow(false)
          setTempShow(false)
          setSpShow(false)
      }
    
    }
    document.addEventListener("keydown", handleKeyDown);
    
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    }
    
  }, []);
  const generateIcon = (name) => {
    const initials = name
      .split(" ")
      .map((word) => word[0])
      .join("")
      .toUpperCase();

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="45"
        height="45"
        viewBox="0 0 45 45"
        fill="none"
      >
        <circle
          cx="22.5"
          cy="22.5"
          r="17.5"
          stroke="rgb(60, 104, 158)"
          stroke-width="2"
          fill="none"
        />

        <circle cx="22.5" cy="22.5" r="17.5" fill="white" />

        <text
          x="50%"
          y="50%"
          dominantBaseline="middle"
          textAnchor="middle"
          fontSize="14"
          fontWeight="bold"
          fill="black"
        >
          {initials}
        </text>
      </svg>
    );
  };

  const [historyShow, setHistoryShow] = useState(false);

 
  const handleClickAssign = () => {
    navigate("/assignpatient", {
      state: { name: name, id: id, age: age, gender: gender,reassign:true,mob: mob },
    });
  };
  const handleWound = () => {
    navigate("/wound_report", {
      state: {from:"pr", name: name, id: id, gender: gender, woundId: "", mobile_number: mob, room: assignData ? assignData.bedunit_code : "" },
    });
  };
  useEffect(() => {
   
    allDatas();
   
  }, [scanRefresh]);
  useEffect(() => {
    setTimeout(() => {
   if(reference==="ref1"){
    handleClick(ref1);
   }
   if(reference==="ref3"){
    handleClick(ref3);
   }
   if(reference==="ref5"){
    handleClick(ref5);
   }
   if(reference==="ref6"){
    handleClick(ref6);
   }
   else if(reference==="ref8"){
    handleClick(ref8);
   }
   else if(reference==="ref7"){
    handleClick(ref7);
   }
  }, 500);
  }, []);
  const handleVisitorView = () => {

    if (visitorId) {
      const tokenString = sessionStorage.getItem("usertoken");
      let str = tokenString.replace(/["]+/g, "");

      axios
        .get(`${APIURL}/api/v1/service-provider/visitor-detail/${visitorId}`, {
          headers: {
            Authorization: "Token " + str,
          },
        })
        .then((res) => {
          if (res.status == 200) {
            setVisitorDatas1(res.data.visitor);
            setCardView(true);
          } else {
            alert.error("error");
          }
        });
    } else {
      alert.error("Family Member Details Not Added");
    }
  };
  const [linkDisabled, setLinkDisabled] = useState(false);
  const [todayDate, setTodayDate] = useState(
    new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000)
      .toISOString()
      .split("T")[0]
  );
 

  useEffect(() => {
    if (visit === todayDate) {
      if (isOnline === true) {
        setLinkDisabled(true);
      } else {
        setLinkDisabled(false);
      }
    } else {
      setLinkDisabled(false);
    }
  }, [visit, isOnline]);
 
  const reader = new FileReader();

  const setDefaultDoctor = () => {
    const hosp_doc_data = sessionStorage.getItem("hosp_doc_data");
    if (hosp_doc_data) {
      const { doctor_name, user_id } = JSON.parse(hosp_doc_data);
      setDoctorId(user_id);
      setDoctorName(doctor_name);
    }
  };
  let track = null;

  useEffect(() => {

    const tokenString = sessionStorage.getItem("usertoken");
    let str = tokenString.replace(/["]+/g, "");

    axios
      .get(`${APIURL}/api/v1/service-provider/assign-room/?patient=${id}`, {
        headers: {
          Authorization: "Token " + str,
        },
      })
      .then((res) => {
        if (res.status == 200) {
          setAssignData(res.data.data);
        }
      });

  }, []);

  useEffect(() => {
    if (login_datas.user_type === "hospital_doctor" || login_datas.user_type === "hospital_allied_prof"||login_datas.user_type === "hospital_nurse") {
      const tokenString = sessionStorage.getItem("usertoken");
      let str = tokenString.replace(/["]+/g, "");

      axios
        .get(`${APIURL}/api/v1/doctor/allergies-list/?patient_id=${id}`, {
          headers: {
            Authorization: "Token " + str,
          },
        })
        .then((res) => {
          if (res.status == 200) {
            setAllergyArray(res.data.message);
          }
        });
    }
  }, [refresh]);

  useEffect(() => {
    const tokenString = sessionStorage.getItem("usertoken");
    let str = tokenString.replace(/["]+/g, "");

    axios
      .get(`${APIURL}/api/v1/doctor/follow-up/?patient_id=${id}`, {
        headers: {
          Authorization: "Token " + str,
        },
      })
      .then((res) => {
        if (res.status == 200) {
          setFollowUP(res.data.follow_up);
        }
      });
  }, []);

  useEffect(() => {

    const tokenString = sessionStorage.getItem("usertoken");
    let str = tokenString.replace(/["]+/g, "");

    axios
      .get(`${APIURL}/api/v1/service-provider/visitor/`, {
        headers: {
          Authorization: "Token " + str,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          const filteredData = res.data.filter(
            (item) => item.related_patient === parseInt(id)
          );

          setVisitorDatas(filteredData);
        }
      });
  }, [refreshVisitor]);

  const handleVisit = () => {
    setVisit1(true);
    setIP(false);
    setTreat(false);
    setPro(false);
    setTestScan(false)
    setTherapy(false);
  };
  const handleIP = () => {
    setIP(true);
    setVisit1(false);
    setTreat(false);
    setPro(false);
    setTestScan(false)
    setTherapy(false);
  };
  const handleTherapy = () => {
    setTherapy(true);
    setIP(false);
    setVisit1(false);
    setTreat(false);
    setPro(false);
    setTestScan(false)
  };
  const handleTreat = () => {
    setTreat(true);
    setIP(false);
    setVisit1(false);
    setPro(false);
    setTestScan(false)
    setTherapy(false);
  };
  const handlePro = () => {
    setPro(true);
    setTreat(false);
    setIP(false);
    setVisit1(false);
    setTherapy(false);
    setTestScan(false)

  };
  const handleTestScan = () => {
    setTreat(false);
    setIP(false);
    setVisit1(false);
    setPro(false);
    setTestScan(true)
    setTherapy(false);
  };

  const handleVisitor = (e) => {
    setAddVisitor(true);
  };

  const handleVisitorOff = () => {
    setAddVisitor(false);
    setRefreshVisitor(!refreshVisitor)
  
  };

  useEffect(() => {
    const tokenString = sessionStorage.getItem("usertoken");
    let str = tokenString.replace(/["]+/g, "");

    axios
      .get(
        `${APIURL}/api/v1/service-provider/patient-history-detail/?patient_id=${id}`,
        {
          headers: {
            Authorization: "Token " + str,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setFamilyDatas(res.data.message);
          setFamHistory(res.data.message[0].family_history);
          setMedHistory(res.data.message[0].past_medical_history);
          setWeightNew(res.data.message[0].weight);
          setBloodNew(res.data.message[0].blood_group);
          setHeightNew(res.data.message[0].height);
          setMaritalStatus(res.data.message[0].married)
        }
      });
  }, [refresh]);

  useEffect(() => {
    if (login_datas.user_type === "hospital_doctor" || login_datas.user_type === "hospital_allied_prof"||login_datas.user_type === "hospital_nurse") {
      const tokenString = sessionStorage.getItem("usertoken");
      let str = tokenString.replace(/["]+/g, "");
      axios
        .get(`${APIURL}/api/v1/patient/observations-list/?patient_id=${id}`, {
          headers: {
            Authorization: "Token " + str,
          },
        })
        .then((res) => {
          if (res.status == 200) {
            setObservationsArray(res.data);
          }
        });
    }
  }, [refresh]);

  const callSaveDetails = () => {
    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");

    const data = {
      patient_id: id,
      present_complaint: familyData.present_complaint,
      past_medical_history: familyData.past_medical_history,
      family_history: familyData.family_history,
    };
    axios
      .put(
        `${APIURL}/api/v1/service-provider/patient-history-detail/?patient_id=${id}`,
        data,
        {
          headers: {
            Authorization: "Token " + str,
          },
        }
      )
      .then((res) => {
        if (res.data.status === "success") {
          alert.success("Updated Successfully")
          setRefresh(!refresh)
        }
      });
  };

 


 
  const handleObservationsOverlay = (e) => {
    e.preventDefault();
    setObservationsShow(true);
  };

  const handleAddObs = () => {
    setObservationsShow(false);
    setRefresh(!refresh);
  };
  const handleAddAllergy = () => {
    setAllergyShow(false);
    setRefresh(!refresh);
  };
  const handleAllergyOverlay = (e) => {
    e.preventDefault();
    setAllergyShow(true);
  };
  const handleCholOverlay = (e) => {
    e.preventDefault();
    setCholesterolShow(true);
  };
  const submitCholHandle = () => {
    setAddCholestrol(false);
  };
  const ComplaintAddPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddDetails update={handleSend} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
          <Button className="btn btn-primary btn-col" onClick={props.onSave}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const MedHistoryAddPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddDetails update={handleSend} />
        </Modal.Body>
        <Modal.Footer>
        
          <Button  className="btn btn-primary btn-col" onClick={props.onSave}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const ComplaintPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditRemark data={detailData} update={handleSend} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
          <Button className="btn btn-primary btn-col" onClick={props.onSave}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const HistoryAddPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddDetails update={handleSend} />
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary btn-col" onClick={props.onSave}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const handleHistoryAdd = () => {
    setHistoryAddShow(true);
  };

  const handleFamAdd = () => {
    setMedAddShow(true);
  };
  const handleMedHistory = (data) => {
    setMedEditShow(true);
    setDetailData(data);
  };

  const handleHistory = (data) => {
    setHistoryEditShow(true);
    setDetailData(data);
  };
  const MedHistoryPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditRemark data={medHistory} update={handleSend} />
        </Modal.Body>
        <Modal.Footer>
          {" "}
          {login_datas.user_type === "hospital_doctor" || login_datas.user_type === "hospital_allied_prof"||login_datas.user_type === "hospital_nurse" ? (
            <Button className="btn btn-primary btn-col" onClick={props.onSave}>
              Update
            </Button>
          ) : (
            ""
          )}
        </Modal.Footer>
      </Modal>
    );
  };
  const HistoryPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditRemark data={famHistory} update={handleSend} />
        </Modal.Body>
        <Modal.Footer>
          {login_datas.user_type === "hospital_doctor" || login_datas.user_type === "hospital_allied_prof"||login_datas.user_type === "hospital_nurse" ? (
            <Button className="btn btn-primary btn-col" onClick={props.onSave}>
              Update
            </Button>
          ) : (
            ""
          )}
        </Modal.Footer>
      </Modal>
    );
  };

  const BmiPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-80w"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <VitalsBmi {...props} />
        </Modal.Body>
      </Modal>
    );
  };

  const handleBmiOverlay = (e) => {
    e.preventDefault();
    setBmiShow(true);
  };

  const handleBmi = () => {
    setAddBmi(true);
  };

  const AddBmiPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <AddBmi id={id} submitbmi={submitBmiHandle} />
        </Modal.Body>
      </Modal>
    );
  };

 
  const handleChol = () => {
    setAddCholestrol(true);
  };

  const ProcedureUpdatePopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 style={{ color: "green" }}>{procedureMsg}</h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };


  const AllergyPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        size="md"
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <h3 className="title-of-tasks header">Allergy</h3>

          <AddAller id={id} handleAdd={handleAddAllergy} />
        </Modal.Body>
      </Modal>
    );
  };

 useEffect(() => {
    if (login_datas.user_type === "hospital_doctor" || login_datas.user_type === "hospital_allied_prof") {
      const tokenString = sessionStorage.getItem("usertoken");

      let str = tokenString.replace(/["]+/g, "");

      axios
        .get(
          `${APIURL}/api/v1/service-provider/procedure-assignee/?q=${searchAssign}`,
          {
            headers: {
              Authorization: "Token " + str,
            },
          }
        )
        .then((res) => {
          if (res.data.status === "success") {
            setAssignList(res.data.details);
            setDefaultDoctor();
          }
        });
    }
  }, [searchAssign]);

  const handleTrack = () => {
    setMessagesShow(false);
    if (track !== null) {
      track.stop();
    }
  };


  const GetReport = async () => {
    if (login_datas.user_type === "hospital_doctor" || login_datas.user_type === "hospital_allied_prof"||login_datas.user_type === "hospital_nurse") {
      const tokenString = sessionStorage.getItem("usertoken");

      let str = tokenString.replace(/["]+/g, "");
      await axios
        .get(
          `${APIURL}/api/v1/doctor/patient-documents-view/?patient_id=${id}&is_health=True&per_page=100&sort_by=desc`,
          {
            headers: {
              Authorization: "Token " + str,
            },
          }
        )
        .then((res) => {
          if (res.data.status === "success") {
            if (res.data.message.length > 0) {
              const data = res.data.results;
              const filteredData = data.filter((item) => {
                return item.id !== "";
              });

              if (filteredData.length < 1) {
                setItem([
                  {
                    id: "",
                    title: "",
                    image: "",
                    comment: "",
                    date: "",
                  },
                ]);
              } else {
                setItem(filteredData);
              }
            }
          }
        });
    }
  };
 

  const allDatas = async () => {
    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");
    await axios
      .get(`${APIURL}/api/v1/doctor/patient-dashboard/?patient_id=${id}`, {
        headers: {
          Authorization: "Token " + str,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          setuserDetails(res.data.data);
          setName(res.data.data.name);
          setAge(res.data.data.age);
          setGender(res.data.data.gender);
          setVisit(res.data.data.next_visit);
          if (res.data.data.next_visit) {
            setIsOnline(res.data.data.is_online);
          }
          if (res.data.pemission_levels[0] === "Level 1") {
            setFlag(1);
          }
          if (res.data.pemission_levels[1] === "Level 2") {
            setFlag2(2);
          }

          const today = new Date();
          const offset = today.getTimezoneOffset();
          let dateUpdated = new Date(today.getTime() - offset);
          let ot = moment.utc(res.data.data.next_visit).toDate();
          const m = moment(dateUpdated, "YYYY-MM-DD");
          var ms = moment(ot, "YYYY-MM-DD");
          let timediff = moment
            .duration(moment(ms, "YYYY-MM-DD").diff(moment(m, "YYYY-MM-DD")))
            .asDays();
          if (timediff >= 0) {
            setNext_visit(res.data.data.next_visit);
          } else {
            setNext_visit("");
          }
          setDoctorName(login_datas.doctor_name);
          setDoctorId(login_datas.user_id);
          if (res.data.data.next_visit !== null) {
            setNext(new Date(res.data.data.next_visit));
          } else {
            setNext(null);
          }
          console.log(res.data.data.photo)
          setImgUrl(res.data.data.photo);
        }
      });
   
  };
  const saveNextVisit = () => {
    const fd1 = new FormData();

    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");

    if (doctorName === login_datas.doctor_name) {
      setDoctorName(login_datas.doctor_name);
    }

    if (doctorId === "" || doctorId === null) {
      setSubmitMsg("error");
      setErrorSubmit(true);
      setErrorMessage("Select a Doctor for next visit");
    } else if (next === null) {
      setSubmitMsg("error");
      setErrorSubmit(true);
      setErrorMessage("Select a Valid Date for next visit");
    } else if (next !== null && doctorId !== null) {
      const offset = next.getTimezoneOffset();
      let dateUpdated = new Date(next.getTime() - offset * 60 * 1000);
      let DateExtracted = dateUpdated.toISOString().split("T")[0];
      fd1.append("patient_id", id);
      fd1.append("next_visit", DateExtracted);
      fd1.append("doctor_id", doctorId);
      fd1.append("is_online", isOnline);
      axios
        .post(`${APIURL}/api/v1/doctor/patient-record/`, fd1, {
          headers: {
            Authorization: "Token " + str,
            "Content-Type": "application/json",
          },
        })

        .then((res) => {
          if (res.data.status === "success") {
            alert.success(res.data.message);
            setErrorMessage("");
            setDoctorName(login_datas.doctor_name);
            setSearchAssign("");
            setSubmitMsg("");

            setProcedureRefresh(!procedureRefresh);
            setScanRefresh(!scanRefresh);
          } else {
            setSubmitMsg1("error");
            setErrorSubmit1(true);
          }
        })
        .catch((err) => {
          setSubmitMsg1("error");
          setErrorSubmit1(true);
        });
    }
  };

 
  useEffect(() => {
    GetReport();
  }, []);
  useEffect(() => {
    PatientDetails();
  }, []);

  const PatientDetails = () => {
    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");

    axios
      .get(`${APIURL}/api/v1/patient/user-details/?patient_id=${id}`, {
        headers: {
          Authorization: "Token " + str,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {

          const data = res.data.health_details;
          setWeight(data.weight);
          setHeight(data.height);
          setBlood(data.blood_group)
          setMaritalStatus(data.married);
        }
      })
      .catch((err) => {
        setSubmitMsg("error");
        setErrorSubmit(true);
      });
    setWeightAddShow(false);
    setHeightAddShow(false);
    setBloodAddShow(false)
  };

  const handleClick = (ref) => {
    ref.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };
  const SymptomsPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3 className="title-of-tasks header">Symptom</h3>
          <AddSymptoms id={id} handleAdd={handleAddSymptoms} />
        </Modal.Body>
      </Modal>
    );
  };

  const ProcedureHistoryPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-90w"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <ProcedureHistory id={id} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const InferencePopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-90w"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h2 className="title-of-tasks"> Inference & Impact Analysis </h2>
          <Inference patientId={id} summary={displaySummaryTable} />
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary btn-col" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const handleWeightAdd = () => {
    setWeightAddShow(true);
  };

  const handleHeightAdd = () => {
    setHeightAddShow(true);
  };
  const handleBloodAdd = () => {
    setBloodAddShow(true);
  };
  const handleMaritalAdd = () => {
    setMaritalAddShow(true);
  };
  

 
  const handleNextVisit = (date) => {
    if (date !== null) {
      setNext(date);
    }
  };

 
 
  const UpdateSuccessPopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className="text-success">Procedure Updated Successfully</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary btn-col" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const AddCholestrolPopUp = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <AddCholestrol id={id} submitcholestrol={submitCholHandle} />
        </Modal.Body>
      </Modal>
    );
  };
  const handleChange = (e) => {
    const val = e.target.value;
    if (e.target.value === "true") setIsOnline(true);
    else setIsOnline(false);
  }; 
  const handleDeleteNextVisit1 = () => {
    setDeleteNextVisitConfirmMsg("success");
    setDeleteNextVisitConfirmModalShow("true");
  };
  const handleDeleteNext = () => {
    setNext_visit("");
    setNext(null);
    callDeleteNext();
  };

  const callDeleteNext = (index) => {
    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");

    const dataToUpdate = {
      patient_id: id,
      next_visit: next_visit===""?" ":next_visit,
    };

    axios
      .put(`${APIURL}/api/v1/doctor/patient-record/`, dataToUpdate, {
        headers: {
          Authorization: "Token " + str,
          "Content-Type": "application/json",
        },
      })

      .then((res) => {
        if (res.data.status === "success") {
          alert.success("Successfully deleted next visit data");
          setReRender(!reRender);
            setDeleteNextMsg("");
          setDoctorName("");
          setDisplayDoctor(false);
        } else if (res.data.status === "error") {
          setNextErr(
            res.data.message ? res.data.message : "Error in submission!"
          );
        } else {
          setNextErr("Error in submission!");
        }
      })
      .catch((err) => {
        setNextErr("Error in submission!");
      });
  };

 

  const DeleteNextVisitConfirmPopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 id="confirm-reject">Please confirm to delete Next Visit!</h4>
        </Modal.Body>
        <Modal.Footer>
          

          <Button
            variant="danger"
            onClick={() => {
              setDeleteNextVisitConfirmModalShow(false);
              handleDeleteNext();
            }}
          >
            {" "}
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

 
  useEffect(() => {
    if (login_datas.user_type === "hospital_doctor" || login_datas.user_type === "hospital_allied_prof"||login_datas.user_type === "hospital_nurse") {
      const tokenString = sessionStorage.getItem("usertoken");
      let str = tokenString.replace(/["]+/g, "");

      axios
        .get(`${APIURL}/api/v1/patient/symptoms-list/?patient_id=${id}`, {
          headers: {
            Authorization: "Token " + str,
          },
        })
        .then((res) => {
          if (res.status == 200) {
            setSymptoms(res.data);
          }
        });
    }
  }, [refresh]);

  const WeightAddPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
       
        <Modal.Body>
        <h6 style={{ marginLeft: "2%", textTransform: "capitalize",marginTop:"-5px" }}><b>{name}</b>(Weight)
            <button style={{ backgroundColor: "white", float: "right",marginRight:"15%" }} variant="secondary" onClick={props.onHide}>
              <i class="fa fa-times fa-lg" style={{ color: "rgba(43, 147, 160, 1)" }} aria-hidden="true"></i>
            </button></h6><br/>
          <WeightAdd
            id={id}
           
            seteditweight={weight}
            seteditheight={height}
            onSuccess={onSuccess}
          />
        </Modal.Body>
        
      </Modal>
    );
  };
  const BloodAddPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
       
        <Modal.Body>
        <h6 style={{ marginLeft: "2%", textTransform: "capitalize",marginTop:"-5px" }}><b>{name}</b>(Blood Group)
            <button style={{ backgroundColor: "white", float: "right",marginRight:"15%" }} variant="secondary" onClick={props.onHide}>
              <i class="fa fa-times fa-lg" style={{ color: "rgba(43, 147, 160, 1)" }} aria-hidden="true"></i>
            </button></h6><br/>
          <BloodAdd
            id={id}
            seteditblood={blood}
            seteditheight={height}
            seteditweight={weight}
            onSuccess={onSuccess}
          />
        </Modal.Body>
       
      </Modal>
    );
  };
  const MaritalAddPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
       
        <Modal.Body>
        <h6 style={{ marginLeft: "2%", textTransform: "capitalize",marginTop:"-5px" }}><b>{name}</b>(Marital Status)
            <button style={{ backgroundColor: "white", float: "right",marginRight:"15%" }} variant="secondary" onClick={props.onHide}>
              <i class="fa fa-times fa-lg" style={{ color: "rgba(43, 147, 160, 1)" }} aria-hidden="true"></i>
            </button></h6><br/>
          <MaritalStatus   id={id} married={maritalStatus}  onSuccess={onSuccess}/>
          
        </Modal.Body>
       
      </Modal>
    );
  };
  


  const onSuccess = () => {
setHeightAddShow(false)
setWeightAddShow(false)
setBloodAddShow(false)
setMaritalAddShow(false)
    PatientDetails()
   
  };
  const HeightAddPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
     
        <Modal.Body style={{width:"96%"}}> 
        <h6 style={{ marginLeft: "2%", textTransform: "capitalize",marginTop:"-5px" }}><b>{name}</b>(Height)
            <button style={{ backgroundColor: "white", float: "right",marginRight:"15%" }} variant="secondary" onClick={props.onHide}>
              <i class="fa fa-times fa-lg" style={{ color: "rgba(43, 147, 160, 1)" }} aria-hidden="true"></i>
            </button></h6><br/>
          <HeightAdd
            id={id}
           
            seteditheight={height}
            seteditweight={weight}
            onSuccess={onSuccess}
           
          />
        </Modal.Body>
       
      </Modal>
    );
  };

 
  const PulsePopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-80w"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <VitalsPulse {...props} />
        </Modal.Body>
      </Modal>
    );
  };
  const TempPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-80w"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <VitalsTemp {...props} />
        </Modal.Body>
      </Modal>
    );
  };
  const SpPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-80w"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <VitalsSp {...props} />
        </Modal.Body>
      </Modal>
    );
  };
 
  const GlucosePopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-80w"
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <GlucoseVitals {...props} />
        </Modal.Body>
      </Modal>
    );
  };

  const handleObs = () => {
    setRefresh(!refresh);
  };

  const handleSymptomsOverlay = (e) => {
    e.preventDefault();
    setSymptomsShow(true);
  };

  const handleAddSymptoms = () => {
    setSymptomsShow(false);
    setRefresh(!refresh);
  };

  const BpPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-80w"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <VitalsBP {...props} />
        </Modal.Body>
      </Modal>
    );
  };
  const CholesterolPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-80w"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <VitalsChol {...props} />
        </Modal.Body>
      </Modal>
    );
  };

  const MessagesPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-90w"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Suspense fallback={<div>Loading...</div>}></Suspense>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary btn-col" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const SubmitPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Data submitted successfully!</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary btn-col" onClick={props.onHide}>
            Continue
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  

  const ErrorNextPopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 style={{ color: "red" }}> {nextErr}</h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };



  
  const SubmitErrorPopup1 = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 style={{ color: "red" }}> Please Select doctor name </h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const handleGlucoseOverlay = (e) => {
    e.preventDefault();
    setGlucoseShow(true);
  };
  const handlePulseOverlay = (e) => {
    e.preventDefault();
    setPulseShow(true);
  };

  const handleTempOverlay = (e) => {
    e.preventDefault();
    setTempShow(true);
  };
  const handleSpOverlay = (e) => {
    e.preventDefault();
    setSpShow(true);
  };
  const handleBpOverlay = (e) => {
    e.preventDefault();
    setBpShow(true);
  };

  const ObservationsPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3 className="title-of-tasks header">Observation</h3>

          <AddObs id={id} handleAdd={handleAddObs} />
        </Modal.Body>
      </Modal>
    );
  };
 
  const EditPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body></Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
          <Button className="btn btn-primary btn-col" onClick={props.onSave}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

 
  const ViewPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body></Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  let imgFile = null;
 

  const handlePulse = () => {
    setAddPulse(true);
  };
  const handleTemp = () => {
    setAddTemp(true);
  };
  const handleSp = () => {
    setAddSp(true);
  };

  const handleSugar = () => {
    setAddSugar(true);
  };
  const handleBp = () => {
    setAddBp(true);
  };

 
  const handleEditVisitor = () => {
    setCardView(false);
    setCardEditView(true);
  };

  const handleVisitorFalse = () => {
    setCardEditView(false);
  
  };

 
  const AddRptPopup = (props) => {
    return (
      <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={false}
      >
       <Modal.Header style={{ borderBottom: "none", height: "30px", marginTop: "2%", }}>
          <button style={{ backgroundColor: "white", marginLeft: "95%" }} variant="secondary" onClick={props.onHide}>
            <i class="fa fa-times-circle fa-2x" style={{ color: "#F0A639" }} aria-hidden="true"></i>
          </button>
        </Modal.Header>
        <Modal.Body style={{ paddingRight: "7%", textAlign: "center" }}>
          <AddRemarkReport
            update={handleSend}
            id={id}
            reportId={cellid}
            rptImg={rptImg}
            onSuccess={GetReport}

          />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    );
  };

  const AddVisitorPOpUp = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-80w"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <AddVisitor patient={id} handle={handleVisitorOff} />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    );
  };

  const VisitorEditPopUp = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-80w"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <VisitorUpdate
            patient={id}
            data={visitorDatas1}
            handle={handleVisitorFalse}
          />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    );
  };

  const ViewVisitorCard = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-60w"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          {login_datas.user_type === "hospital_bdo"||login_datas.user_type === "hospital_pro" ? (
            <button
              style={{
                backgroundColor: "rgb(52, 104, 194)",
                color: "white",
                marginBottom: "5%",
                justifyContent: "left",
              }}
              onClick={handleEditVisitor}
            >
              Edit
            </button>
          ) : (
            ""
          )}
          <Card.Body>
            {visitorDatas1 && (
              <div>
                <h4 style={{ color: "green" }}>
                  <b>Basic Info</b>
                </h4>
                <ListGroup
                  style={{ paddingRight: "4%" }}
                  className="list-group-flush text-left"
                >
                  <ListGroupItem>
                    <b>User Name </b>
                    <span style={{ float: "right",}}>
                      {" "}
                      {visitorDatas1.username}{" "}
                    </span>
                  </ListGroupItem>
                  <ListGroupItem>
                    <b>First Name </b>
                    <span style={{ float: "right",textTransform:"capitalize"  }}>
                      {" "}
                      {visitorDatas1.first_name}{" "}
                    </span>
                  </ListGroupItem>
                  <ListGroupItem>
                    <b>Last Name </b>
                    <span style={{ float: "right" ,textTransform:"capitalize" }}>
                      {" "}
                      {visitorDatas1.last_name}{" "}
                    </span>
                  </ListGroupItem>
                  <ListGroupItem>
                    <b>Relation </b>
                    <span style={{ float: "right",textTransform:"capitalize"  }}>
                      {" "}
                      {visitorDatas1.relation}{" "}
                    </span>
                  </ListGroupItem>
                  <ListGroupItem>
                    <b>Email </b>
                    <span style={{ float: "right" }}>
                      {" "}
                      {visitorDatas1.email}{" "}
                    </span>
                  </ListGroupItem>
                  <ListGroupItem>
                    <b>Gender </b>
                    <span style={{ float: "right" ,textTransform:"capitalize" }}>
                      {" "}
                      {visitorDatas1.gender}{" "}
                    </span>
                  </ListGroupItem>
                  <ListGroupItem>
                    <b>Hospital Name </b>
                    <span style={{ float: "right" ,textTransform:"capitalize" }}>
                      {" "}
                      {login_datas.hospital_name}{" "}
                    </span>
                  </ListGroupItem>
                  <ListGroupItem>
                    <b>Date of Birth </b>
                    <span style={{ float: "right" }}>
                      {" "}
                      {visitorDatas1.dob}{" "}
                    </span>
                  </ListGroupItem>
                  <ListGroupItem>
                    <b>Mobile Number </b>
                    <span style={{ float: "right" }}>
                      {" "}
                      {visitorDatas1.phone}{" "}
                    </span>
                  </ListGroupItem>
                  <ListGroupItem>
                    <b>City </b>
                    <span style={{ float: "right",textTransform:"capitalize"  }}>
                      {" "}
                      {visitorDatas1.city}{" "}
                    </span>
                  </ListGroupItem>
                  <ListGroupItem>
                    <b>State </b>
                    <span style={{ float: "right",textTransform:"capitalize"  }}>
                      {" "}
                      {visitorDatas1.state}{" "}
                    </span>
                  </ListGroupItem>
                  <ListGroupItem>
                    <b>Country </b>
                    <span style={{ float: "right" }}>
                      {" "}
                      {visitorDatas1.country}{" "}
                    </span>
                  </ListGroupItem>
                </ListGroup>
              </div>
            )}

            {/* <Button disabled={disable} onClick={handleReset} className='btn btn-primary btn-col'>Reset Password</Button> */}
          </Card.Body>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    );
  };

  const renderReport = (cellInfo) => {
    const date4 = item1;
    const date5 = [...date4].reverse();
    return <h6>{date4[cellInfo.index][cellInfo.column.id]}</h6>;
  };
  const renderReportId = (cellInfo) => {
    const date4 = item1;
    const date5 = [...date4].reverse();
    return (
      <>
        <h6 style={{ marginLeft: "35%" }}>
          {date4[cellInfo.index][cellInfo.column.id]}
        </h6>
      </>
    );
  };

  const handleAdd1 = (index, id, img) => {
    setcellId(id);
    setRptModalShow(true);
    setRptImg(img);
  };

  const renderRemarks3 = (cellInfo) => {
    return (
      item1[cellInfo.index].image!==""&&<div style={{textAlign:"center",padding:"1%"}}><i
        style={{ color: "rgb(91 138 218)",  }}
        onClick={() => {
          const date4 = item1;
          const date5 = [...date4].reverse();
          handleAdd1(
            cellInfo.index,
            date4[cellInfo.index].id,
            date4[cellInfo.index].images
          );
        }}
        className="fa fa-file-image-o fa-lg"
      ></i></div>
    );
  };

  const columnsReports = [
   
    {
      Header: "Type",
      accessor: "doc_type",
      Cell: renderReport,
      style: {
        backgroundColor: "rgba(0,0,0,0.1)",
      },
      sortable: false,
    },
    {
      Header: "Remarks",
      accessor: "comment",
      Cell: renderReport,
    
      sortable: false,
    },

    {
      Header: "Reports",
      accessor: "comment",
      Cell: renderRemarks3,
      sortable: false,
      filterable: false,
     
    },
  ];
 
 
  const SizePopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 id="confirm-reject">You have Exceeded the size limit 10MB</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const LoadPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 id="confirm-reject">Select an Image</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const ValidPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 id="confirm-reject">Select a valid Image</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const ContentPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 id="confirm-reject">Invalid Image Content</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const AddBpPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddBp id={id} submitbp={submitBpHandle} />
        </Modal.Body>
      </Modal>
    );
  };

  const AddSugarPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <AddSugar id={id} submitsugar={submitSugarHandle} />
        </Modal.Body>
      </Modal>
    );
  };
  const AddPulsePopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <AddPulse id={id} submitpulse={submitPulseHandle} />
        </Modal.Body>
      </Modal>
    );
  };

  const AddTempPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <AddTemp id={id} submittemp={submitTempHandle} />
        </Modal.Body>
      </Modal>
    );
  };
  const AddSpPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <AddSp id={id} submitsp={submitSpHandle} />
        </Modal.Body>
      </Modal>
    );
  };
  const submitBpHandle = () => {
    setAddBp(false);
  };

  const submitSugarHandle = () => {
    setAddSugar(false);
  };
  const submitPulseHandle = () => {
    setAddPulse(false);
  };
  const submitTempHandle = () => {
    setAddTemp(false);
  };
  const submitSpHandle = () => {
    setAddSp(false);
  };

 
  const handleMessagesOverlay = (e) => {
    e.preventDefault();
    setMessagesShow(true);
  };

 
  let sendData = null;

  function handleSend(childData) {
    sendData = childData;
  }

  const AddPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center"></Modal.Body>
        <Modal.Footer>
          <Button  variant="secondary" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const RemarkViewPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">{detailData}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

 
  const DetailsUpdateSuccessPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Updated successfully.</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary btn-col" onClick={props.onHide}>
            Continue
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const UpdateImgPopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 id="confirm-reject">Please upload an Image</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  let summaryArray = summary.split(/[\r\n]+/);
  let displaySummary =
    summaryArray.length > 1
      ? summaryArray.map((line, i) => {
        if (line) {
          return (
            <>
              <h6 className="text-left ">
                {" "}
                {i + 1}.{line}{" "}
              </h6>
            </>
          );
        }
      })
      : "-No data to show-";

  let displaySummaryTable = (
    <div className="sub-section inferences-subsection">
      <div className="sub-section-header box-theme">
        <h6 className="sub-section-title">
          {" "}
          Inferences <span className="ref-text">(Ref: last 60 days) </span>
        </h6>
      </div>
      <div className="sub-section-body">{displaySummary}</div>
    </div>
  );
  useEffect(() => {
    if (!isBooped) {
      return;
    }
    const timeoutId = window.setTimeout(() => {
      setIsBooped(false);
    }, timing);
    return () => {
      window.clearTimeout(timeoutId);
    };
  }, [isBooped, timing]);

 
 
  const submitBmiHandle = () => {
    setAddBmi(false);
    PatientDetails();
  };

  const onVideoCall = () => {
    navigate("/call/1234");
    //TODO - Should be unique room id, accessible to doctor and patient
  };

  return (
    <>



      <div className="row" style={{width:"100%"}}>


        <div className="col-2" style={{ borderRight: "4px solid rgba(205, 205, 205, 1)", paddingLeft: "1.5%",position:"fixed",height:"100%" ,paddingTop:"1%"}}>
         <div className="row">
         <div className="sub-section-img col-4" style={{paddingleft:"5%"}}>
       
         <img
                          style={{
                            borderRadius: "50%",
                            width: "40px",
                            height: "40px",
                           
                          }}
                    src={imgUrl}
                    alt="Profile Photo"
                    
                  />
                </div>
                <div className="col-8">
                <div className="recdiv" style={{borderRight:"none",textAlign:"left",paddingTop:"8%"}}>
                  <h6 className='text-style-here-patient-rec 'style={{color:"black"}} ><b>{name}</b></h6>
                  <h6 className='text-style-here-patient-rec'style={{color:"black"}} >{gender}, {age}</h6>
                  </div>
                </div>
         </div>
          <div className="">

            <button className="patientRecbutton"
            style={{width:"100%"}}
              onClick={() => {
                handleClick(ref1);
              }}
            >
              &nbsp;
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M12 9a2 2 0 1 0 0 4a2 2 0 0 0 0-4Zm-4 8.5a3 3 0 0 1 3-3h2a3 3 0 0 1 3 3a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1Z" /><path fill="currentColor" fill-rule="evenodd" d="M7 2.25A2.75 2.75 0 0 0 4.25 5v14A2.75 2.75 0 0 0 7 21.75h10A2.75 2.75 0 0 0 19.75 19V8.198a1.75 1.75 0 0 0-.328-1.02L16.408 2.98a1.75 1.75 0 0 0-1.421-.73H7ZM5.75 5c0-.69.56-1.25 1.25-1.25h7.25v4.397c0 .414.336.75.75.75h3.25V19c0 .69-.56 1.25-1.25 1.25H7c-.69 0-1.25-.56-1.25-1.25V5Z" clip-rule="evenodd" /></svg>
              &nbsp;
              Personal Data{" "}

            </button>

            <button className="patientRecbutton"
            style={{width:"100%",cursor: (login_datas &&
              login_datas.user_type !== 'hospital_doctor' && login_datas.user_type !== 'hospital_allied_prof'&& login_datas.user_type !== 'hospital_nurse')? "not-allowed" : "pointer"}}
              onClick={() => {
                handleClick(ref3);
              }}
            >
              &nbsp;
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 20 20"><path fill="currentColor" d="M6.44 9.439A1.495 1.495 0 0 1 7.5 9h5a1.5 1.5 0 0 1 1.5 1.5v4a1.5 1.5 0 0 1-1.5 1.5H9.121l-.56-.56A4.482 4.482 0 0 0 9 13.5V15h3.5a.5.5 0 0 0 .5-.5V13H9v.5a4.481 4.481 0 0 0-1-2.829V10h-.5a.5.5 0 0 0-.145.021a4.503 4.503 0 0 0-.915-.582ZM13 10.5a.5.5 0 0 0-.5-.5H9v2h4v-1.5ZM5 9.027a4.551 4.551 0 0 0-1 0V4a2 2 0 0 1 2-2h4.586a1.5 1.5 0 0 1 1.06.44l3.915 3.914A1.5 1.5 0 0 1 16 7.414V16a2 2 0 0 1-2 2h-3.085a1.495 1.495 0 0 0-.354-.56l-.44-.44H14a1 1 0 0 0 1-1V8h-3.5A1.5 1.5 0 0 1 10 6.5V3H6a1 1 0 0 0-1 1v5.027ZM11.5 7h3.293L11 3.207V6.5a.5.5 0 0 0 .5.5Zm-4.197 8.596a3.5 3.5 0 1 0-.707.707l2.55 2.55a.5.5 0 1 0 .708-.707l-2.55-2.55ZM7 13.5a2.5 2.5 0 1 1-5 0a2.5 2.5 0 0 1 5 0Z" /></svg>
              &nbsp;
              Diagnostics Info{" "}

            </button>


            <button className="patientRecbutton"
            style={{width:"100%",cursor:(login_datas &&
              login_datas.user_type !== 'hospital_doctor' && login_datas.user_type !== 'hospital_allied_prof'&& login_datas.user_type !== 'hospital_nurse')? "not-allowed" : "pointer"}}
              onClick={() => {
                handleClick(ref5);
              }}
            >
              &nbsp;
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 21 21"><g fill="none" fill-rule="evenodd" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"><path d="M16.5 15.5v-7l-5-5h-5a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zm-10-5h5m-5 2h7m-7 2h3" /><path d="M11.5 3.5v3a2 2 0 0 0 2 2h3" /></g></svg>
              &nbsp;
              Observations{" "}

            </button>
            <button className="patientRecbutton"
            style={{width:"100%",cursor:(login_datas &&
              login_datas.user_type !== 'hospital_doctor' && login_datas.user_type !== 'hospital_allied_prof'&& login_datas.user_type !== 'hospital_nurse')? "not-allowed" : "pointer"}}
              onClick={() => {
                handleClick(ref6);
              }}
            >
              &nbsp;
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M20 2a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1v-2H3v-2h2v-2H3v-2h2v-2H3V9h2V7H3V5h2V3a1 1 0 0 1 1-1h14Zm-1 2H7v16h12V4Zm-5 4v3h3v2h-3.001L14 16h-2l-.001-3H9v-2h3V8h2Z" /></svg>
              &nbsp;
              Symptoms{" "}

            </button>



            <button className="patientRecbutton"
            style={{width:"100%",cursor:(login_datas &&
              login_datas.user_type !== 'hospital_doctor' && login_datas.user_type !== 'hospital_allied_prof'&& login_datas.user_type !== 'hospital_nurse')? "not-allowed" : "pointer"}}
              onClick={() => {
                handleClick(ref7);
              }}
            >
              &nbsp;
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 48 48"><path fill="currentColor" fill-rule="evenodd" d="M20 4a3 3 0 0 0-3 3h-3a3 3 0 0 0-3 3v28a3 3 0 0 0 3 3h12v-2H14a1 1 0 0 1-1-1V10a1 1 0 0 1 1-1h3a3 3 0 0 0 3 3h8a3 3 0 0 0 3-3h3a1 1 0 0 1 1 1v15h2V10a3 3 0 0 0-3-3h-3a3 3 0 0 0-3-3h-8Zm-1 3a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-8a1 1 0 0 1-1-1V7Zm4 12v-3h2v3h3v2h-3v3h-2v-3h-3v-2h3Zm4 9a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v6.789a7 7 0 0 1-3.117 5.824l-3.328 2.22a1 1 0 0 1-1.11 0l-3.328-2.22A7 7 0 0 1 27 34.79V28Zm2 1v5.789a5 5 0 0 0 2.227 4.16L34 40.8l2.773-1.85A5 5 0 0 0 39 34.79V29H29Zm4.707 7.707l4-4l-1.414-1.414L33 34.586l-1.293-1.293l-1.414 1.414l2 2a1 1 0 0 0 1.414 0Z" clip-rule="evenodd" /></svg>
              &nbsp;
              Allergy{" "}

            </button>

            <button className="patientRecbutton"
            style={{width:"100%",cursor:(login_datas &&
              login_datas.user_type !== 'hospital_doctor' && login_datas.user_type !== 'hospital_allied_prof'&& login_datas.user_type !== 'hospital_nurse')? "not-allowed" : "pointer"}}
              onClick={() => {
                handleClick(ref8);
              }}
            >
              &nbsp;
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 48 48"><g fill="currentColor"><path d="M16 20a1 1 0 1 0 0 2h7a1 1 0 1 0 0-2h-7Zm-1-4a1 1 0 0 1 1-1h15.5a1 1 0 1 1 0 2H16a1 1 0 0 1-1-1Z" /><path fill-rule="evenodd" d="M15 28a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1v-4Zm2 3v-2h2v2h-2Zm11 0a3 3 0 1 0 0-6a3 3 0 1 0 0 6Zm0-2a1 1 0 1 0 .002-1.998A1 1 0 0 0 28 29Zm-6 6.182C22 33.066 25.997 32 28 32s6 1.066 6 3.182V39H22v-3.818Zm2.055.04a.927.927 0 0 0-.055.057V37h8v-1.72a.927.927 0 0 0-.055-.059c-.164-.16-.48-.372-.976-.583c-1-.425-2.234-.638-2.969-.638c-.735 0-1.969.213-2.969.638c-.496.21-.812.423-.976.583Z" clip-rule="evenodd" /><path fill-rule="evenodd" d="M17 7a3 3 0 0 1 3-3h8a3 3 0 0 1 3 3h4a3 3 0 0 1 3 3v31a3 3 0 0 1-3 3H13a3 3 0 0 1-3-3V10a3 3 0 0 1 3-3h4Zm11 5a3 3 0 0 0 3-3h4a1 1 0 0 1 1 1v31a1 1 0 0 1-1 1H13a1 1 0 0 1-1-1V10a1 1 0 0 1 1-1h4a3 3 0 0 0 3 3h8Zm-8-6a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1h-8Z" clip-rule="evenodd" /></g></svg>
              &nbsp;
              Medical Reports{" "}

            </button>

          </div>
          {features.includes("call_for_action") && <div >
          <CallforActionPat id={id} name={name}  />
          </div>}
          
        
        </div>

        <div className="col-10 row" style={{marginLeft:"16%", padding: "1%", fontFamily: "Poppins" , }}>
       
          <div className="col-11">
          

              {flag2 !== 2 ? (
                <h4>
                  {" "}
                  <b>Level-1 Patient</b>{" "}
                </h4>
              ) : null}

              <div
                style={{}}
                className="new-rec-section"
                ref={ref1}
              >

                <div
                  className="patientRecbutton row" style={{
                    
                    backgroundColor: "rgba(129, 182, 182, 1)", color: "white"
                  }}
                >
                
                  <div className="col-7 row " style={{padding:"1%"}}>
                  <div className="col-3 recdiv" >
                  <h6 className='text-style-here-patient-rec ' ><b>Weight</b> &nbsp; {weight ? <i
                              style={{
                                justifySelf: "start",
                                
                              color:(login_datas.user_type === "hospital_doctor" || login_datas.user_type === "hospital_allied_prof"||login_datas.user_type === "hospital_nurse")? "white":"gray",
                              cursor:(login_datas.user_type === "hospital_doctor" || login_datas.user_type === "hospital_allied_prof"||login_datas.user_type === "hospital_nurse")?"pointer":"not-allowed",
                                fontSize: "10px",
                              }}
                              onClick={() =>(login_datas.user_type === "hospital_doctor" || login_datas.user_type === "hospital_allied_prof"||login_datas.user_type === "hospital_nurse")&&handleWeightAdd()}
                              className="fas fa-pencil-alt"
                              aria-hidden="true"
                            ></i>:<i
                            onClick={() =>(login_datas.user_type === "hospital_doctor" || login_datas.user_type === "hospital_allied_prof"||login_datas.user_type === "hospital_nurse")&& handleWeightAdd()}
                            style={{
                              justifySelf: "start",
                              color:(login_datas.user_type === "hospital_doctor" || login_datas.user_type === "hospital_allied_prof"||login_datas.user_type === "hospital_nurse")? "white":"gray",
                              cursor:(login_datas.user_type === "hospital_doctor" || login_datas.user_type === "hospital_allied_prof"||login_datas.user_type === "hospital_nurse")?"pointer":"not-allowed"
                            }}
                            className="fas fa-plus"
                            aria-hidden="true"
                          ></i> } 
                          </h6>
                          {login_datas.user_type === "hospital_doctor" || login_datas.user_type === "hospital_allied_prof" ||login_datas.user_type === "hospital_nurse"? <h6 className='text-style-here-patient-rec' >{weight} {weight ? "kg" : ""}</h6>:
                          <h6 className='text-style-here-patient-rec' >{weightnew} {weightnew ? "kg" : ""}</h6>}

                  </div>
                  <div className="col-3 recdiv" >
                  <h6 className='text-style-here-patient-rec ' ><b>Height</b> &nbsp; {height ? <i
                              style={{
                                justifySelf: "start",
                                
                              color:(login_datas.user_type === "hospital_doctor" || login_datas.user_type === "hospital_allied_prof"||login_datas.user_type === "hospital_nurse")? "white":"gray",
                              cursor:(login_datas.user_type === "hospital_doctor" || login_datas.user_type === "hospital_allied_prof"||login_datas.user_type === "hospital_nurse")?"pointer":"not-allowed",
                                fontSize: "10px",
                              }}
                              onClick={() =>(login_datas.user_type === "hospital_doctor" || login_datas.user_type === "hospital_allied_prof"||login_datas.user_type === "hospital_nurse")&&handleHeightAdd()}
                              className="fas fa-pencil-alt"
                              aria-hidden="true"
                            ></i>:<i
                            onClick={() =>(login_datas.user_type === "hospital_doctor" || login_datas.user_type === "hospital_allied_prof"||login_datas.user_type === "hospital_nurse")&& handleHeightAdd()}
                            style={{
                              justifySelf: "start",
                              color:(login_datas.user_type === "hospital_doctor" || login_datas.user_type === "hospital_allied_prof"||login_datas.user_type === "hospital_nurse")? "white":"gray",
                              cursor:(login_datas.user_type === "hospital_doctor" || login_datas.user_type === "hospital_allied_prof"||login_datas.user_type === "hospital_nurse")?"pointer":"not-allowed"
                            }}
                            className="fas fa-plus"
                            aria-hidden="true"
                          ></i> } 
                          </h6>
                          {login_datas.user_type === "hospital_doctor" || login_datas.user_type === "hospital_allied_prof"||login_datas.user_type === "hospital_nurse" ? <h6 className='text-style-here-patient-rec' >{height} {height ? "cm" : ""}</h6>:
                          <h6 className='text-style-here-patient-rec' >{heightnew} {heightnew ? "cm" : ""}</h6>}

                  </div>
                  <div className="col-3 recdiv" >
                  <h6 className='text-style-here-patient-rec ' ><b>B Group</b>&nbsp; {blood ? <i
                              style={{
                                justifySelf: "start",
                                
                              color:(login_datas.user_type === "hospital_doctor" || login_datas.user_type === "hospital_allied_prof"||login_datas.user_type === "hospital_nurse")? "white":"gray",
                              cursor:(login_datas.user_type === "hospital_doctor" || login_datas.user_type === "hospital_allied_prof"||login_datas.user_type === "hospital_nurse")?"pointer":"not-allowed",
                                fontSize: "10px",
                              }}
                              onClick={() =>(login_datas.user_type === "hospital_doctor" || login_datas.user_type === "hospital_allied_prof"||login_datas.user_type === "hospital_nurse")&&handleBloodAdd()}
                              className="fas fa-pencil-alt"
                              aria-hidden="true"
                            ></i>:<i
                            onClick={() =>(login_datas.user_type === "hospital_doctor" || login_datas.user_type === "hospital_allied_prof"||login_datas.user_type === "hospital_nurse")&& handleBloodAdd()}
                            style={{
                              justifySelf: "start",
                              color:(login_datas.user_type === "hospital_doctor" || login_datas.user_type === "hospital_allied_prof"||login_datas.user_type === "hospital_nurse")? "white":"gray",
                              cursor:(login_datas.user_type === "hospital_doctor" || login_datas.user_type === "hospital_allied_prof"||login_datas.user_type === "hospital_nurse")?"pointer":"not-allowed"
                            }}
                            className="fas fa-plus"
                            aria-hidden="true"
                          ></i> } 
                          </h6>
                          {login_datas.user_type === "hospital_doctor" || login_datas.user_type === "hospital_allied_prof" ||login_datas.user_type === "hospital_nurse"? <h6 className='text-style-here-patient-rec' >{blood}</h6>:
                          <h6 className='text-style-here-patient-rec' >{bloodnew} </h6>}

                  </div>
                  <div className="col-3 recdiv" >
                  <h6 className='text-style-here-patient-rec ' ><b>Marital Status</b> &nbsp;<i
                              style={{
                                justifySelf: "start",
                                
                              color:(login_datas.user_type === "hospital_doctor" || login_datas.user_type === "hospital_allied_prof"||login_datas.user_type === "hospital_nurse")? "white":"gray",
                              cursor:(login_datas.user_type === "hospital_doctor" || login_datas.user_type === "hospital_allied_prof"||login_datas.user_type === "hospital_nurse")?"pointer":"not-allowed",
                                fontSize: "10px",
                              }}
                              onClick={() =>(login_datas.user_type === "hospital_doctor" || login_datas.user_type === "hospital_allied_prof"||login_datas.user_type === "hospital_nurse")&&handleMaritalAdd()}
                              className="fas fa-pencil-alt"
                              aria-hidden="true"
                            ></i></h6>
                          <h6 className='text-style-here-patient-rec' >{maritalStatus ? "Married" : "Unmarried"} </h6>

                  </div>
                  </div>
                  <div className="col-5 row">
                  <div className="col-6" style={{ textAlign: "center", justifySelf: "center" }}>
                   
                    {login_datas.user_type === "hospital_doctor" || login_datas.user_type === "hospital_allied_prof"||login_datas.user_type === "hospital_nurse" ? (
                      <div
                        className=""
                        style={{

                          
                          borderRadius: "10px",
                          backgroundColor: "rgba(43, 147, 160, 1)",
                          marginTop: "10%",
                          

                          textAlign: "center",
                          color: "white",
                          
                        }}
                      >
                          <h6 style={{paddingTop: "6%",paddingBottom:"6%",}} className='text-style-here-patient-rec '>Medical History &nbsp;
                          {medHistory ? (
                            <i
                              style={{
                                justifySelf: "start",
                                color: "white",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                handleMedHistory(familyData?.past_medical_history)
                              }
                              className="fas fa-edit iconStyle"
                            ></i>
                          ) : (
                            <i
                              onClick={() => handleFamAdd()}
                              style={{ justifySelf: "start", color: "white",cursor:"pointer"}}
                              className="fas fa-plus"
                            ></i>
                          )}</h6>
                      </div>
                    ) : (
                      <div
                        className=""
                        style={{

                          
                          borderRadius: "10px",
                          backgroundColor: "rgba(43, 147, 160, 1)",
                          marginTop: "10%",
                          

                          textAlign: "center",
                          color: "white",
                        }}
                      >
                         <h6 style={{paddingTop: "6%", paddingBottom:"6%"}} className='text-style-here-patient-rec '>Medical History &nbsp;
                          {medHistory && (
                            <i
                              style={{
                                justifySelf: "start",
                                color: "white",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                handleMedHistory(familyData?.past_medical_history)
                              }
                              className="fa fa-eye"
                            ></i>
                          )}</h6>
                      </div>
                    )}{" "}
                  </div>

                  <div className="col-6" style={{ textAlign: "center", justifySelf: "center" }}>
                    {login_datas.user_type === "hospital_doctor" || login_datas.user_type === "hospital_allied_prof"||login_datas.user_type === "hospital_nurse" ? (
                      <div
                        className=""
                        style={{

                        
                          borderRadius: "10px",
                          backgroundColor: "rgba(43, 147, 160, 1)",
                          marginTop: "10%",
                          

                          textAlign: "center",
                          color: "white",
                        }}
                      >
                        <h6 style={{paddingTop: "6%",paddingBottom:"6%"}} className='text-style-here-patient-rec '>&nbsp;Family History &nbsp;

                          {famHistory ? (
                            <i
                              style={{
                                justifySelf: "start",
                                color: "white",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                handleHistory(familyData?.family_history)
                              }
                              className="fas fa-edit iconStyle"
                            ></i>
                          ) : (
                            <i
                              onClick={() => handleHistoryAdd()}
                              style={{
                                justifySelf: "start",
                                color: "white",
                                marginTop: "-1%",
                                cursor:"pointer"
                              }}
                              className="fas fa-plus"
                            ></i>
                          )}</h6>
                      </div>
                    ) : (
                      <div
                        className=""
                        style={{

                         
                          borderRadius: "10px",
                          backgroundColor: "rgba(43, 147, 160, 1)",
                          marginTop: "10%",
                          

                          textAlign: "center",
                          color: "white",
                        }}
                      >
                       <h6 style={{paddingTop: "6%",paddingBottom:"6%"}} className='text-style-here-patient-rec '>&nbsp; Family History &nbsp;&nbsp;
                          {famHistory && (
                            <i
                              style={{
                                justifySelf: "start",
                                color: "white",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                handleHistory(familyData?.family_history)
                              }
                              className="fa fa-eye"
                            ></i>)}</h6>
                        {" "}
                      </div>
                    )}{" "}
                  </div>
                  </div>
                </div>
                <div style={{ width: "100%" }}>
                  <div className="row" style={{marginTop:" 2%"}}>
                    <div className="col-2" style={{paddingRight:0}}>                    <button
                      className="visitbutton"
                      style={{
                        backgroundColor: visit1 && "#3C689E" ,
                        color: visit1 ? "white" : "black",
                      }}
                      onClick={() => handleVisit()}
                    >
                      {" "}
                      <b>Visit</b>
                    </button>                    </div>                    
                    {(features.includes("ip_form_admission")||features.includes("ip_admission")) &&  
                    <div className="col-2" style={{paddingRight:0}}> <button
                      className="visitbutton"
                      style={{
                        backgroundColor: ip && "#3C689E",
                        color: ip ? "white" : "black",
                        
                      }}
                      onClick={() => handleIP()}
                    >
                      <b>IP</b>
                    </button></div>}
                    {role==='alliedprofessional' && <div className="col-2" style={{paddingRight:0}}><button
                      className="visitbutton"
                      style={{
                        backgroundColor: therapy && "#3C689E",
                        color: therapy ? "white" : "black",
                        
                      }}
                      onClick={() => handleTherapy()}
                    >
                      <b>Therapy</b>
                    </button></div>}
                   <div className="col-2" style={{paddingRight:0}}>                    <button
                      className="visitbutton"
                      style={{
                        backgroundColor:pro&& "#3C689E",
                        color: pro? "white" : "black",
                        
                      }}
                      onClick={() => handlePro()}
                    >
                      <b>Procedure</b>
                    </button>                    </div>
                    <div className="col-2" style={{paddingRight:0}}>                    <button
                      className="visitbutton"
                      style={{
                        backgroundColor: testScan && "#3C689E" ,
                        color: testScan ? "white" : "black",
                        
                        
                      }}
                      onClick={() => handleTestScan()}
                    >
                      <b>Scan & Test</b>
                    </button>                    </div>
                    <div className="col-2"><button
                      className="visitbutton"
                      style={{
                        backgroundColor: treat && "#3C689E" ,
                        color: treat ? "white" : "black",
                      
                      }}
                      onClick={() => handleTreat()}
                    >
                      <b>Treatment Plan</b>
                    </button></div>
                  </div>
                  <div className="visitdata" >
                    {visit1 ? (
                      <div className="second-contentbox1">
                        {login_datas &&
                          login_datas.user_type &&
                          login_datas.user_type === "hospital_doctor" || login_datas.user_type === "hospital_allied_prof" ? (
                          <>
                            <div className="next-visit-box">
                              <div
                                className=""
                                style={{ display: "flex", fontSize: "13px" }}
                              >
                                <h6
                                  style={{ fontSize: "13px", marginLeft: "2%" }}
                                >
                                  consult via:{" "}
                                </h6>
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                <div className="radio-options">
                                  <input
                                    style={{}}
                                    type="radio"
                                    id="in_person"
                                    name="nextvisit"
                                    value="false"
                                    checked={isOnline === false}
                                    onChange={handleChange}
                                  />
                                  <span>&nbsp;</span>
                                  In-person
                                </div>

                                <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                <div className="radio-options">
                                  <input
                                    style={{}}
                                    type="radio"
                                    id="video"
                                    name="nextvisit"
                                    value="true"
                                    checked={isOnline === true}
                                    onChange={handleChange}
                                  />
                                  <span>&nbsp;</span>
                                  Online
                                </div>

                                <Link
                                  style={{
                                    pointerEvents: linkDisabled ? "" : "none",
                                  }}
                                  to={{
                                    pathname: `/videocall`,
                                  }}
                                  state={{ patient: id }}
                                >
                                  <div
                                    style={{
                                      color: linkDisabled
                                        ? "var(--teal)"
                                        : "silver",
                                    }}
                                    className="video-button"
                                  >
                                    <i
                                      class="fa fa-video-camera"
                                      aria-hidden="true"
                                    ></i>
                                    {"  "}Consultation
                                  </div>
                                </Link>
                              </div>
                              <div className="d-flex flex-row justify-content-around appFornextvisit">
                                <div
                                  className="d-flex flex-column align-self-center"
                                  style={{ width: "40%" }}
                                >
                                  <h6 style={{ fontSize: "13px" }}>
                                    Select Doctor from list
                                  </h6>
                                  <input
                                    style={{ backgroundColor: "#fafafa" }}
                                    onClick={() => {
                                      setSearchAssign("");
                                      setDisplayDoctor(true);
                                    }}
                                    value={doctorName}
                                    className="form-control"
                                    name="assignee"
                                    onChange={(e) => {
                                      e.preventDefault();

                                      setDoctorName(e.target.value);
                                      setDisplayDoctor(true);

                                      setSearchAssign(e.target.value);
                                    }}
                                  />

                                  {displayDoctor && (
                                    <div className="suggestions-box-holder doctor-suggest">
                                      <button
                                        className="btn-suggestion btn btn-secondary btn-smallest"
                                        title="close"
                                        onClick={() => setDisplayDoctor(false)}
                                      >
                                        {" "}
                                        X{" "}
                                      </button>
                                      <div className="suggestions-box suggestions-assignee-box">
                                        {assignList.map((item, i) => {
                                          return (
                                            <div
                                              className="scan-item"
                                              key={item.id}
                                              onClick={(e) => {
                                                e.preventDefault();
                                                setRender(!render);
                                                setDoctorId(item.id);
                                                setDoctorName(item.full_name);
                                                setDisplayDoctor(false);
                                              }}
                                            >
                                              <div className="search-item each-option-suggestion">
                                                <h6 className="">
                                                  {" "}
                                                  {item.full_name}
                                                </h6>
                                              </div>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  )}
                                </div>

                                <div
                                  style={{
                                    alignSelf: "flex-start",
                                    color: "black",
                                  }}
                                  className="each-list-cal each-tile box-4   schedule-box "
                                >
                                  <div>
                                    <DatePicker
                                      popperClassName="some-custom-class"
                                      popperPlacement="top-end"
                                      format="dd-MM-yyyy"
                                      value={next}
                                      onChange={(date) => {
                                        handleNextVisit(date);
                                      }}
                                      minDate={new Date()}
                                      clearIcon={null}
                                    />{" "}
                                    <i
                                      onClick={() => {
                                        handleDeleteNextVisit1();
                                      }}
                                      style={{ color: "red", fontSize: "medium" }}
                                      class="fas fa-trash-alt"
                                      aria-hidden="true"
                                    ></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="form-button"
                              style={{ marginTop: "1%",marginBottom:"1%" }}
                            >
                              {flag === 1 &&
                                flag2 === 2 &&
                                login_datas &&
                                login_datas.user_type &&
                                login_datas.user_type === "hospital_doctor" || login_datas.user_type === "hospital_allied_prof" ? (
                                <button
                                  style={{ alignSelf: "center" }}
                                  onClick={saveNextVisit}
                                  className="btn btn-primary btn-col btn-square btn-small"
                                >
                                  Save Next Visit
                                </button>
                              ) : (
                                ""
                              )}
                            </div>
                          </>
                        ) : // Code for other user types
                          login_datas &&
                            login_datas.user_type &&
                            login_datas.user_type !== "hospital_doctor"&&login_datas.user_type !== "hospital_allied_prof"&&
                            followUP &&
                            followUP.length > 0 ? (
                              <div>
                              {followUP.map((followUpItem, index) => (
                                <div
                                  key={index}
                                  style={{
                                    background: "#F0F9FD",
                                    borderRadius: "10px",
                                    border: "1px solid rgba(88, 182, 222, 0.15",
                                    margin: "10px",
                                    padding: "10px",
                                    display: "inline-block",
                                    width: "380px",
                                    boxSizing: "border-box",
                                  }}
                                >
                                  <div style={{ display: "flex", display: "flex", alignItems: "center",justifyContent: "center", }}>
                                    <div style={{ marginRight: "5px" }}>Doctor:</div>
                                    <div style={{ marginRight: "10px" }}>
                                      <b> {followUpItem.doctor}</b>
                                    </div>
                                    <div style={{ marginRight: "5px" }}>Next Visit:</div>
                                    <div>
                                      <b> {followUpItem.next_visit} </b>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          ) : (
                            <p>No follow-up data available.</p>
                          )}
                      </div>
                    ) : ip ? (
                      <>

                        <div
                          className="row"
                          style={{ display: "flex", }}
                        >
                          <div className="col-4" style={{ paddingBottom:"1%"}}>
                            <label
                              style={{
                                fontFamily: "Poppins",
                                color: "black",
                                marginTop: "10px",
                                fontSize: "15px",
                                marginLeft: "10px",
                              }}
                            >
                              <b>Chief Doctor</b>
                            </label>
                            <div style={{}}>
                              {assignData &&
                                assignData.chief_doctor &&
                                assignData.chief_doctor.length > 0 ? (
                                assignData.chief_doctor.map((doctor, index) => (
                               
                                    <div
                                      style={{
                                        marginLeft: "10px",
                                        borderRadius: "10px",
                                        border:
                                          "1.124px solid rgba(88, 182, 222, 0.15)",
                                        background: "#F0F9FD",
                                        fontSize: "12px",

                                      }}
                                      className="patientRecbutton1"

                                    >
                                      {generateIcon(doctor.name)} {doctor.name}
                                      {/* -{doctor.specialization }  */}

                                    </div>
                                  
                                ))
                              ) : (
                                <div>No chief doctor found.</div>
                              )}
                            </div>
                            <label
                              style={{
                                fontFamily: "Poppins",
                                color: "black",
                                marginTop: "10px",
                                fontSize: "15px",
                                marginLeft: "10px",
                              }}
                            >
                              <b>Duty Doctors</b>
                            </label>
                            <SimpleBar style={{ maxHeight: "130px" }}>
                              <div style={{  }}>
                                {assignData &&
                                  assignData.duty_doctor &&
                                  assignData.duty_doctor.length > 0 ? (
                                  assignData.duty_doctor.map((carer, index) => (
                                    <div key={index}>
                                      <div
                                        style={{
                                          marginLeft: "10px",
                                          borderRadius: "10px",
                                          border:
                                            "1.124px solid rgba(88, 182, 222, 0.15)",
                                          opacity: "0.8",
                                          background: "#DDE3F9",
                                          fontSize: "12px",
                                          marginTop: "3px",
                                        }}
                                        className="patientRecbutton1"
                                      >
                                        {generateIcon(carer.name)} {carer.name}
                                      </div>
                                    </div>
                                  ))
                                ) : (
                                  <div>No duty doctors found.</div>
                                )}
                              </div>
                            </SimpleBar>
                          </div>

                          <div className="col-4" style={{paddingBottom:"1%"}}>
                            <label
                              style={{
                                fontFamily: "Poppins",
                                color: "black",
                                marginTop: "10px",
                                fontSize: "15px",
                              }}
                            >
                              <b> Duty Nurses</b>
                            </label>
                            <SimpleBar style={{ maxHeight: "100px" }}>
                              <div style={{  }}>
                                {assignData &&
                                  assignData.nurse &&
                                  assignData.nurse.length > 0 ? (
                                  assignData.nurse.map((carer, index) => (
                                    <div key={index}>
                                      <div
                                        style={{
                                          marginLeft: "10px",
                                          borderRadius: "10px",
                                          border:
                                            "1.124px solid rgba(88, 182, 222, 0.15)",
                                          opacity: "0.8",
                                          background: "#29CEC8",
                                          fontSize: "12px",
                                          marginTop: "3px",
                                        }}
                                        className="patientRecbutton1"
                                      >
                                        {generateIcon(carer.name)} {carer.name}
                                      </div>
                                    </div>
                                  ))
                                ) : (
                                  <div>No nurse found.</div>
                                )}
                              </div>
                            </SimpleBar>

                            <label
                              style={{
                                fontFamily: "Poppins",
                                color: "black",
                                marginTop: "10px",
                                fontSize: "15px",
                                // marginLeft: "10px",
                              }}
                            >
                              <b>Carers</b>
                            </label>
                            <SimpleBar style={{ maxHeight: "100px" }}>
                              <div style={{ }}>
                                {assignData &&
                                  assignData.carer &&
                                  assignData.carer.length > 0 ? (
                                  assignData.carer.map((carer, index) => (
                                    <div key={index}>
                                      <div
                                        style={{
                                          marginLeft: "10px",
                                          borderRadius: "10px",
                                          border:
                                            "1.124px solid rgba(88, 182, 222, 0.15)",
                                          opacity: "0.8",
                                          background: "#29CEC8",
                                          fontSize: "12px",
                                          marginTop: "3px",
                                        }}
                                        className="patientRecbutton1"
                                      >
                                        {generateIcon(carer.name)} {carer.name}
                                      </div>
                                    </div>
                                  ))
                                ) : (
                                  <div>No carer found.</div>
                                )}
                              </div>
                            </SimpleBar>
                          </div>

                          <div className="col-4" style={{paddingBottom:"1%"}}>
                            <div className="rightassign">
                              <div
                                style={{
                                  marginTop: "30%",
                                  marginLeft: "32%",
                                  justifyContent: "center",
                                }}
                              >
                                <img
                                  src={hospitalbed}
                                  className=""
                                  width="65px"
                                />
                              </div>
                              <br />
                              {assignData && assignData.allotment && (
                                <>
                                  <label style={{ marginLeft: "20px" }}>
                                    {" "}
                                    <b>
                                      Building:{" "}
                                      <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                      <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                      {assignData.allotment.building}{" "}
                                    </b>
                                  </label>
                                  <br />
                                  <label style={{ marginLeft: "20px" }}>
                                    <b>
                                      Floor: <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                      <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                      <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                      &nbsp;&nbsp;{assignData.allotment.floor}
                                    </b>
                                  </label>
                                  <br />
                                  {/* <label style={{marginLeft:"20px"}}><b>{assignData.allotment.ward ? "Ward /Bedunit:" : "Room:"} &nbsp;&nbsp;{assignData.allotment.ward ? assignData.allotment.ward+"/"+assignData.allotment.bedunit : assignData.allotment.room }</b></label> */}
                                  {assignData.allotment.ward && (
                                    <>
                                      {" "}
                                      <label style={{ marginLeft: "20px" }}>
                                        <b>
                                          Ward/BedNo: <span>&nbsp;&nbsp;</span>
                                          {assignData.allotment.ward +
                                            "/" +
                                            assignData.allotment.bedunit}
                                        </b>
                                      </label>
                                    </>
                                  )}
                                  {assignData.allotment.room && (
                                    <>
                                      {" "}
                                      <label style={{ marginLeft: "20px" }}>
                                        <b>
                                          RoomNo: <span>&nbsp;</span>
                                          <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                          &nbsp;&nbsp;{assignData.allotment.room}
                                        </b>
                                      </label>
                                    </>
                                  )}
                                  <br />
                                </>
                              )}
                              
                              {/* {assignData && assignData.allotment.ward ? <label style={{marginLeft:"20px"}}><b>Bed Unit:<span>&nbsp;&nbsp;&nbsp;&nbsp;</span><span>&nbsp;&nbsp;&nbsp;&nbsp;</span> { assignData.allotment.bedunit}</b></label>:""} */}
                              {assignData &&
                                (login_datas.user_type === "hospital_bdo"||login_datas.user_type === "hospital_pro") &&
                               (
                                <button
                                style={{marginTop:"0px",height:"40px"}}
                                  className="assignbutton"
                                  onClick={() => handleClickAssign()}
                                >
                                  Reassign
                                </button>
                              ) }
                            </div>
                          </div>
                        </div>
                      </>
                    ):therapy ? (
                      <Therapy id={id} />                   
                    ):treat? (
                      <TreatPlan id={id} />
                    ):pro?(
                    <Procedure id={id} />
                    ):testScan?
                    (<TestScan id={id} />):""
                  }
                  </div>
                </div>

              </div>
             

              
                


              {flag2 === 2 &&
                login_datas &&
                login_datas.user_type &&
                login_datas.user_type === "hospital_doctor" || login_datas.user_type === "hospital_allied_prof"|| login_datas.user_type === "hospital_nurse" ? (
                <div 
                  className="new-rec-section"
                  ref={ref3}
                >
                  <h5 className="sub-title head-strip"> Diagnostics Info </h5>

<div className="row" style={{paddingLeft:"2%",paddingRight:"2%",textAlign:"center"}}>
<div className="col-1"></div>
  <div className="col-5 sub-section-rec">
    <div className="sub-section-header box-theme ">
                        <h6 className="sub-section-title"> Vitals </h6>
                      </div>
                      <div className="vitals-colv">
                          <div className="vitals-row">
                            <div className="">
                              <br />
                              <button
                              style={{marginTop:"33%"}}
                                className="btn btn-primary btn-col"
                                onClick={handleSugar}
                              >
                                +
                              </button>
                            </div>
                            <div
                              className="each-list-title each-tile box-4 colored-box colored-box-small"
                              id="vitals-tile-small"
                              onClick={handleGlucoseOverlay}
                            >
                              <span style={{padding:"1%"}}className="history-link">
                                <h6>Blood Sugar </h6>
                              </span>
                            </div>

                            <div
                              className="each-list-title each-tile box-4 colored-box colored-box-small"
                              id="vitals-tile-small"
                              onClick={handlePulseOverlay}
                            >
                              <span style={{padding:"1%"}} className="history-link">
                                <h6> Pulse </h6>
                              </span>
                            </div>
                            <div>
                              <br />
                              <button
                               style={{marginTop:"33%"}}
                                onClick={handlePulse}
                                className="btn btn-primary btn-col"
                              >
                                +
                              </button>
                            </div>
                          </div>

                          <div className="vitals-row">
                            <div>
                              <br />
                              <button
                               style={{marginTop:"33%"}}
                                className="btn btn-primary btn-col"
                                onClick={handleBp}
                              >
                                +
                              </button>
                            </div>
                            <div
                              className="each-list-title each-tile box-4 colored-box colored-box-small"
                              id="vitals-tile-small"
                              onClick={handleBpOverlay}
                            >
                              <span style={{padding:"1%"}} className="history-link">
                                <h6>Blood Pressure </h6>
                              </span>
                            </div>

                            <div
                              className="each-list-title each-tile box-4 colored-box colored-box-small"
                              id="vitals-tile-small"
                              onClick={handleBmiOverlay}
                            >
                              <span style={{padding:"1%"}}className="history-link">
                                <h6> BMI </h6>
                              </span>
                            </div>
                            <div>
                              <br />
                              <button
                               style={{marginTop:"33%"}}
                                onClick={handleBmi}
                                className="btn btn-primary btn-col"
                              >
                                +
                              </button>
                            </div>
                          </div>
                          <div className="vitals-row">
                            <div className="">
                              <br />
                              <button
                               style={{marginTop:"33%"}}
                                className="btn btn-primary btn-col"
                                onClick={handleChol}
                              >
                                +
                              </button>
                            </div>
                            <div
                              className="each-list-title each-tile box-4 colored-box colored-box-small"
                              id="vitals-tile-small"
                              onClick={handleCholOverlay}
                            >
                              <span style={{padding:"1%"}} className="history-link">
                                <h6>Cholesterol </h6>
                              </span>
                            </div>
                            <div
                              className="each-list-title each-tile box-4 colored-box colored-box-small"
                              id="vitals-tile-small"
                              onClick={handleTempOverlay}
                            >
                              <span style={{padding:"1%"}} className="history-link">
                                <h6> Temperature </h6>
                              </span>
                            </div>
                            <div>
                              <br />
                              <button
                               style={{marginTop:"33%"}}
                                onClick={handleTemp}
                                className="btn btn-primary btn-col"
                              >
                                +
                              </button>
                            </div>
                          </div>
                          <div className="vitals-row">
                            <div className="">
                              <br />
                              <button
                               style={{marginTop:"33%"}}
                                className="btn btn-primary btn-col"
                                onClick={handleSp}
                              >
                                +
                              </button>
                            </div>
                            <div
                              className="each-list-title each-tile box-4 colored-box colored-box-small"
                              id="vitals-tile-small"
                              onClick={handleSpOverlay}
                            >
                              <span style={{padding:"1%"}}className="history-link">
                                <h6>SPO2 </h6>
                              </span>
                            </div>
                            <div>
                              {" "}
                              <span>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              </span>
                            </div>
                          </div>
                        </div>
  </div>
 &nbsp;&nbsp;&nbsp;&nbsp;
  <div className="col-5 sub-section-rec">
  <div className="sub-section-header box-theme">
                        <h6 className="sub-section-title">
                          {" "}
                          Primary/Chronic Medical Condition{" "}
                        </h6>
                      </div>
                      <div className="sub-section-body comorb-body-content">
                        <Chronic id={id} />
                      </div>
  </div>
</div>
<div className="row" style={{paddingLeft:"2%",paddingRight:"2%",textAlign:"center",marginTop:"2%"}}>
<div className="col-1"></div>
  <div className="col-5 sub-section-rec">
  <div className="sub-section-header box-theme">
                        <h6 className="sub-section-title"> Comorbidities </h6>
                      </div>
                      <div className="sub-section-body comorb-body-content">
                        <Comorbidities id={id} />
                      </div>
  </div>
  &nbsp;&nbsp;&nbsp;&nbsp;
  <div className="col-5 sub-section-rec">
  <div className="sub-section-header box-theme">
                        <h6 className="sub-section-title"> Findings </h6>
                      </div>
                      <div className="sub-section-body comorb-body-content">
                        <Diagnosis id={id} />
                      </div>
  </div>
  </div>


                
                 
                </div>
              ) : null}
              {/*******************************OBSERVATIONS************/}
              {flag2 === 2 &&
                login_datas &&
                login_datas.user_type &&
                login_datas.user_type === "hospital_doctor" || login_datas.user_type === "hospital_allied_prof"|| login_datas.user_type === "hospital_nurse" ? (
                <div
                  className="each-section summary  section-diagnostic"
                  ref={ref5}
                >
                  <h5 className="sub-title head-strip"> Observations </h5>

                  <br />

                  {/****placeholder only****/}

                  <ViewObs
                    patientId={id}
                    handleObs={handleObs}
                    obs={observationsArray}
                  />

                  <div
                    className="form-button"
                    onClick={handleObservationsOverlay}
                  >
                    <button className="btn btn-primary btn-col btn-col btn-smallest btn-square mb-3">
                      {" "}
                      Add Observations{" "}
                    </button>
                  </div>

                  {/******placeholder*** ENd*/}
                </div>
              ) : null}
              {/*****************************OBS END ********************/}
              {flag2 === 2 &&
                login_datas &&
                login_datas.user_type &&
                login_datas.user_type === "hospital_doctor" || login_datas.user_type === "hospital_allied_prof"|| login_datas.user_type === "hospital_nurse" ? (
                <div
                  className="each-section summary  section-diagnostic"
                  ref={ref6}
                >
                  <h4 className="sub-title head-strip"> Symptoms </h4>

                  <br />

                  <ViewSymptoms
                    patientId={id}
                    handleObs={handleObs}
                    obs={symptoms}
                  />

                  <div className="form-button" onClick={handleSymptomsOverlay}>
                    <button className="btn btn-primary btn-col btn-col btn-smallest btn-square">
                      {" "}
                      Add Symptoms{" "}
                    </button>
                  </div>

                  {/******placeholder*** ENd*/}
                </div>
              ) : null}
              {flag2 === 2 &&
                login_datas &&
                login_datas.user_type &&
                login_datas.user_type === "hospital_doctor" || login_datas.user_type === "hospital_allied_prof"|| login_datas.user_type === "hospital_nurse" ? (
                <div
                  className="each-section summary  section-diagnostic"
                  ref={ref7}
                >
                  <h4 className="sub-title head-strip"> Allergy</h4>

                  <br />

                  <ViewAller
                    patientId={id}
                    handleObs={handleObs}
                    obs={allergyArray}
                  />
                  <div className="form-button" onClick={handleAllergyOverlay}>
                    <button className="btn btn-primary btn-col btn-col btn-smallest btn-square">
                      {" "}
                      Add Allergy{" "}
                    </button>
                  </div>

                  {/******placeholder*** ENd*/}
                </div>
              ) : null}
             
              {flag2 === 2 &&
                login_datas &&
                login_datas.user_type &&
                login_datas.user_type === "hospital_doctor" || login_datas.user_type === "hospital_allied_prof"|| login_datas.user_type === "hospital_nurse" ? (
                <div
                  className="each-section summary  section-diagnostic"
                  ref={ref8}
                >
                  <h5 className="sub-title head-strip">Medical Reports</h5>

                  <br />

                  <ReactTable
                    columns={columnsReports}
                    data={item1}
                    defaultPageSize={5}
                    style={{ color: "black" }}
                    className="observation-table"
                  ></ReactTable>
                  <br />
                </div>
              ) : null}
              <br />


           
          </div>
          <div className="col-1"style={{marginTop:"1%",padding:"0%",marginLeft:"75%",position:"fixed"}}>
            <div className=" rightdivinpr checkBoxWebmenu">
            {features.includes("personal_inventory") && <div onClick={() => {
                if (
                  login_datas &&
                  (login_datas.user_type === "hospital_bdo"||login_datas.user_type === "hospital_pro") && assignData &&
                  assignData.patient
                ) {
                  navigate("/personal-inventory", {
                    state: {from:"pr", name: name, id: id, gender: gender, woundId: "", mobile_number: mob, room: assignData ? assignData.bedunit_code : "" ,admission:assignData? assignData.admission_date:""},

                  })
                }


              }} style={{
               backgroundColor:"rgba(43, 147, 160, 1)",marginBottom:"2%",borderRadius:"5px", color:"white", padding: "20%", cursor: (login_datas &&
                  login_datas.user_type !== 'hospital_bdo'&&login_datas.user_type !== "hospital_pro") || (!assignData ) ? "not-allowed" : "pointer"
              }}>
                <svg style={{
                cursor: (login_datas &&
                  login_datas.user_type !== 'hospital_bdo'&&login_datas.user_type !== "hospital_pro") || (!assignData ) ? "not-allowed" : "pointer"
              }}xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M13 9V3h8v6h-8ZM3 13V3h8v10H3Zm10 8V11h8v10h-8ZM3 21v-6h8v6H3Zm2-10h4V5H5v6Zm10 8h4v-6h-4v6Zm0-12h4V5h-4v2ZM5 19h4v-2H5v2Zm4-8Zm6-4Zm0 6Zm-6 4Z" /></svg><br/>
                <label className='text-style-here ' style={{color:"white"}} >Inventory</label>
              </div>}
              {features.includes("wound_management") &&<div >
                <div onClick={() => {
                  if (
                    login_datas &&
                    (login_datas.user_type === 'hospital_doctor' || login_datas.user_type === "hospital_allied_prof"|| login_datas.user_type === "hospital_nurse") && assignData &&
                    assignData.patient
                  ) {
                    handleWound();
                  }

                }}


                  style={{backgroundColor:"rgba(43, 147, 160, 1)",marginBottom:"2%",color:"white",borderRadius:"5px", 
                    padding: "20%", cursor: (login_datas &&
                      login_datas.user_type !== 'hospital_doctor' && login_datas.user_type !== 'hospital_allied_prof'&&login_datas.user_type !== "hospital_nurse")||!assignData? "not-allowed" : "pointer"
                  }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-width="1.5"><path d="M3 10c0-3.771 0-5.657 1.172-6.828C5.343 2 7.229 2 11 2h2c3.771 0 5.657 0 6.828 1.172C21 4.343 21 6.229 21 10v4c0 3.771 0 5.657-1.172 6.828C18.657 22 16.771 22 13 22h-2c-3.771 0-5.657 0-6.828-1.172C3 19.657 3 17.771 3 14v-4Z" /><path stroke-linecap="round" d="M8 10h8m-8 4h5" /></g></svg><br/>
                  <label className='text-style-here ' style={{color:"white"}}>Wound</label>
                </div>
              </div>}
              <div >
                <div   onClick={() => {
                  if (login_datas.user_type === "hospital_bdo"||login_datas.user_type === "hospital_pro") {
                    if (visitorDatas.length > 0) {
                      handleVisitorView()
                    }
                    else {
                      handleVisitor()
                    }

                  }
                  else {
                    handleVisitorView()
                  }

                }}

                  style={{backgroundColor:"rgba(43, 147, 160, 1)",color:"white",borderRadius:"5px", 
                    padding: "20%", cursor : "pointer"
                  }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M3.69 9.12a.88.88 0 0 0-.65-.28c-.41 0-.72.19-.92.58s-.15.76.17 1.11c1.18 1.06 1.93 1.81 2.25 2.25c.41.56.61 1.38.61 2.44c0 1.31.5 2.28 1.5 2.95c.56.44 1.17.77 1.85.99v-3.89c0-.94-.33-1.72-.96-2.35m8.92.05c-.62.62-.96 1.39-.96 2.3v3.93c.96-.34 1.76-.87 2.42-1.57c.65-.7.98-1.47.98-2.41c0-1.13.19-1.94.57-2.44c.09-.16.26-.36.53-.61c.23-.25.47-.49.71-.71c.23-.21.46-.43.68-.65l.33-.28a.9.9 0 0 0 .28-.66c0-.28-.09-.53-.28-.73c-.19-.2-.42-.3-.72-.3s-.5.09-.69.28M12 20c.69 0 1.36-.09 2-.28v-3.57c0-.59-.18-1.05-.59-1.49C13 14.22 12.53 14 12 14c-.53 0-1 .2-1.38.61c-.4.39-.62.85-.62 1.45v3.66c.64.19 1.31.28 2 .28M9 8.5c0 .83-.67 1.5-1.5 1.5S6 9.33 6 8.5S6.67 7 7.5 7S9 7.67 9 8.5m9 0c0 .83-.67 1.5-1.5 1.5S15 9.33 15 8.5S15.67 7 16.5 7s1.5.67 1.5 1.5m-4.5-3c0 .83-.67 1.5-1.5 1.5s-1.5-.67-1.5-1.5S11.17 4 12 4s1.5.67 1.5 1.5m0 5.5c0 .83-.67 1.5-1.5 1.5s-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5s1.5.67 1.5 1.5Z"/></svg><br/>
                  <label className='text-style-here ' style={{color:"white"}}>Family Member</label>
                </div>
              </div>
            </div>
            
          </div>


        
        </div>

      </div>

      {/********* Set Reminders******/}

   

      {nextErr !== "nil" ? (
        <ErrorNextPopup
          show={errorNextModalShow}
          onHide={() => setErrorNextModalShow(false)}
        />
      ) : null}

   

      {glucoseShow ? (
        <GlucosePopup
          show={glucoseShow}
          onHide={() => setGlucoseShow(false)}
          id={id}
        />
      ) : null}

      {bpShow ? (
        <BpPopup show={bpShow} onHide={() => setBpShow(false)} id={id} />
      ) : null}

      {inferenceShow ? (
        <InferencePopup
          show={inferenceShow}
          onHide={() => setInferenceShow(false)}
        />
      ) : null}
      {symptomsShow ? (
        <SymptomsPopup
          show={symptomsShow}
          onHide={() => setSymptomsShow(false)}
        />
      ) : null}
    

      {pulseShow ? (
        <PulsePopup
          show={pulseShow}
          onHide={() => setPulseShow(false)}
          id={id}
        />
      ) : null}
      {tempshow ? (
        <TempPopup
          show={tempshow}
          onHide={() => setTempShow(false)}
          id={id}
        />
      ) : null}
      {spshow ? (
        <SpPopup show={spshow} onHide={() => setSpShow(false)} id={id} />
      ) : null}

      {addBmi ? (
        <AddBmiPopup
          show={addBmi}
          onHide={() => {
            setAddBmi(false);
          }}
        />
      ) : null}

      {bmiShow ? (
        <BmiPopup show={bmiShow} onHide={() => setBmiShow(false)} id={id} />
      ) : null}

      {cholesterolShow ? (
        <CholesterolPopup
          show={cholesterolShow}
          onHide={() => setCholesterolShow(false)}
          id={id}
        />
      ) : null}

      {inferenceShow ? (
        <InferencePopup
          show={inferenceShow}
          onHide={() => setInferenceShow(false)}
        />
      ) : null}

      {submitMsg1 === "error" ? (
        <SubmitErrorPopup1
          show={errorSubmit1}
          onHide={() => {
            setErrorSubmit1(false);
            setSubmitMsg1("");
          }}
        />
      ) : null}
   
     
     {submitMsg === "success" ? (
        <SubmitPopUp
          show={modalShow}
          onHide={() => {
            setModalShow(false);
           
            setErrorMessage("");
            setDoctorName(login_datas.doctor_name);
            setSearchAssign("");
            setSubmitMsg("");

            setProcedureRefresh(!procedureRefresh);
            setScanRefresh(!scanRefresh);
          
          }}
        />
      ) :  (
        ""
      )}
      {sizeShow ? (
        <SizePopUp
          show={sizeShow}
          onHide={() => {
            setSizeShow(false);
          }}
        />
      ) : null}

      {uploadShow ? (
        <LoadPopUp
          show={uploadShow}
          onHide={() => {
            setUploadShow(false);
          }}
        />
      ) : null}

      {validShow ? (
        <ValidPopUp
          show={validShow}
          onHide={() => {
            setValidShow(false);
          }}
        />
      ) : null}

      {contentShow ? (
        <ContentPopUp
          show={contentShow}
          onHide={() => {
            setContentShow(false);
          }}
        />
      ) : null}

     

      {imgErrorShow ? (
        <UpdateImgPopup
          show={imgErrorShow}
          onHide={() => {
            setImgErrorShow(false);
          }}
        />
      ) : null}
    

    

      {remarkViewShow ? (
        <RemarkViewPopup
          show={remarkViewShow}
          onHide={() => {
            setRemarkViewShow(false);
          }}
        />
      ) : null}
      {procedureMsg !== "" ? (
        <ProcedureUpdatePopUp
          show={procedureShow}
          onHide={() => {
            setProcedureShow(false);
            setProcedureRefresh(!procedureRefresh);
          }}
        />
      ) : (
        ""
      )}

      {editSuccess ? (
        <DetailsUpdateSuccessPopUp
          show={editSuccess}
          onHide={() => {
            setEditSuccess(false);
          }}
        />
      ) : null}

      {addShow ? (
        <AddPopup
          show={addShow}
          onHide={() => {
            setAddShow(false);
          }}
        />
      ) : null}
      {historyEditShow ? (
        <HistoryPopup
          show={historyEditShow}
          onHide={() => {
            setHistoryEditShow(false);
          }}
          onSave={() => {
            if (sendData !== familyData.family_history) {
              setHistoryEditShow(false);
              const data = familyData;
              data.family_history = sendData;
              callSaveDetails();
            }
          }}
        />
      ) : null}

   
      {viewShow ? (
        <ViewPopup
          show={viewShow}
          onHide={() => {
            setViewShow(false);
          }}
        />
      ) : null}
    
    

   
      {procedureUpdate && (
        <UpdateSuccessPopup
          show={procedureUpdate}
          onHide={() => setProcedureUpdate(false)}
        />
      )}

      {messagesShow === false ? (
        <div
          className="dr-msgs-basic-sticky "
          onClick={handleMessagesOverlay}
          title="Message via Felixa Messenger"
        ></div>
      ) : null}

      {observationsShow ? (
        <ObservationsPopup
          show={observationsShow}
          onHide={() => setObservationsShow(false)}
          id={id}
        />
      ) : null}

      {messagesShow ? (
        <MessagesPopup show={messagesShow} onHide={handleTrack} id={id} />
      ) : null}

      {weightAddShow ? (
        <WeightAddPopup
          show={weightAddShow}
          seteditweight={weight}
          onHide={() => {
            setWeightAddShow(false);
          }}
        />
      ) : null}

      {heightAddShow ? (
        <HeightAddPopup
          show={heightAddShow}
          seteditheight={height}
          onHide={() => {
            setHeightAddShow(false);
          }}
        />
      ) : null}
       {bloodAddShow ? (
        <BloodAddPopup
          show={bloodAddShow}
          seteditblood={blood}
          onHide={() => {
            setBloodAddShow(false);
          }}
        />
      ) : null}
      {maritalAddShow ? (
        <MaritalAddPopup
          show={maritalAddShow}
          seteditblood={blood}
          onHide={() => {
            setMaritalAddShow(false);
          }}
        />
      ) : null}
      {allergyShow ? (
        <AllergyPopup
          show={allergyShow}
          onHide={() => setAllergyShow(false)}
        />
      ) : null}
        {deleteNextVisitConfirmMsg === "success" ? (
                    <DeleteNextVisitConfirmPopup
                      show={deleteNextVisitConfirmModalShow}
                      onHide={() => setDeleteNextVisitConfirmModalShow(false)}
                    />
                  ) : (
                    ""
                  )}
               
      

      {addPulse ? (
        <AddPulsePopup
          show={addPulse}
          onHide={() => {
            setAddPulse(false);
          }}
        />
      ) : null}
      {addTemp ? (
        <AddTempPopup
          show={addTemp}
          onHide={() => {
            setAddTemp(false);
          }}
        />
      ) : null}
      {addSp ? (
        <AddSpPopup
          show={addSp}
          onHide={() => {
            setAddSp(false);
          }}
        />
      ) : null}

      {addBp ? (
        <AddBpPopup
          show={addBp}
          onHide={() => {
            setAddBp(false);
          }}
        />
      ) : null}
      {addChol ? (
        <AddCholestrolPopUp
          show={addChol}
          onHide={() => {
            setAddCholestrol(false);
          }}
        />
      ) : null}

      {addSugar ? (
        <AddSugarPopup
          show={addSugar}
          onHide={() => {
            setAddSugar(false);
          }}
        />
      ) : null}

     

      {historyShow ? (
        <ProcedureHistoryPopup
          show={historyShow}
          onHide={() => {
            setHistoryShow(false);
          }}
        />
      ) : null}
     

      

      {medEditShow ? (
        <MedHistoryPopup
          show={medEditShow}
          onHide={() => {
            setMedEditShow(false);
          }}
          onSave={() => {
            if (sendData !== familyData.past_medical_history) {
              setMedEditShow(false);
              const data = familyData;
              data.past_medical_history = sendData;
              setFamilyDatas(data);
              callSaveDetails();
            }
          }}
        />
      ) : null}
      {complaintEditShow ? (
        <ComplaintPopup
          show={complaintEditShow}
          onHide={() => {
            setComplaintEditShow(false);
          }}
          onSave={() => {
            if (sendData !== familyData.present_complaint) {
              setComplaintEditShow(false);
              const data = familyData;
              data.present_complaint = sendData;
              setFamilyDatas(data);
              callSaveDetails();
            }
          }}
        />
      ) : null}
     
      {complaintAddShow ? (
        <ComplaintAddPopup
          show={complaintAddShow}
          onHide={() => {
            setComplaintAddShow(false);
          }}
          onSave={() => {
            if (sendData !== familyData.present_complaint) {
              setComplaintAddShow(false);
              const data = familyData;
              data.present_complaint = sendData;
              setFamilyDatas(data);
              callSaveDetails();
            }
          }}
        />
      ) : null}
      {medAddShow ? (
        <MedHistoryAddPopup
          show={medAddShow}
          onHide={() => {
            setMedAddShow(false);
          }}
          onSave={() => {
            if (sendData !== familyData.past_medical_history&&sendData!==""&&sendData!==null) {
              setMedAddShow(false);
              const data = familyData;
              data.past_medical_history = sendData;
              setFamilyDatas(data);
              callSaveDetails();
            }else{
              alert.error("Add history")
            }
          }}
        />
      ) : null}
      {historyAddShow ? (
        <HistoryAddPopup
          show={historyAddShow}
          onHide={() => {
            setHistoryAddShow(false);
          }}
          onSave={() => {
            if (sendData !== familyData.family_history&&sendData!==""&&sendData!==null) {
              setHistoryAddShow(false);
              const data = familyData;
              data.family_history = sendData;
              setFamilyDatas(data);
              callSaveDetails();
            }else{
              alert.error("Add history")
            }
          }}
        />
      ) : null}
      {rptModalShow ? (
        <AddRptPopup
          show={rptModalShow}
          onHide={() => {
            setRptModalShow(false);
          }}
        />
      ) : null}

      {addVisitor ? (
        <AddVisitorPOpUp
          show={addVisitor}
          onHide={() => {
            setAddVisitor(false);
          }}
        />
      ) : null}

      {cardView ? (
        <ViewVisitorCard
          show={cardView}
          onHide={() => {
            setCardView(false);
          }}
        />
      ) : null}

      {cartEditView ? (
        <VisitorEditPopUp
          show={cartEditView}
          onHide={() => {
            setCardEditView(false);
          }}
        />
      ) : null}

    </>
  );
}
export default PatientRecord;
