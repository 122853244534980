import React, { useState, useEffect, useContext, useRef, useLayoutEffect } from 'react';
import axios from "axios";
import { APIURL } from "../../../Global";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import SubmitConfirmationPopup from "../../common/SubmitConfirm";
import { ConfirmationContext } from "../../contexts/SubmitConfirmationContext";
import DialysisFormView from './DialysisFormView';
import DeniedView from './DeniedView';
import { Modal } from 'react-bootstrap';
import { useAlert } from 'react-alert';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css'


const PriorityQueue = ({ approvedQueue, search, type }) => {
  const [showConfirmation, setShowConfirmation, closeConfirmationPopup] = useContext(ConfirmationContext);
  const [denyConfirmation, setDenyConfirmation] = useState(false);
  const [originalPriorityItem, setOriginalPriorityItem] = useState([]);
  const [originalPendingItem, setOriginalPendingItem] = useState([]);
  const [priorityItem, setPriorityItem] = useState([]);
  const [pendingItem, setPendingItem] = useState([]);
  const [deniedList, setDeniedList] = useState([])
  const [deniedShow, setDeniedShow] = useState(false)
  // const [approvedCount, setApprovedCount] = useState('');
  // const [pendingCount, setPendingCount] = useState('');
  const [patientDetails, setPatientDetails] = useState({});
  const [formShow, setFormShow] = useState(false);
  const userData = JSON.parse(sessionStorage.getItem("logindatas"));
  const isOfficialAccess = userData.is_official_access ? userData.is_official_access : "";
  const role = userData.role
  const alert = useAlert()
  const priorityQueueRef = useRef(null);
  const pendingQueueRef = useRef(null);
  const [datafetched, setDataFetched] = useState(false)
  const [deny, setDeny] = useState('')


  useEffect(() => {
    console.log(deniedShow)
  }, [deniedShow])

  useLayoutEffect(() => {
    if (priorityQueueRef.current) {
      priorityQueueRef.current.scrollTop = priorityQueueRef.current.scrollHeight;
    }
    if (pendingQueueRef.current) {
      pendingQueueRef.current.scrollTop = pendingQueueRef.current.scrollHeight;
    }
  }, [priorityItem, pendingItem]);

  const fetchQueueData = async () => {
    const tokenString = sessionStorage.getItem("usertoken");
    let token = tokenString.replace(/["]+/g, "");
    try {
      let response;
      if (type === 'dialysis') {
        response = await axios.get(`${APIURL}/api/v1/service-provider/dialysis-queue/?count=true`, {
          headers: {
            Authorization: "Token " + token,
          },
        });
      } else if (type === 'physio') {
        response = await axios.get(`${APIURL}/api/v1/service-provider/physio-queue/?count=true`, {
          headers: {
            Authorization: "Token " + token,
          },
        });
      } else {
        response = await axios.get(`${APIURL}/api/v1/service-provider/palliative-queue/?count=true`, {
          headers: {
            Authorization: "Token " + token,
          },
        });
      }
      const queueData = response.data.data;
      const approvedItems = queueData.filter(item => item.eligibility === 'approved' && item.is_patient_denied === false).sort((a, b) => a.priority - b.priority);
      const pendingItems = queueData.filter(item => item.eligibility === 'pending').sort((a, b) => a.priority - b.priority);
      const denied = response.data.admission_denied

      setDeniedList(denied)
      setOriginalPriorityItem(approvedItems);
      setOriginalPendingItem(pendingItems);
      setDataFetched(true)
      if (search === '') {
        setPriorityItem(approvedItems);
        setPendingItem(pendingItems);
      }
    } catch (error) {
      console.error('Error fetching queue data:', error);
    }
  };

  const mergeAndFilter = () => {
    const key = type === 'dialysis' ? 'de_number' : type === 'physio' ? 'phe_number' : 'pape_number';

    const filteredPriorityItems = approvedQueue
      .map(item => {
        const priorityMatch = originalPriorityItem.find(originalItem => originalItem[key] === item[key]);
        if (priorityMatch) {
          return priorityMatch;
        }
        return null;
      })
      .filter(item => item !== null);

    const filteredPendingItems = approvedQueue
      .map(item => {
        const pendingMatch = originalPendingItem.find(originalItem => originalItem[key] === item[key]);
        if (pendingMatch) {
          return pendingMatch;
        }
        return null;
      })
      .filter(item => item !== null);

    setPriorityItem(filteredPriorityItems);
    setPendingItem(filteredPendingItems);
  }

  useEffect(() => {
    fetchQueueData();
  }, []);

  useEffect(() => {
    if (search !== '' && datafetched) {
      mergeAndFilter();
    } else {
      setPriorityItem(originalPriorityItem);
      setPendingItem(originalPendingItem);
    }
  }, [datafetched, originalPriorityItem, originalPendingItem, search, approvedQueue]);



  const FormPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ marginLeft: "auto" }}>Application Details</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: "left" }}>
          <DialysisFormView content={patientDetails} />
        </Modal.Body>
      </Modal>
    );
  };

  const onDragEnd = (result) => {
    if (search !== '' || !isOfficialAccess || (role !== 'higher_official' && role !== 'manager')) {
      return; // Do not allow dragging if the user does not have the required permissions
    }

    const { source, destination } = result;

    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return;
    }

    let updatedSourceItems = [];
    let updatedDestinationItems = [];

    const moveItems = (sourceList, destinationList) => {
      const [removed] = sourceList.splice(source.index, 1);
      destinationList.splice(destination.index, 0, removed);
    };

    if (source.droppableId === 'priorityQueue' && destination.droppableId === 'priorityQueue') {
      updatedSourceItems = Array.from(priorityItem);
      moveItems(updatedSourceItems, updatedSourceItems);
      setPriorityItem(updatedSourceItems.map((item, index) => ({ ...item, index: index + 1 })));
    } else if (source.droppableId === 'pendingQueue' && destination.droppableId === 'pendingQueue') {
      updatedSourceItems = Array.from(pendingItem);
      moveItems(updatedSourceItems, updatedSourceItems);
      setPendingItem(updatedSourceItems.map((item, index) => ({ ...item, index: index + 1 })));
    } else if (source.droppableId === 'priorityQueue' && destination.droppableId === 'pendingQueue') {
      updatedSourceItems = Array.from(priorityItem);
      updatedDestinationItems = Array.from(pendingItem);
      moveItems(updatedSourceItems, updatedDestinationItems);
      setPriorityItem(updatedSourceItems.map((item, index) => ({ ...item, index: index + 1 })));
      setPendingItem(updatedDestinationItems.map((item, index) => ({ ...item, index: index + 1 })));
    } else if (source.droppableId === 'pendingQueue' && destination.droppableId === 'priorityQueue') {
      updatedSourceItems = Array.from(pendingItem);
      updatedDestinationItems = Array.from(priorityItem);
      moveItems(updatedSourceItems, updatedDestinationItems);
      setPendingItem(updatedSourceItems.map((item, index) => ({ ...item, index: index + 1 })));
      setPriorityItem(updatedDestinationItems.map((item, index) => ({ ...item, index: index + 1 })));
    }
  };

  const togglePopup = async (item) => {
    const tokenString = sessionStorage.getItem("usertoken");
    let token = tokenString.replace(/["]+/g, "");

    try {
      let response;
      if (item.is_inpatient) {
        const val = type === 'dialysis' ? item.de_number : item.phe_number
        response = await axios.get(`${APIURL}/api/v1/service-provider/pri-enquiry/?pv_admission_number=${val}`, {
          headers: {
            Authorization: "Token " + token,
          },
        });
      } else {
        if (type === 'dialysis') {
          response = await axios.get(`${APIURL}/api/v1/service-provider/dialysis-enquiry/?de_number=${item.de_number}`, {
            headers: {
              Authorization: "Token " + token,
            },
          });
        } else if (type === 'physio') {
          response = await axios.get(`${APIURL}/api/v1/patient/physio-enquiry/?phe_number=${item.phe_number}`, {
            headers: {
              Authorization: "Token " + token,
            },
          });
        } else {
          response = await axios.get(`${APIURL}/api/v1/service-provider/palliative-enquiry/?pape_number=${item.pape_number}`, {
            headers: {
              Authorization: "Token " + token,
            },
          });
        }
      }


      setPatientDetails(response.data[0]); // Set the response data to state

    } catch (error) {
      console.error('Error fetching approved data:', error);
    }
    console.log(patientDetails)
    setFormShow(!formShow); // Toggle form visibility
  };

  const confirmSubmit = () => {
    setShowConfirmation(true)
  }

  const handleConfirm = () => {
    handleSubmit()
  }

  const handleCancel = () => {
    closeConfirmationPopup()
  }

  const handleSubmit = () => {
    const approvedList = priorityItem.map((item, index) => ({
      [type === 'dialysis' ? 'de_number' : type === 'physio' ? 'phe_number' : 'pape_number']: type === 'dialysis' ? item.de_number : type === 'physio' ? item.phe_number : item.pape_number,
      priority: index + 1,
      eligibility: 'approved',
    }));

    const pendingList = pendingItem.map((item, index) => ({
      [type === 'dialysis' ? 'de_number' : type === 'physio' ? 'phe_number' : 'pape_number']: type === 'dialysis' ? item.de_number : type === 'physio' ? item.phe_number : item.pape_number,
      priority: index + 1,
      eligibility: 'pending',
    }));


    const tokenString = sessionStorage.getItem('usertoken');
    let str = tokenString.replace(/["]+/g, '');

    const data = {
      approved_list: [...approvedList, ...pendingList],
      is_suspended: true,
    };

    const endpoint = type === 'dialysis' ? 'service-provider/dialysis-queue/' : type === 'palliative' ? 'service-provider/palliative-queue/' : 'service-provider/physio-queue/';
    axios
      .post(`${APIURL}/api/v1/${endpoint}`, data, {
        headers: {
          "Authorization": 'Token ' + str,
          "Content-Type": "application/json"
        }
      })
      .then(res => {
        if (res.data.status === "success") {
          alert.success(res.data.message);
          setDeniedShow(false)
          fetchQueueData()
        } else {
          alert.error(res.data.message);
        }
      });
  };
  const handleDeny = (item) => {
    setDeny(item)
    setDenyConfirmation(true)
  }

  const handleCancelDeny = () => {
    setDenyConfirmation(false)
  }

  const handleConfirmDeny = () => {
    const tokenString = sessionStorage.getItem('usertoken');
    let str = tokenString.replace(/["]+/g, '');
    const eq_number = deny[type === 'dialysis' ? 'de_number' : type === 'physio' ? 'phe_number' : 'pape_number'];

    const data = {
      [type === 'dialysis' ? 'de_number' : type === 'physio' ? 'phe_number' : 'pape_number']: eq_number,
      queue_type: type
    }
    axios
      .put(`${APIURL}/api/v1/service-provider/admission-denial/`, data, {
        headers: {
          "Authorization": 'Token ' + str,
          "Content-Type": "application/json"
        }
      })
      .then(res => {
        if (res.data.status === "success") {
          setDenyConfirmation(false)
          fetchQueueData()
          alert.success(res.data.message);
        } else {
          alert.error(res.data.message);
        }
      });
  }

  const onHide = () => {
    setDeniedShow(false)
  }

  return (
    <div className='queue-container2' style={{ height: "80vh", marginTop: "37px" }}>
      <div className='row queue-head'>
        <div className='col-6 queue-head1'>

          <label className='col-11' style={{ justifyContent: 'center', display: 'flex' }}>Approved Queue  {deniedList.length > 0 && <button className='queue-badge' onClick={() => { setDeniedShow(true) }}>{deniedList.length}</button>}</label>
          {/* <label className='col-1' style={{ margin: 0, color: '#000', fontWeight: 400 }}>{approvedCount}</label> */}
          {/* <svg width="27" height="27" data-tooltip-id="approvedSearchTip" data-tip data-for="approvedSearchTip" onClick={() => setShowApprovedSearch(!showApprovedSearch)} viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.4374 15.7502H16.5487L16.2337 15.4464C16.9367 14.6296 17.4506 13.6675 17.7384 12.629C18.0262 11.904 18.081 10.5011 17.8987 9.43891C17.3699 6.31141 14.7599 3.8139 11.6099 3.4314C10.5025 3.2913 9.37767 3.4064 8.32156 3.76788C7.26546 4.12936 6.30604 4.72765 5.51672 5.51697C4.72741 6.30628 4.12912 7.2657 3.76764 8.32181C3.40615 9.37792 3.29106 10.5027 3.43116 11.6102C3.81366 14.7602 6.31116 17.3702 9.43866 17.8989C10.5008 18.0812 11.5902 18.0265 12.6287 17.7386C13.6673 17.4508 14.6294 16.937 15.4462 16.2339L15.7499 16.5489V17.4377L20.5312 22.2189C20.9924 22.6802 21.7462 22.6802 22.2074 22.2189C22.6687 21.7577 22.6687 21.0039 22.2074 20.5427L17.4374 15.7502ZM10.6874 15.7502C7.88616 15.7502 5.62491 13.4889 5.62491 10.6877C5.62491 7.8864 7.88616 5.62515 10.6874 5.62515C13.4887 5.62515 15.7499 7.8864 15.7499 10.6877C15.7499 13.4889 13.4887 15.7502 10.6874 15.7502Z" fill="#fff" />
          </svg>
          <Tooltip id="approvedSearchTip" place="top" effect="solid">
            {showApprovedSearch && (
              <input
                type="text"
                value={approvedSearchTerm}
                onChange={(e) => setApprovedSearchTerm(e.target.value)}
                placeholder="Search Approved Queue..."
              />
            )}
          </Tooltip> */}
        </div>
        <div className='col-6 queue-head2'><label className='col-11' style={{ justifyContent: 'center', display: 'flex' }}>Pending Queue</label>
          {/* <label className='col-1' style={{ margin: 0, color: '#000', fontWeight: 400 }}>{pendingCount}</label> */}
          {/* <svg width="27" height="27" data-tooltip-id="pendingSearchTip" onClick={() => setShowPendingSearch(!showPendingSearch)} data-tip data-for="pendingSearchTip" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.4374 15.7502H16.5487L16.2337 15.4464C16.9367 14.6296 17.4506 13.6675 17.7384 12.629C18.0262 11.904 18.081 10.5011 17.8987 9.43891C17.3699 6.31141 14.7599 3.8139 11.6099 3.4314C10.5025 3.2913 9.37767 3.4064 8.32156 3.76788C7.26546 4.12936 6.30604 4.72765 5.51672 5.51697C4.72741 6.30628 4.12912 7.2657 3.76764 8.32181C3.40615 9.37792 3.29106 10.5027 3.43116 11.6102C3.81366 14.7602 6.31116 17.3702 9.43866 17.8989C10.5008 18.0812 11.5902 18.0265 12.6287 17.7386C13.6673 17.4508 14.6294 16.937 15.4462 16.2339L15.7499 16.5489V17.4377L20.5312 22.2189C20.9924 22.6802 21.7462 22.6802 22.2074 22.2189C22.6687 21.7577 22.6687 21.0039 22.2074 20.5427L17.4374 15.7502ZM10.6874 15.7502C7.88616 15.7502 5.62491 13.4889 5.62491 10.6877C5.62491 7.8864 7.88616 5.62515 10.6874 5.62515C13.4887 5.62515 15.7499 7.8864 15.7499 10.6877C15.7499 13.4889 13.4887 15.7502 10.6874 15.7502Z" fill="#fff" />
          </svg>
          <Tooltip
            id="pendingSearchTip"
            place="top"
            effect="solid"
            clickable={true}
          >
            {showPendingSearch && (
              <input
                style={{ backgroundColor: '#000000', border: 'none' }}
                type="text"
                value={pendingSearchTerm}
                onChange={(e) => setPendingSearchTerm(e.target.value)}
                placeholder="Search Pending Queue..."
                onClick={(e) => e.stopPropagation()}
                onFocus={Tooltip.hide}
                onBlur={Tooltip.show}
              />
            )}
          </Tooltip> */}
        </div>
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <div className='row queue-content-trust' style={{ height: (isOfficialAccess && (role === 'higher_official' || role === 'manager')) ? "80%" : '90%' }}>
          <Droppable droppableId="priorityQueue">
            {(provided) => (
              <div
                className='col-6 queue-content1'
                {...provided.droppableProps}
                ref={(ref) => {
                  provided.innerRef(ref);
                  priorityQueueRef.current = ref;
                }}
              >
                {priorityItem.length > 0 ? priorityItem.map((item, index) => (
                  <Draggable
                    key={item.id}
                    draggableId={item.id.toString()}
                    index={index}
                    isDragDisabled={search !== '' || !isOfficialAccess || (role !== 'higher_official' && role !== 'manager')}
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                          ...provided.draggableProps.style,
                          marginRight: ".6%",
                          backgroundColor: item.is_inpatient ? '#e4dd9f80' : ''
                        }}
                        className="dialysis_priorityqueue"
                      >
                        <div className="col-7 dename-content">
                          {index + 1}. {item.applicant_details.full_name || ''}
                        </div>
                        <div className="col-4 eye-content">
                          <div className="row eye-content-div">
                            <div className="col-6 envelop">
                              <span className="fa-stack fa-lg">
                                <i className="fa fa-envelope" aria-hidden="true" style={{ fontSize: "20px" }}></i>
                              </span>
                            </div>
                            <div className="col-6 eye">
                              <i onClick={() => togglePopup(item)} className="fa fa-eye" style={{ fontSize: "18px" }} aria-hidden="true"></i>
                            </div>
                          </div>
                        </div>
                        {role === 'bdo' && <div className="col-1 eye-content" style={{ padding: 0 }}>
                          <div className='deny'>
                            <svg onClick={() => handleDeny(item)} xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24"><path fill="red" d="m8.4 16.308l3.6-3.6l3.6 3.6l.708-.708l-3.6-3.6l3.6-3.6l-.708-.708l-3.6 3.6l-3.6-3.6l-.708.708l3.6 3.6l-3.6 3.6zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709M12 20q3.35 0 5.675-2.325T20 12t-2.325-5.675T12 4T6.325 6.325T4 12t2.325 5.675T12 20m0-8" /></svg>
                          </div>
                        </div>}
                      </div>
                    )}
                  </Draggable>
                )) : <div className="nodata" >No requests approved!!</div>}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          <Droppable droppableId="pendingQueue">
            {(provided) => (
              <div
                className='col-6 queue-content2'
                {...provided.droppableProps}
                ref={(ref) => {
                  provided.innerRef(ref);
                  pendingQueueRef.current = ref;
                }}
              >
                {pendingItem.length > 0 ? pendingItem.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id.toString()} index={index} isDragDisabled={search !== '' || !isOfficialAccess || (role !== 'higher_official' && role !== 'manager')}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                          ...provided.draggableProps.style,
                          marginLeft: ".6%",
                          backgroundColor: item.is_inpatient ? '#e4dd9f80' : ''
                        }}
                        className="dialysis_pendingqueue"
                      >
                        <div className="col-8 dename-content">
                          {index + 1}. {item.applicant_details.full_name || ''}
                        </div>
                        <div className="col-4 eye-content">
                          <div className="row eye-content-div">
                            <div className="col-6 envelop">
                              <span className="fa-stack fa-lg">
                                <i className="fa fa-envelope" aria-hidden="true" style={{ fontSize: "20px" }}></i>
                              </span>
                            </div>
                            <div className="col-6 eye">
                              <i onClick={() => togglePopup(item)} className="fa fa-eye" style={{ fontSize: "18px", margin: "4px 0" }} aria-hidden="true"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Draggable>
                )) : <div className="nodata" >No requests pending!!</div>}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </DragDropContext>
      {((role === "higher_official" || role === 'manager') && isOfficialAccess) && <div className="row queue-button">
        <button className="save" disabled={search !== ''} onClick={confirmSubmit}><b>Update Queue</b></button>
      </div>}

      {formShow ? (
        <FormPopUp
          show={formShow}
          onHide={() => {
            setFormShow(false);
          }}
        />
      ) : null}

      {showConfirmation && (
        <SubmitConfirmationPopup
          message={deniedList.length === 0 ? "Are you sure you want to update the Queue? " : "Are you sure you want to update the Queue? It will also remove the patinets in the withdrawal list."}
          onConfirm={handleConfirm}
          onCancel={handleCancel}
        />
      )
      }
      {denyConfirmation && (
        <SubmitConfirmationPopup
          message="Are you sure you want to cancel patients priority in the queue?"
          onConfirm={handleConfirmDeny}
          onCancel={handleCancelDeny}
        />
      )
      }
      {deniedShow && (
        <DeniedView
          list={deniedList}
          onHide={onHide}
          isOfficialAccess={isOfficialAccess}
          togglePopup={togglePopup}
          updateQueue={() => setShowConfirmation(true)} />
      )}

    </div>
  );
};

export default PriorityQueue;