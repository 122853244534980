import React, { useState, useEffect } from 'react';
import './folderview.css';
import { APIURL } from '../../Global';
import axios from 'axios';
import { FiFolder } from "react-icons/fi";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";

const Folder = ({ nodesData, setNodesData, onToggle, handleRender }) => {
    const [expandedNodes, setExpandedNodes] = useState([]);

    const handleToggle = (event, nodeId) => {
        event.stopPropagation();
        const newExpandedNodes = expandedNodes.includes(nodeId)
            ? expandedNodes.filter(id => id !== nodeId)
            : [...expandedNodes, nodeId];
        setExpandedNodes(newExpandedNodes);
        if (onToggle) {
            onToggle(event, newExpandedNodes);
        }
    };

    const handleNodeClick = async (event, node) => {
      event.stopPropagation();
  
      if (!node.children || node.children.length === 0) {
          try {
              const tokenString = sessionStorage.getItem("usertoken");
              const token = tokenString.replace(/["]+/g, "");
  
              const response = await axios.get(`${APIURL}/api/v1/service-provider/access-nodes/?parent_node=${node.node_access}`, {
                  headers: {
                      Authorization: "Token " + token
                  },
              });
  
              const newNodes = response.data.details;
              setNodesData(prevNodes => {
                  const updatedNodes = [...prevNodes];
                  const targetNode = findNode(updatedNodes, node.id);
                  if (targetNode) {
                      targetNode.children = newNodes;
                  }
                  return updatedNodes;
              });
          } catch (error) {
              console.error('Failed to fetch node children:', error);
          }
      }
  
      handleRender(node);
  };  

    const findNode = (nodes, nodeId) => {
        for (let node of nodes) {
            if (node.id === nodeId) return node;
            if (node.children) {
                const foundNode = findNode(node.children, nodeId);
                if (foundNode) return foundNode;
            }
        }
        return null;
    };

    const renderTree = (nodes, parentId = '') => {
      const nodeId = nodes.id ? String(nodes.id) : `node-${parentId}`;
      const isExpanded = expandedNodes.includes(nodeId);
      const iconColor = getColorForNode(nodes.node_access);
      const isFirstParent = nodes.parent_node === "0"; // Check if it's the first parent
  
      return (
          <div key={nodeId} className={`tree-item ${isFirstParent ? 'first-parent' : ''}`}>
              <div
                  className={`tree-item-label ${isExpanded ? 'expanded' : ''}`}
                  onClick={event => {
                      handleToggle(event, nodeId);
                      handleNodeClick(event, nodes);
                  }}
              >
                  <span
                      className="tree-item-icon"
                      style={{ backgroundColor: iconColor }}
                  ></span>
                  {nodes.children && nodes.children.length > 0 && (
                      <span className="expand-icon">
                          {isExpanded ? <IoIosArrowDown /> : <IoIosArrowForward />}
                      </span>
                  )}
                  <FiFolder className="folder-icon" />
                  <span>{nodes.node_name}</span>
              </div>
              {isExpanded && nodes.children && (
                  <div className="tree-item-children">
                      {nodes.children.map(node => renderTree(node, nodeId))}
                  </div>
              )}
          </div>
      );
  };
     

    return (
        <div className="tree-view">
            {nodesData.map(node => renderTree(node))}
        </div>
    );
};

const getColorForNode = (nodeAccess) => {
    const level = nodeAccess.split('.').length;
    const colorMap = {
        1: '#FF0000', // For node_access 0.1
        2: '#FF941C', // For node_access 0.1.1, 0.1.2, 0.1.3, etc.
        3: '#2FA533', // For node_access 0.1.1.1, 0.1.1.2, 0.1.2.1, 0.1.3.2, etc.
        4: '#0066FF', // Other nodes, if necessary
    };
    return colorMap[level] || '#0066FF';
};

export default Folder;