import React, { useState, useEffect, useRef } from "react";
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";
import "./addmedi.css";
import { Link } from "react-router-dom";
import AddMedicinePtient from "./AddMedicinePtient";
import AddRemark from "./AddRemark";
import AddMoreinfoMed from "./AddMoreinfoMed"
import Select from "react-select";
import axios from "axios";
import { APIURL,TZ } from "../../Global";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import EditRemark from "./EditRemark";
import { useAlert } from "react-alert";
import Review from "./Review";
import { Tooltip } from "react-bootstrap";
import { OverlayTrigger } from "react-bootstrap";
import EditMoreData from "./EditMoreData";
function AddMedicinesPat(props) {
  const [infoShow, setinfoShow] = useState(false);
  const alert = useAlert();
  const [n, setn] = useState(10)
  const [temppushitem, setTempPushItem] = useState([]);
  const [medList1, setMedList1] = useState([]);
  const [render, setRender] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [medId, setMedID] = useState("");
  const [doImmediatly,setDoImmediatly]=useState(false)
  const [addShow, setAddShow] = useState(false);
  const [viewShow, setViewShow] = useState(false);
  const [viewShowMore, setViewShowMore] = useState(false);
  const [flag, setFlag] = useState(false);
  const [addRentalShow, setAddRentalShow] = useState(false)
  const [meddata, setMedData] = useState("");
  const patientid = props.patient;
  const [remarkData, setRemarkData] = useState("");
  const [drugShow, setDrugShow] = useState(false);

  const [load, setLoad] = useState(false)
  const [drvalue, setDrvalue] = useState(0)
const [review,setReview]=useState(false)
  const [url, setUrl] = useState("")
const [mData,setMData]=useState([])
  const RouteOptions = [{value: "", label: ""},{ value: 'oral', label: 'Oral' },
  { value: 'topical_skin', label: 'Topical skin' },
  { value: 'topical-eyes', label: 'Topical eyes' },
  { value: 'topical_ears', label: 'Topical ears' },
  { value: 'inhalation', label: 'Inhalation' },
  { value: 'injections', label: 'Injections' },
  { value: 'vaginal', label: 'Vaginal' },
  { value: 'anal', label: 'Anal' },
  { value: 'sub_lingual', label: 'Sub lingual' }

  ]
  const DosageOptions = [{value: "", label: ""},{ value: 'tablets', label: 'Tablets' },
  { value: 'capsules', label: 'Capsules' },
  { value: 'chewable tablets', label: 'Chewable tablets' },
  { value: 'powders', label: 'Powders' },
  { value: 'solutions', label: 'Solutions' },
  { value: 'emulsions', label: 'Emulsions' },
  { value: 'suspensions', label: 'Suspensions' },
  { value: 'lotions', label: 'Lotions' },
  { value: 'creams', label: 'Creams' },
  { value: 'ointments', label: 'Ointments' },
  { value: 'effervescent granules', label: 'Effervescent granules' },
  { value: 'aerosols', label: 'Aerosols' },
  { value: 'gases', label: 'Gases' },
  { value: 'suppositories', label: 'Suppositories' },
  { value: 'injections', label: 'Injections' }
  ]
  const [focusedIndex, setFocusedIndex] = useState(-1);
  const [focusedRowIndex, setFocusedRowIndex] = useState(-1);
  const [item1, setItem] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [display, setDisplay] = useState(false);
  const [search, setSearch] = useState("name_suggest__completion");
  const [rend, setRend] = useState(false)
  const [cell, setCell] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [medicine, setMedicine] = useState([]);
  const MAX_ROWS = 10;
  const [infoData1, setInfoData1] = useState(""); 
   
  const inputRefs = useRef(Array(MAX_ROWS).fill().map(() => []));
  const handleDisplay = () => {
    setDisplay(false);

  }

  useEffect(() => {
    document.addEventListener('click', handleDisplay)

    return () => {
      document.removeEventListener('click', handleDisplay)
    }
  }, [])

  const handleKeyDown = (e, refIndex, rowIndex) => {
    if (e.key === "ArrowRight") {  
      e.preventDefault(); 
      const nextIndex = refIndex + 1;
      if (inputRefs.current[rowIndex][nextIndex]) { // Access the next field in the same row
        inputRefs.current[rowIndex][nextIndex].focus();
      }
    } else if (e.key === "ArrowLeft") {  
      e.preventDefault(); 
      const prevIndex = refIndex - 1;
      if (inputRefs.current[rowIndex][prevIndex]) { // Access the previous field in the same row
        inputRefs.current[rowIndex][prevIndex].focus();
      }
    } else if (e.key === "Enter") {  
      e.preventDefault(); 
      handleMedicine();
    }
  };

  useEffect(() => {
    const currentDate = new Date();
    const formattedDate = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}`;

    const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;


    const tokenString = sessionStorage.getItem('usertoken');

    let str = tokenString.replace(/["]+/g, '');


    axios.get(`${APIURL}/api/v1/doctor/update-current-medication/?medicines_list=current_medicines&patient_id=${patientid}&timeZone=${currentTimezone}&current_date=${formattedDate}`,
      {
        headers: {
          'Content-Type': 'application/json',
          "Authorization": 'Token ' + str
        }
      })
      .then(res => {




        if (res.data.status === "success") {

          let doctorname = "";

          let data = []
          if (res.data.doctor.length === 0) {
            data = []
          }

          if (res.data.doctor.length !== 0) {
            res.data.doctor.map((item) => {
              data = data.concat(item.medicines)
              doctorname = data.doctor_name;
            })
          }
          if (res.data.patient.length !== 0) {
            res.data.patient.map((item) => {
              data = data.concat(item.medicine)
              //patient_name=data.patient_name;
            })
          }
          setMedList1(data)

        }


      })




  }, [])


  const initialRender = useRef(true);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else if (searchTerm === "") {
      setMedicine([]);
      setDisplay(false);
    } else {


      axios
        .get(
          `https://medsearch.cianlogic.com/api/v1/doctor/medicine/suggest/?${search}=${searchTerm}`,
          {
            headers: {},
          }
        )
        .then((res) => {
          if (res.status === 200) {
            setMedicine(res.data[search][0].options);

            setDisplay(true);

          } else {
          }
        })
        .catch((error) => { });
    }
  }, [searchTerm]);

  useEffect(() => {


    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");

    axios
      .get(
        `${APIURL}/api/v1/doctor/online-prescription/?patient_id=${patientid}`,
        {
          headers: {
            Authorization: "Token " + str,
          },
        }
      )
      .then((res) => {
        if (res.data.status === "success") {
          if (res.data.message.length > 0) {
            const data = res.data.message;
            const filteredData = data.filter((item) => {
              return item.id !== "";
            });


            if (filteredData.length < 1) {
              setItem([
                {
                  id:"",
                  generic_name: "",
                  composition: "",
                  med_name: "",
                  strength: "",
                  strength1: "",
                  dosage_form: "",
                  dosage_form1: "",
                  route: "",
                  durationvalue: "",

                  dosage: "",
                  freequency: "",
                  intake_count: [],
                  intake_time: "",
                  dosage_count: "",
                  remarks: "",
                  intake_unit: "",
                  med_interactions: null
                },
              ]);
            } else {

            }

          }
        }
      })
      .catch((err) => { });
  }, [refresh]);


  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setMedID("");
    if (e.target.value === '') {
      setDisplay(false);
    }

  };


  useEffect(() => {
    setTableData(tableData);
  }, [render, tableData]);

  const intakecountoptions = [
    {value: "", label: ""},
    { value: "Daily", label: "Daily" },
    { value:"Weekly", label: "Weekly" },
    { value: "Monthly", label: "Monthly" },

  ];

  const freqOptions = [
    {value: "", label: ""},
    { value: "0-0-0-1", label: "0-0-0-1" },
    { value: "0-0-1-0", label: "0-0-1-0" },
    { value: "0-0-1-1", label: "0-0-1-1" },
    { value: "0-1-0-0", label: "0-1-0-0" },
    { value: "0-1-0-1", label: "0-1-0-1" },
    { value: "0-1-1-0", label: "0-1-1-0" },
    { value: "0-1-1-1", label: "0-1-1-1" },
    { value: "1-0-0-0", label: "1-0-0-0" },
    { value: "1-0-0-1", label: "1-0-0-1" },
    { value: "1-0-1-0", label: "1-0-1-0" },
    { value: "1-0-1-1", label: "1-0-1-1" },
    { value: "1-1-0-0", label: "1-1-0-0" },
    { value: "1-1-0-1", label: "1-1-0-1" },
    { value: "1-1-1-0", label: "1-1-1-0" },
    { value: "1-1-1-1", label: "1-1-1-1" },
  ];

  const durationOptions = [
    { value: "", label: "" },
    { value: "days", label: "Days" },
    { value: "weeks", label: "Weeks" },
    { value: "months", label: "Months" },
  ];

  const durationOptions1 = [
    { value: "", label: "" },
    { value: "weeks", label: "Weeks" },
    { value: "months", label: "Months" },
  ];

  const durationOptions2 = [

    { value: "", label: "" },
    { value: "months", label: "Months" },
  ];
  const intakeTimeOptions = [
    {value: "", label: ""},
    { value: "before food", label: "Before food" },
    { value: "after food", label: "After food" },
  ];

  const IntakeUnitOptions1 = [
    {value: "", label: ""},
    { value: "tbsp", label: "tbsp" },
    { value: "tsp", label: "tsp" },
    { value: "oz", label: "ounces" },
    { value: "nos", label: "number" },
    { value: "shots", label: "shots" },
    { value: "ml", label: "mL" },
    { value: "drops", label: "drops" },
    { value: "cc", label: "cc" },
    { value: "g", label: "g" },
    { value: "puffs", label: "puffs" },
    { value: "mg", label: "mg" },

  ];
  const IntakeUnitOptions2 = [
    {value: "", label: ""},
    { value: "ml", label: "mL" },
    { value: "cc", label: "cc" },
    { value: "shots", label: "shots" },
    { value: "mg", label: "mg" },
  ];
  const IntakeUnitOptions3 = [
    {value: "", label: ""},
    { value: "ml", label: "mL" },
    { value: "puffs", label: "puffs" },
    { value: "mg", label: "mg" },
  ]
  const IntakeUnitOptions4 = [
    {value: "", label: ""},
    { value: "ml", label: "mL" },
    { value: "drops", label: "drops" },
    { value: "mg", label: "mg" },
  ]

  const IntakeUnitOptions5 = [
    {value: "", label: ""},
    { value: "nos", label: "number" },
    { value: "tbsp", label: "tbsp" },
    { value: "tsp", label: "tsp" },
    { value: "g", label: "g" },
    { value: "mg", label: "mg" },
  ]
  const IntakeUnitOptions6 = [
    {value: "", label: ""},
    { value: "tbsp", label: "tbsp" },
    { value: "tsp", label: "tsp" },
    { value: "oz", label: "ounces" },
    { value: "ml", label: "mL" },
    { value: "drops", label: "drops" },
    { value: "mg", label: "mg" },
  ]
  const IntakeUnitOptions7 = [
    {value: "", label: ""},
    { value: "nos", label: "number" }
  ]




  const handleNew = (item2) => {
    const t = item1.filter(x => x.medicine_name == item2.medname)
    if (t.length > 0) {
      setAddRentalShow(false)
      setMedID("")
      return
    } else {
      const ddd = {
        created_at: "",
        doctor: 0,
        doctor_name: "",
        dosage_count: "", // needde frm db
        dosage_form: item2.dosgetypee,
        duration: "", // needed frm db
        frequency: "", // needed frm db
        hospital: 0, // needed frm db
        id: "",
        intake: "", // needed frm db
        intake_time: "", // needed frm db
        med_code:item2.med_code,
        med_type: item2.med_type,
        medicine: 0, // needed frm db
        medicine_name: item2.medname,
        modified_at: "",
        patient: 0, // needed frm db
        patient_name: "", // needed frm db
        remarks: "",
        route: "",
        strength: item2.strengthval,
        strength_unit: item2.strengthuni,
        composition: item2.composition,
        generic_name: item2.generic_name
      };

      temppushitem.push(ddd);
      setItem((oldArray) => oldArray.concat(ddd));
      setSearchTerm('');
      let data = { ...meddata };
      setMedData(data);
      setMedID("")
      setAddRentalShow(false)
    }
  }


  const rentalModalClose = () => {
    setAddRentalShow(false)
    setRefresh(!refresh)
    setFlag(true)
    setRend(!rend)
  }
  const handleChange = (e) => {
    const val = e.target.value;
    setSearch(val)
  }
  const notProceedClose = () => {
    setAddRentalShow(false)
    setRefresh(!refresh)
  }


  const AddRentalPopUp = (props) => {

    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-80w"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddMedicinePtient modalClose={rentalModalClose} notProceedClose={notProceedClose} render={handleNew} patientID={props.patient} />
        </Modal.Body>
        <Modal.Footer>
          <Button className='btn btn-primary btn-col' onClick={() => { handleClose(); }}>Close</Button>
        </Modal.Footer>
      </Modal>
    )
  }
  const ReviewPopUp = (props) => {

    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        size="md"
      >
        
        <div style={{display:"flex",width:"100%",padding:"1%"}}><h4 style={{width:"100%"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Preview </h4>
            {/* <svg  onClick={() => { setReview(false); }} style={{float:"right",marginRight:"1%",marginTop:"5%"}}xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 1024 1024"><path fill="currentColor" d="M512 0C229.232 0 0 229.232 0 512c0 282.784 229.232 512 512 512c282.784 0 512-229.216 512-512C1024 229.232 794.784 0 512 0m0 961.008c-247.024 0-448-201.984-448-449.01c0-247.024 200.976-448 448-448s448 200.977 448 448s-200.976 449.01-448 449.01m181.008-630.016c-12.496-12.496-32.752-12.496-45.248 0L512 466.752l-135.76-135.76c-12.496-12.496-32.752-12.496-45.264 0c-12.496 12.496-12.496 32.752 0 45.248L466.736 512l-135.76 135.76c-12.496 12.48-12.496 32.769 0 45.249c12.496 12.496 32.752 12.496 45.264 0L512 557.249l135.76 135.76c12.496 12.496 32.752 12.496 45.248 0c12.496-12.48 12.496-32.769 0-45.249L557.248 512l135.76-135.76c12.512-12.512 12.512-32.768 0-45.248"/></svg> */}
            </div> 
          
       
        <Modal.Body>
          <Review   MedData={ mData} onSuccess={(data) => {setDoImmediatly(data); setReview(false);medicineSubmit( mData,data); }} needUpdate={()=>setReview(false)} />
        </Modal.Body>
      
      </Modal>
    )
  }

  const handleClose = () => {
    setAddRentalShow(false);

  }


  const AddPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Body className="text-center">
          <AddRemark update={handleSend} save={addHandle} modalClose={props.onHide}/>
        </Modal.Body>
      </Modal>
    );
  };

 const ViewPopup = (props) => { 
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <EditRemark data={remarkData} update={handleSend} drug={drugShow} url={url} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
          {!drugShow && <Button className='btn btn-primary btn-col' onClick={props.onSave}>Update</Button>}
        </Modal.Footer>
      </Modal>
    );
  };
  const ViewMorePopup = (props) => { 
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
       <Modal.Header closeButton>
        <span >{item1[cell].medicine_name}</span>
       
        </Modal.Header>
        <Modal.Body >
          <EditMoreData data={infoData1} update={handleSend}  save={addHandle1}/>
        </Modal.Body>
       
      </Modal>
    );
  };
  const addHandle = () => {
    setAddShow(false);
    let list = [...item1];
    list[cell].remarks = sendData;
    alert.success("remarks added successfully")
  };
  const addHandle1 = () => {
    alert.success(infoShow?"more datas added successfully":"Updated successfully")
    setinfoShow(false);
    setViewShowMore(false)
    let list = [...item1];
    list[cell].route = sendData["route"];
    list[cell].intake_time = sendData["intakeTime"];
    // alert.success("more datas added successfully")
  };
  const handleMedicine = () => {


    setLoad(true)
    let isValid = true;

    const MedData = item1.filter((row, i) => {
      return row.med_name !== "" || row.id !== "" || row.med_code !== "";
    });
setMData(MedData)
    MedData.map((item) => {
      if (item.medicine_name === "") {

        alert.error("*Medicine name is mandatory!");

        isValid = false;
      }
      else if (item.strength === " " && !item.strength1) {
        alert.error("*Strength is mandatory!");

        isValid = false;
      } 
      else if (item.dosage_form === " " && !item.dosage_form1) {
        alert.error("*Dosage form is mandatory!");

        isValid = false;
      } 
      
      else if (item.frequency === "") {
        alert.error("*Frequency is mandatory!");

        isValid = false;
      }
      //  else if (item.intake_time === "") {
      //   alert.error("*Intake Time is mandatory!");

      //   isValid = false;
      // }
       else if (item.intake_unit === "" || item.intake_unit === undefined) {
        alert.error("*Quantity is mandatory!");

        isValid = false;
      } else if (item.duration < 1) {
        alert.error(
          "*Duration is mandatory!!- intake period of " +
            item.medicine_name +
            " is changed. Do corresponding change in duration"
        );
        isValid = false;
      }
    
      //(item.duration >= 1)
      else if (item.durationvalue === undefined || item.durationvalue == "") {
        alert.error("*Duration is mandatory!");
        isValid = false;
      }

      else if (item.intake === "") {
        alert.error("*Intake Period is mandatory!");

        isValid = false;
      } else if (item.dosage_count === "") {
        alert.error("*quantity is mandatory!");

        isValid = false;
      }


    });

    if (isValid) {
      setReview(true)
      
    }
    else {
      setLoad(false)
    }
  };

  const medicineSubmit = (MedData,x) => {


    const tokenString = sessionStorage.getItem("usertoken");
    let str = tokenString.replace(/["]+/g, "");
    if (MedData.length < 1) {
      return false;
    }

    let medList = [];



    for (let i = 0; i < MedData.length; i++) {
      let durationVal = 0;
      let durationunit = "";
      let frequencyValue = 0;
      let intakeValue = 0;
      let intakeTimeValue = 0;
      let intakeUnitValue = 0;

      if (

        MedData[i].duration.value &&
        MedData[i].durationvalue
      ) {
        durationVal = MedData[i].durationvalue;
        durationunit = MedData[i].duration.value;
      }
      if (
        typeof MedData[i].frequency === "object" &&
        MedData[i].frequency !== null
      ) {
        frequencyValue = MedData[i].frequency.value;
      } else {
        frequencyValue = MedData[i].frequency;
      }

      if (typeof MedData[i].intake === "object" && MedData[i].intake !== null) {
        intakeValue = MedData[i].intake.label;
      } else {
        intakeValue = MedData[i].intake;
      }
      if (
        typeof MedData[i].intake_time === "object" &&
        MedData[i].intake_time !== null
      ) {
        intakeTimeValue = MedData[i].intake_time.value;
      } else {
        intakeTimeValue = MedData[i].intake_time;
      }
      if (
        typeof MedData[i].intake_unit === "object" &&
        MedData[i].intake_unit !== null
      ) {
        intakeUnitValue = MedData[i].intake_unit.value;
      }

      else {
        intakeUnitValue = MedData[i].intake_unit;
      }
      medList.push({

        med_code:MedData[i].med_code,
        drug: MedData[i].medicine_name,
        medicine: MedData[i].id,
        med_type: MedData[i].med_type,
        frequency: frequencyValue,
        intake:intakeValue,
        intake_time: intakeTimeValue,
        intake_unit: intakeUnitValue,
        strength: MedData[i].strength === " " ? MedData[i].strength1 : MedData[i].strength,
        form: MedData[i].dosage_form === " " ? MedData[i].dosage_form1 : MedData[i].dosage_form,
        route: MedData[i].route === "" ? MedData[i].route1 : MedData[i].route,
        duration: MedData[i].durationvalue,
        duration_unit: MedData[i].duration,
        
        strength_unit: "mg",
        //dosage:"",
        intake_count: MedData[i].dosage_count,
        remarks: MedData[i].remarks,
      });
    }
    const dataToSend = {
      patient_id: patientid,
      medicines: medList,
    };

    axios
      .post(`${APIURL}/api/v1/doctor/patient-prescriptions/`, dataToSend, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + str,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          if(x===true){
            axios
            .post(`${APIURL}/api/v1/doctor/schedule-runner/?patient_id=${patientid}&schedule=medicine`,{},{
              headers: {
                "Content-Type": "application/json",
                Authorization: "Token " + str,
              },
            })
          }
          alert.success("successfully added");
          setLoad(false)
          props.onSuccess()
        } else {

          alert.error(res.data.message)
          setLoad(false)
        }
      })
      .catch((err) => {
        alert.error(" Please check whether medicine already added or not");
        setLoad(false)
      });

  };


  const handleAddProcedure = (e, item) => {

    e.preventDefault();
    e.preventDefault();
    initialRender.current = true;
    setSearchTerm(item.med_name);
    setMedID(item.id);
    setMedData(item);
    setDisplay(false);

    if (item.id === "") {
      alert.error("Please Select Medicine name from Dropdown");

    }

    const t = item1.filter(x => x.medicine_name == item.med_name)
    const s = medList1.filter(x => x.drug == item.med_name)

    if (s.length > 0) {

      setFlag(true)
      setRend(!rend)
      return
    } else if (t.length > 0) {
alert.error("medicine already added")
setSearchTerm('');
      return
    }
    else {
      setFlag(false)
      setRend(!rend)
    }
    if (flag == false) {
      if (item.id !== "") {
        const ddd = {
          created_at: item.created_at,
          doctor: 0,
          doctor_name: "",
          dosage_count: "", // needde frm db
          dosage_form: item.dosage_form,
          duration: "", // needed frm db
          frequency: "", // needed frm db
          hospital: 0, // needed frm db
          id: item.id,
          intake: "", // needed frm db
          intake_time: "", // needed frm db
          med_type: item.med_type,
          medicine: 0, // needed frm db
          medicine_name: item.med_name,
          med_code: item.med_code,
          modified_at: item.modified_at,
          patient: 0, // needed frm db
          patient_name: "", // needed frm db
          remarks: "",
          route: "",
          strength: item.strength,
          strength_unit: item.strength_unit,
          composition: item.composition,
          generic_name: item.generic_name,
          med_interactions: item.med_interactions,
          med_url: item.med_url
        };

        temppushitem.push(ddd);
        setItem((oldArray) => oldArray.concat(ddd));
        setSearchTerm('');
        let data = { ...meddata };
        data.med_code = '';
        data.generic_name = '';
        data.strength = '';
        data.strength_unit = '';
        setMedData(data);
        setMedID("")

      }

    }
  };
  useEffect(() => { if (item1.length > 10) { setn(item1.length) } }, [item1])
  useEffect(() => {

    if (flag == true) {



      if (flag === true) {
        setSearchTerm("")
        alert.error("*This medicine is already added to this patient!");
        
      }

      else {
        const ddd = {
          created_at: meddata.created_at,
          doctor: 0,
          doctor_name: "",
          dosage_count: "", // needde frm db
          dosage_form: meddata.dosage_form,
          duration: "", // needed frm db
          frequency: "", // needed frm db
          hospital: 0, // needed frm db
          id: meddata.id,
          intake: "", // needed frm db
          intake_time: "", // needed frm db
          med_type: meddata.med_type,
          medicine: 0, // needed frm db
          medicine_name: meddata.med_name,
          med_code: meddata.med_code,
          modified_at: meddata.modified_at,
          patient: 0, // needed frm db
          patient_name: "", // needed frm db
          remarks: "",
          route: "",
          strength: meddata.strength,
          strength_unit: meddata.strength_unit,
          composition: meddata.composition,
          generic_name: meddata.generic_name,
          med_interactions: meddata.med_interactions,
          med_url: meddata.med_url
        };

        temppushitem.push(ddd);
        setItem((oldArray) => oldArray.concat(ddd));
        setSearchTerm('');
        let data = { ...meddata };
        data.med_code = '';
        data.generic_name = '';
        data.strength = '';
        data.strength_unit = '';
        setMedData(data);
        setMedID("")
      }

    } else {

      if (medId !== "") {
        const ddd = {
          created_at: meddata.created_at,
          doctor: 0,
          doctor_name: "",
          dosage_count: "", // needde frm db
          dosage_form: meddata.dosage_form,
          duration: "", // needed frm db
          frequency: "", // needed frm db
          hospital: 0, // needed frm db
          id: meddata.id,
          intake: "", // needed frm db
          intake_time: "", // needed frm db
          med_type: meddata.med_type,
          medicine: 0, // needed frm db
          medicine_name: meddata.med_name,
          med_code: meddata.med_code,
          modified_at: meddata.modified_at,
          patient: 0, // needed frm db
          patient_name: "", // needed frm db
          remarks: "",
          route: "",
          strength: meddata.strength,
          strength_unit: meddata.strength_unit,
          composition: meddata.composition,
          generic_name: meddata.generic_name,
          med_interactions: meddata.med_interactions,
          med_url: meddata.med_url

        };

        temppushitem.push(ddd);
        setItem((oldArray) => oldArray.concat(ddd));
        setSearchTerm('');
        let data = { ...meddata };
        data.med_code = '';
        data.generic_name = '';
        data.strength = '';
        data.strength_unit = '';
        setMedData(data);
        setMedID("")
      }
    }

  }, [rend])


  var sendData = null;

  function handleSend(childData) {
    sendData = childData;
  }



  const deleteRow = (index) => {
    const newList = item1.filter((elt, i) => {
      return i !== index&&elt.medicine_name!==item1[index].med_name;
    });
    setItem([]);
    setItem(newList);
    setRender(!render);
  };


  const handleView = (data, index, drug, url) => {
    setRemarkData(data);
    setUrl(url)
    setCell(index);
    setViewShow(true);
    if (drug === "drug") {
      setDrugShow(true)
    }
    else {
      setDrugShow(false)
    }
  };
  const handleViewMore = (data, index) => {
    setInfoData1(data);
    setCell(index);
    setViewShowMore(true);
    
  };
  const handleAdd = (index) => {
    setCell(index);
    setAddShow(true);
  };
  const handleAddmore = (index) => {
    setCell(index);
    setinfoShow(true);
  };



  const renderMedicineType = (cellInfo) => {

    // if(item1[cellInfo.index].id!==""){
    return <p> {(item1[cellInfo.index].med_interactions && item1[cellInfo.index].med_interactions !== "null" && item1[cellInfo.index].med_interactions !== "" && item1[cellInfo.index].med_interactions !== null) ||
      (item1[cellInfo.index].med_url && item1[cellInfo.index].med_url !== "null" && item1[cellInfo.index].med_url !== "" && item1[cellInfo.index].med_url !== null) ? <i style={{ color: "Highlight" }} onClick={() => { handleView(item1[cellInfo.index].med_interactions, cellInfo.index, "drug", item1[cellInfo.index].med_url) }} class="fa fa-exclamation-triangle fa-lg" aria-hidden="true"></i> : ""}
      &nbsp;&nbsp;{item1[cellInfo.index].medicine_name.replace(/\//g, '\\ ')}</p>;
    // }

  };
  const renderComposition = (cellInfo) => {

    // if(item1[cellInfo.index].id!==""){
    return <p style={{textTransform:"lowercase"}}>{item1[cellInfo.index].composition}</p>;
    // }

  };
  const renderStr = (cellInfo) => {
    const rowIndex = cellInfo.index;
    let numbers = item1[cellInfo.index].strength.match(/\d+/g);
    return (numbers === null ?
      <input
      ref={el => inputRefs.current[rowIndex][0] = el}
      onKeyDown={(e) => handleKeyDown(e,0,rowIndex)}
      onFocus={() => handleFocus(0, rowIndex)}
      onBlur={handleBlur}
      className={focusedIndex === 0 && focusedRowIndex === rowIndex ? 'css-1s2u09g-control1 focused' : 'css-1s2u09g-control1'}
        type="text"
        value={item1[cellInfo.index].strength1}
        style={{ width: "90px", height: "35px" }}

        onChange={(e) => {
          setRender(!render);
          e.preventDefault();
          let data = e.target.value;
          if(!data.startsWith(' ') && !data.startsWith('-')){
          const List = item1;
          List[cellInfo.index].strength1 = data;
          console.log(data)
          setItem(List);
          }
          setRender(!render);

        }}
      />
      : <p>{item1[cellInfo.index].strength} {item1[cellInfo.index].strength_unit}</p>)

    // if(item1[cellInfo.index].id!==""){
    //   return <p>{item1[cellInfo.index].strength}</p>
    // }
  };
  
  const renderMedCOde = (cellInfo) => {

    // if(item1[cellInfo.index].id!==""){
    return <p>{item1[cellInfo.index].med_code}</p>;
    // }

  };

  // const renderStr = (cellInfo) => {
  //   const rowIndex = cellInfo.index;
  //   let numbers = item1[cellInfo.index].strength.match(/\d+/g);
  //   return (numbers === null ?
  //     <input
  //     ref={el => inputRefs.current[rowIndex][0] = el}
  //     onKeyDown={(e) => handleKeyDown(e,0,rowIndex)}
  //     onFocus={() => handleFocus(0, rowIndex)}
  //     onBlur={handleBlur}
  //     className={focusedIndex === 0 && focusedRowIndex === rowIndex ? 'css-1s2u09g-control1 focused' : 'css-1s2u09g-control1'}
  //       type="text"
  //       value={item1[cellInfo.index].strength1}
  //       style={{ width: "90px", height: "35px" }}

  //       onChange={(e) => {
  //         setRender(!render);
  //        
  //         e.preventDefault();
  //         let data = e.target.value;
  //         if(!data.startsWith(' ') && !data.startsWith('-')){
  //         const List = item1;
  //         List[cellInfo.index].strength1 = data;
  //      
  //         setItem(List);
  //         }
  //         setRender(!render);

  //       }}
  //     />
  //     : <p>{item1[cellInfo.index].strength} {item1[cellInfo.index].strength_unit}</p>)

  //   // if(item1[cellInfo.index].id!==""){
  //   //   return <p>{item1[cellInfo.index].strength}</p>
  //   // }
  // };
  const renderForm = (cellInfo) => {




    return (item1[cellInfo.index].dosage_form === " " ? <Select
      style={{ width: "100%" }}
      // value={item1[cellInfo.index].dosage_form1}
     defaultValue={item1[cellInfo.index].dosage_form1}
      onChange={(data) => {


        const List = item1;
        if (List[cellInfo.index].intake_unit) {
          List[cellInfo.index].intake_unit.label = ""
          List[cellInfo.index].intake_unit.value = ""
        }
        List[cellInfo.index].dosage_form1 = data.value;
        setItem(List);
        setRender(!render);
      }}
      options={DosageOptions}

      className=""
    /> :
      <p>{(item1[cellInfo.index].dosage_form).replace(/_/g, ' ')}</p>)

    //
  };
  const updateItem = (n, newValues) => {
    const updatedArray = [...item1]; // Copy the array
    updatedArray[n] = {
      ...updatedArray[n], // Copy the existing object
      ...newValues,       // Apply the new values
    };
    setItem([...updatedArray]);  // Update the state
  };
  const renderRoute = (cellInfo) => {
    const rowIndex = cellInfo.index;
    return (item1[cellInfo.index].route === "" ?
      <select
        ref={el => inputRefs.current[rowIndex][1] = el}
        onKeyDown={(e) => handleKeyDown(e,1,rowIndex)}
        onFocus={() => handleFocus(1, rowIndex)}
        onBlur={handleBlur}
        className={focusedIndex === 1 && focusedRowIndex === rowIndex ? 'css-1s2u09g-control1 focused' : 'css-1s2u09g-control1'}
        style={{ width: "100%" }}
        // value={item1[cellInfo.index].route1}
       value={item1[cellInfo.index].route1}
        onChange={(data) => {


          const List = item1;
          List[cellInfo.index].route1 = data.target.value;
          setItem(List);
          setRender(!render);

        }}
        // options={RouteOptions}
      >
         {RouteOptions.map((item)=>{
              return(
                <option  hidden={item.value===""} value={item.value} >{item.label}</option>
              )
            })}
        </select> :
      <p>{item1[cellInfo.index].route.replace(/_/g, ' ')}</p>

    )

  };

  const InfoPopup1 = (props) => {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
        <span >{item1[cell].medicine_name}</span>
       
        </Modal.Header>
        <Modal.Body>
          <div>
          <AddMoreinfoMed form={item1[cell].dosage_form} update={handleSend} save={addHandle1} modalClose={props.onHide}/>
           
                   
          </div>
        </Modal.Body>
      </Modal>
    );
  };
  const renderFreequency = (cellInfo) => {
    setLoad(false)
    const rowIndex = cellInfo.index;
    return (
      <select
        ref={el => inputRefs.current[rowIndex][2] = el}
        onKeyDown={(e) => handleKeyDown(e,2,rowIndex)}
        onFocus={() => handleFocus(2, rowIndex)}
        onBlur={handleBlur}
        className={focusedIndex === 2 && focusedRowIndex === rowIndex ? 'css-1s2u09g-control1 focused' : 'css-1s2u09g-control1'}
        style={{ width: "100%" }}
        value={item1[cellInfo.index].frequency}
        onChange={(data) => {
          const List = item1;
          List[cellInfo.index].frequency = data.target.value;
          setItem(List);
          setRender(!render);
        }}
        // options={freqOptions}
      >
         {freqOptions.map((item)=>{
              return(
                <option  hidden={item.value===""} value={item.value} >{item.label}</option>
              )
            })}
        </select>
    );

  };

  const renderInTake = (cellInfo) => {
    const rowIndex = cellInfo.index;
    setLoad(false)
    let option = {};
    intakeTimeOptions.forEach(item => {
      if (item.value === item1[cellInfo.index].intake_time) {
        option = item;
      }
    });
    return (
      <select
      ref={el => inputRefs.current[rowIndex][3] = el}
      onKeyDown={(e) => handleKeyDown(e,3,rowIndex)}
      onFocus={() => handleFocus(3, rowIndex)}
      onBlur={handleBlur}
      className={focusedIndex === 3 && focusedRowIndex === rowIndex ? 'css-1s2u09g-control1 focused' : 'css-1s2u09g-control1'}
      style={{width:"95%"}}
        value={item1[cellInfo.index].intake_time}
        onChange={(data) => {
          const List = item1;
          List[cellInfo.index].intake_time = data.target.value;

          setItem(List);

          setRender(!render);
        }}
        // options={intakeTimeOptions}
      >
        {intakeTimeOptions.map((item)=>{
              return(
                <option  hidden={item.value===""} value={item.value} >{item.label}</option>
              )
            })}
        </select>
    );

  };

  const handleFocus = (refIndex, rowIndex) => {
  
    
    setFocusedIndex(refIndex);
    setFocusedRowIndex(rowIndex);
  };
  
  const handleBlur = () => {
   

    setFocusedIndex(-1);
    setFocusedRowIndex(-1);
  };

 
  const renderIntake1 = (cellInfo) => {
    const rowIndex = cellInfo.index;
    setLoad(false)
    let option = {};
    intakecountoptions.forEach(item => {
      if (item.value === item1[cellInfo.index].intake) {
        option = item;
      }
    });
   
    return (
      <>
        <select
        ref={el => inputRefs.current[rowIndex][4] = el}
        onKeyDown={(e) => handleKeyDown(e,4,rowIndex)}
        onFocus={() => handleFocus(4, rowIndex)}
        onBlur={handleBlur}
        className={focusedIndex === 4 && focusedRowIndex === rowIndex ? 'css-1s2u09g-control1 focused' : 'css-1s2u09g-control1'}
        style={{width:"90%"}}
          value={item1[cellInfo.index].intake}
          onChange={(data) => {
         
            const List = item1;
            List[cellInfo.index].intake = data.target.value;
            List[cellInfo.index].duration = 0;
            setItem(List);
            setRender(!render);
          }}
          // options={intakecountoptions}
        >
          {intakecountoptions.map((item)=>{
              return(
                <option  hidden={item.value===""} value={item.value} >{item.label}</option>
              )
            })}
          </select>
      </>
    );

  };
  const renderRemarks = (cellInfo) => {
    setLoad(false)
    return (
    <div style={{display:"flex"}}>
       {(item1[cellInfo.index].route === ""&&item1[cellInfo.index].intake_time === "") ||
      (item1[cellInfo.index].route === null &&item1[cellInfo.index].intake_time === null)||
      (item1[cellInfo.index].route === undefined &&item1[cellInfo.index].intake_time === undefined)? 
       <OverlayTrigger overlay={<Tooltip style={{  }} id="tooltip-disabled">Add more info</Tooltip>}>
    <svg onClick={()=>{handleAddmore(cellInfo.index) }} style={{cursor:"pointer"}}xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 256 256"><path fill="#FF9C01" d="M128 24a104 104 0 1 0 104 104A104.11 104.11 0 0 0 128 24m-4 48a12 12 0 1 1-12 12a12 12 0 0 1 12-12m12 112a16 16 0 0 1-16-16v-40a8 8 0 0 1 0-16a16 16 0 0 1 16 16v40a8 8 0 0 1 0 16"/></svg>
    </OverlayTrigger>:
     <OverlayTrigger overlay={<Tooltip style={{  }} id="tooltip-disabled">Edit more info</Tooltip>}>
     <svg onClick={()=>{ handleViewMore(
        {form:item1[cellInfo.index].dosage_form,route:item1[cellInfo.index].route,intakeTime:item1[cellInfo.index].intake_time},
        cellInfo.index
      ) }} style={{cursor:"pointer"}}xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 256 256"><path fill="#3C689E" d="M128 24a104 104 0 1 0 104 104A104.11 104.11 0 0 0 128 24m-4 48a12 12 0 1 1-12 12a12 12 0 0 1 12-12m12 112a16 16 0 0 1-16-16v-40a8 8 0 0 1 0-16a16 16 0 0 1 16 16v40a8 8 0 0 1 0 16"/></svg>
     </OverlayTrigger>}
    &nbsp;&nbsp;&nbsp;
      {item1[cellInfo.index].remarks === "" ||
      item1[cellInfo.index].remarks === null ||
      item1[cellInfo.index].remarks === undefined ? 
      <OverlayTrigger overlay={<Tooltip style={{ textTransform: "capitalize" }} id="tooltip-disabled">Add remarks</Tooltip>}>
        <svg style={{cursor:"pointer"}} onClick={() => handleAdd(cellInfo.index)} xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 24 24"><path fill="#3C689E" d="M20.595 4.17a4.78 4.78 0 0 0-3.33-1.38H6.695a4.71 4.71 0 0 0-4.72 4.72v6.6a4.71 4.71 0 0 0 4.72 4.72h2.36l1.94 1.94c.133.14.293.253.47.33a1.4 1.4 0 0 0 1.09 0a1.5 1.5 0 0 0 .45-.31l2-2h2.33a4.73 4.73 0 0 0 3.33-1.38a4.8 4.8 0 0 0 1-1.53a4.7 4.7 0 0 0 .36-1.81v-6.6a4.7 4.7 0 0 0-1.43-3.3m-5.08 7.7h-2.55v2.53a1 1 0 1 1-2 0v-2.53h-2.53a1 1 0 1 1 0-2h2.53V7.32a1 1 0 0 1 2 0v2.55h2.55a1 1 0 1 1 0 2"/></svg>
     </OverlayTrigger>
     : 
     <OverlayTrigger overlay={<Tooltip style={{ textTransform: "capitalize" }} id="tooltip-disabled">Edit remarks</Tooltip>}>
     <svg style={{cursor:"pointer"}}  onClick={() =>
      handleView(
        item1[cellInfo.index][cellInfo.column.id],
        cellInfo.index
      )
    }xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 24 24"><path fill="#3C9E6A" d="M20.605 4.17a4.67 4.67 0 0 0-3.33-1.38H6.705a4.71 4.71 0 0 0-4.71 4.72v6.6a4.71 4.71 0 0 0 4.71 4.72h2.33l1.95 1.94c.127.143.284.255.46.33c.175.072.361.11.55.11q.286-.004.55-.11a1.6 1.6 0 0 0 .44-.31l2-2h2.33a4.7 4.7 0 0 0 3.33-1.38a4.8 4.8 0 0 0 1-1.53c.234-.575.357-1.19.36-1.81v-6.6a4.67 4.67 0 0 0-1.4-3.3m-13.24 8.17a1.66 1.66 0 1 1 1.66-1.66a1.67 1.67 0 0 1-1.66 1.66m4.63 0a1.66 1.66 0 1 1 0-3.32a1.66 1.66 0 0 1 0 3.32m4.62 0a1.66 1.66 0 1 1 1.66-1.66a1.67 1.67 0 0 1-1.66 1.66"/></svg></OverlayTrigger>
    } &nbsp;&nbsp;&nbsp;
      <OverlayTrigger overlay={<Tooltip style={{ textTransform: "capitalize" }} id="tooltip-disabled">Delete</Tooltip>}> 
     <svg style={{cursor:"pointer"}} onClick={() => {  deleteRow(cellInfo.index);}} xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 24 24"><path fill="red" d="M7 21q-.825 0-1.412-.587T5 19V6H4V4h5V3h6v1h5v2h-1v13q0 .825-.587 1.413T17 21zm2-4h2V8H9zm4 0h2V8h-2z"/></svg>
     </OverlayTrigger>
       
   
</div>
  )}
  const renderDosageCount = (cellInfo) => {
    const rowIndex = cellInfo.index;
    setLoad(false)
    let data = item1[cellInfo.index].dosage_count;
    let option = {};
    IntakeUnitOptions1.forEach(item => {
      if (item.value === item1[cellInfo.index].intake_unit) {
        option = item;
      }
    });
    return (
      <div style={{display:"flex"}}>
      <input
        ref={el => inputRefs.current[rowIndex][7] = el}
        onKeyDown={(e) => handleKeyDown(e,7,rowIndex)}
        onFocus={() => handleFocus(7, rowIndex)}
        onBlur={handleBlur}
        className={focusedIndex === 7 && focusedRowIndex === rowIndex ? 'css-1s2u09g-control1 focused' : 'css-1s2u09g-control1'}
        type="number"
        min="1"
        value={item1[cellInfo.index].dosage_count}
        style={{ width: "40px", height: "35px" }}

        onChange={(e) => {
          setRender(!render);
         
          e.preventDefault();
          let data = e.target.value;
          const List = item1;
          if(data>0&&data.length < 4){
           
            List[cellInfo.index].dosage_count = data;
           
          }
          else{
           
            List[cellInfo.index].dosage_count = "";
           
          }
          setItem(List);
          setRender(!render);
          if (data === "0"||data<1) {
            alert.error("Please Enter a Valid Quantity")


          }
          
        }}/>
          <select
      ref={el => inputRefs.current[rowIndex][8] = el}
      onKeyDown={(e) => handleKeyDown(e,8,rowIndex)}
      onFocus={() => handleFocus(8, rowIndex)}
      onBlur={handleBlur}
      className={focusedIndex === 8 && focusedRowIndex === rowIndex ? 'css-1s2u09g-control1 focused' : 'css-1s2u09g-control1'}
      style={{width:"95%"}}
        isDisabled={item1[cellInfo.index].dosage_form1 === " "}
        value={item1[cellInfo.index].intake_unit}
        onChange={(data) => {

          const List = item1;
          List[cellInfo.index].intake_unit = data.target.value;

          setItem(List);

          setRender(!render);
        }}
        // options={item1[cellInfo.index].dosage_form1 === "capsules" || item1[cellInfo.index].dosage_form1 === "chewable tablets" || item1[cellInfo.index].dosage_form1 === "suppositories" || item1[cellInfo.index].dosage_form1 === "tablets" ||
        //   item1[cellInfo.index].dosage_form.toLowerCase().includes("capsule") || item1[cellInfo.index].dosage_form.toLowerCase().includes("chewable") || item1[cellInfo.index].dosage_form.toLowerCase().includes("suppositor") || item1[cellInfo.index].dosage_form.toLowerCase().includes("tablet") ? IntakeUnitOptions7 :
        //   item1[cellInfo.index].dosage_form1 === "solutions" || item1[cellInfo.index].dosage_form1 === "emulsions" || item1[cellInfo.index].dosage_form1 === "lotions" ||
        //     item1[cellInfo.index].dosage_form.toLowerCase().includes("solution") || item1[cellInfo.index].dosage_form.toLowerCase().includes("emulsion") || item1[cellInfo.index].dosage_form.toLowerCase().includes("lotion") ? IntakeUnitOptions6 :
        //     item1[cellInfo.index].dosage_form1 === "suspensions" || item1[cellInfo.index].dosage_form1 === "creams" || item1[cellInfo.index].dosage_form1 === "ointments" ||
        //       item1[cellInfo.index].dosage_form.toLowerCase().includes("suspension") || item1[cellInfo.index].dosage_form.toLowerCase().includes("cream") || item1[cellInfo.index].dosage_form.toLowerCase().includes("ointment") ? IntakeUnitOptions4 :
        //       item1[cellInfo.index].dosage_form1 === "effervescent granules" || item1[cellInfo.index].dosage_form.toLowerCase().includes("granul") ? IntakeUnitOptions5 :
        //         item1[cellInfo.index].dosage_form1 === "aerosols" || item1[cellInfo.index].dosage_form1 === "gases" || item1[cellInfo.index].dosage_form.toLowerCase().includes("aerosol") || item1[cellInfo.index].dosage_form.toLowerCase().includes("gas") ? IntakeUnitOptions3 :
        //           item1[cellInfo.index].dosage_form1 === "injections" || item1[cellInfo.index].dosage_form.toLowerCase().includes("injection") ? IntakeUnitOptions2 : IntakeUnitOptions1

        // }
      >
           {(item1[cellInfo.index].dosage_form1 === "capsules" || item1[cellInfo.index].dosage_form1 === "chewable tablets" || item1[cellInfo.index].dosage_form1 === "suppositories" || item1[cellInfo.index].dosage_form1 === "tablets" ||
          item1[cellInfo.index].dosage_form.toLowerCase().includes("capsule") || item1[cellInfo.index].dosage_form.toLowerCase().includes("chewable") || item1[cellInfo.index].dosage_form.toLowerCase().includes("suppositor") || item1[cellInfo.index].dosage_form.toLowerCase().includes("tablet")) ? IntakeUnitOptions7.map((item)=>{
              return(
                <option hidden={item.value===""} value={item.value} >{item.label}</option>
              )
            }) : (item1[cellInfo.index].dosage_form1 === "solutions" || item1[cellInfo.index].dosage_form1 === "emulsions" || item1[cellInfo.index].dosage_form1 === "lotions" ||
            item1[cellInfo.index].dosage_form.toLowerCase().includes("solution") || item1[cellInfo.index].dosage_form.toLowerCase().includes("emulsion") || item1[cellInfo.index].dosage_form.toLowerCase().includes("lotion")) ? IntakeUnitOptions6.map((item)=>{
              return(
                <option hidden={item.value===""} value={item.value} >{item.label}</option>
              )
            }) :(item1[cellInfo.index].dosage_form1 === "suspensions" || item1[cellInfo.index].dosage_form1 === "creams" || item1[cellInfo.index].dosage_form1 === "ointments" ||
            item1[cellInfo.index].dosage_form.toLowerCase().includes("suspension") || item1[cellInfo.index].dosage_form.toLowerCase().includes("cream") || item1[cellInfo.index].dosage_form.toLowerCase().includes("ointment"))? IntakeUnitOptions4.map((item)=>{
              return(
                <option hidden={item.value===""} value={item.value} >{item.label}</option>
              )
            }):(item1[cellInfo.index].dosage_form1 === "effervescent granules" || item1[cellInfo.index].dosage_form.toLowerCase().includes("granul"))?IntakeUnitOptions5.map((item)=>{
              return(
                <option hidden={item.value===""} value={item.value} >{item.label}</option>
              )
            }):(item1[cellInfo.index].dosage_form1 === "aerosols" || item1[cellInfo.index].dosage_form1 === "gases" || item1[cellInfo.index].dosage_form.toLowerCase().includes("aerosol") || item1[cellInfo.index].dosage_form.toLowerCase().includes("gas"))?IntakeUnitOptions3.map((item)=>{
              return(
                <option hidden={item.value===""} value={item.value} >{item.label}</option>
              )
            }):(item1[cellInfo.index].dosage_form1 === "injections" || item1[cellInfo.index].dosage_form.toLowerCase().includes("injection"))?IntakeUnitOptions2.map((item)=>{
              return(
                <option hidden={item.value===""} value={item.value} >{item.label}</option>
              )
            }):IntakeUnitOptions1.map((item)=>{
              return(
                <option hidden={item.value===""} value={item.value} >{item.label}</option>
              )
            })}
        </select>
       
      </div>
    );

  };
  const renderDuration = (cellInfo) => {

    const rowIndex = cellInfo.index; 
    setLoad(false)
    let option = {};
    intakecountoptions.forEach(item => {
      if (item.value === item1[cellInfo.index].duration) {
        option = item;
      }
    });
    return (
      <>
        <form style={{ display: "flex" }}>
        <input
        ref={el => inputRefs.current[rowIndex][5] = el}
        onKeyDown={(e) => handleKeyDown(e,5,rowIndex)}
        onFocus={() => handleFocus(5, rowIndex)}
        onBlur={handleBlur}
        className={focusedIndex === 5 && focusedRowIndex === rowIndex ? 'css-1s2u09g-control1 focused' : 'css-1s2u09g-control1'}
        type="number"
        min="1"
        value={item1[cellInfo.index].durationvalue}
        style={{ width: "40px" }}

        onChange={(e) => {
          const List = item1;
          e.preventDefault();
          let data = e.target.value;
          if(Number(data)>0&&data.length < 4){
            List[cellInfo.index].durationvalue = data;
            setDrvalue(e.target.value)
          }
          else{
            setDrvalue("")
            List[cellInfo.index].durationvalue = "";
          }
          setItem(List);
         
       


        }
        }/>
          {/* <input
            type="number"
            min="1"
            className=""
            style={{ width: "40px" }}
            defaultValue={item1[cellInfo.index].durationvalue}
            onChange={(e) => {
              e.preventDefault();
              setDrvalue(e.target.value)
              let data = e.target.value;
              if (data === "0") {
                alert.error("Please Enter a Valid duration")

              }
              else if (data.length < 4 || data !== 0 || data !== "") {
                const List = item1;
                List[cellInfo.index].durationvalue = data;
                setItem(List);
              }


            }
            } */}

          {/* /> */}
          <select
          ref={el => inputRefs.current[rowIndex][6] = el}
          onKeyDown={(e) => handleKeyDown(e,6,rowIndex)}
          onFocus={() => handleFocus(6, rowIndex)}
          onBlur={handleBlur}
          className={focusedIndex === 6 && focusedRowIndex === rowIndex ? 'css-1s2u09g-control1 focused' : 'css-1s2u09g-control1'}
          disabled={item1[cellInfo.index].intake ===""}
            style={{ width: "80%" }}
            value={item1[cellInfo.index].duration}
            onChange={(data) => {
              const List = item1;

              List[cellInfo.index].duration =data.target.value ;

              setItem(List);

              setRender(!render);

            }}
            
          >
            
          
            {item1[cellInfo.index].intake === "Daily" ? durationOptions.map((item)=>{
              return(
                <option hidden={item.value===""} value={item.value} >{item.label}</option>
              )
            }) : item1[cellInfo.index].intake === "Weekly" ? durationOptions1.map((item)=>{
              return(
                <option hidden={item.value===""} value={item.value} >{item.label}</option>
              )
            }) :item1[cellInfo.index].intake ==="Monthly"? durationOptions2.map((item)=>{
              return(
                <option hidden={item.value===""} value={item.value} >{item.label}</option>
              )
            }):""}
            </select>
        </form>
      </>
    );

  };
 

  const columns = [
    {
      Header: "Drug",
      accessor: "medicine_name",
      Cell: renderMedicineType,
     

      minWidth: 100,
      sortable: false,
      filterable: false,
      width: 180,
    },
    // {
    //   Header: "ATC Code",
    //   accessor: "med_code",
    //   Cell: renderMedCOde,
     

    //   minWidth: 100,
    //   sortable: false,
    //   filterable: false,
    //   // width: 120,
    // },

    {
      Header: "Composition",
      accessor: "composition",
      Cell: renderComposition,
      style: {
        whiteSpace: "unset"
      },

      minWidth: 100,
      sortable: false,
      filterable: false,
      width: 180,
    },
    {
      Header: (
        <span
          className="table-header-tooltip"
          data-toggle="tooltip"
          data-placement="top"
          title="Strength"
        >
          Strength
        </span>
      ),
     
      width: 90,
      Cell: renderStr,
      sortable: false,
      filterable: false,
    },
   
    {
      Header: "Frequency",
      accessor: "frequency",
      minWidth: 80,
      // width: 130,
      style: {
        whiteSpace: "unset"
      },
      Cell: renderFreequency,
      sortable: false,
      filterable: false,
    },
   
    {
      Header: "Intake Period",
      accessor: "intake",
      Cell: renderIntake1,
      sortable: false,
      style: {
        whiteSpace: "unset"
      },
      // width: 150,
      filterable: false,
    },
    {
      Header: "Duration",
      accessor: "duration",
      Cell: renderDuration,
      sortable: false,
      style: {
        whiteSpace: "unset"
      },
      // width: 160,
      filterable: false,
    },


    {
      Header: (
        <span
          className="table-header-tooltip"
          data-toggle="tooltip"
          data-placement="top"
          title="INTAKE COUNT"
        >
         Quantity
        </span>
      ),
      accessor: "dosage_count",
      Cell: renderDosageCount,
      sortable: false,
      // width: 160,
      style: {
        whiteSpace: "unset"
      },
      filterable: false,
    },
  

    {
      Header: "",
      accessor: "remarks",
      Cell: renderRemarks,
      sortable: false,
      // width: 120,
      style: {
        whiteSpace: "unset"
      },
      filterable: false,
    },

   
  ];

  const displayTable = (
    <ReactTable
      columns={columns}
      data={item1}
      pageSize={n}

      showPagination={false}
      className="observation-table"
      style={{ overflow: "wrap", textAlign: "left", }}
    ></ReactTable>
  );

  return (
    <>


      <div className="" style={{ marginTop: "1%" }} >
        <div style={{ backgroundColor: "#e4dd9f", padding: "2%",   borderRadius: "10px" }}>
          <div className="row">
            <div className="col-1"></div>
            <div className="col-8">

              <div className="search-div">
                <div style={{ display: "flex", position: "relative" }}>

                  <input

                    type="text"
                    className=""
                    placeholder={search === "composition" ? "Search with medical composition" : search === "med_name" ? "Search with medicine name" : search === "generic_name" ? "Search with generic name" : "Search here"}
                    value={searchTerm}
                    onChange={handleSearchChange}
                    style={{ paddingLeft: "10px", width: "100%" }}
                  />
                  <div className="btn btn-primary btn-col search-btn">
                    {" "}
                    <i className="fas fa-search"></i>{" "}
                  </div>

                </div>
              </div>

              {display ? (
                <div className="suggest-display " >
                  {medicine.length > 0 ? (
                    medicine.map((item) => {
                      return (
                        <p
                          onClick={(e) => handleAddProcedure(e, item._source)}
                          className=" text-secondary"
                          style={{ padding: "2%" }}
                        >

                          {search === "name_suggest__completion" ?
                            <> <div className="row"> <span className="col-6">{item._source.med_name}</span><span className="col-6">{item._source.strength}</span> </div>  </> :
                            // search === "generic_name" ?
                            // <div className="row"><span className="col-6">{item._source.med_name+","+item._source.strength}</span> <span className="col-5"> {item._source.generic_name}</span> <i style={{ float: "right" }} class={item._source.is_approved === false ? "fa fa-window-close" : "fa fa-check-square"} aria-hidden="true"></i></div> :
                            <div className="row"> <span className="col-4">{item._source.med_name}</span><span className="col-3">{item._source.strength}</span><span className="col-5">{item._source.composition}</span> </div>}
                        </p>
                      );
                    })
                  ) : (
                    <h6 className="text-muted" style={{ padding: 10 }}>
                      No Medicines found !!
                    </h6>
                  )}
                </div>
              ) : null}
              <div style={{ marginTop: "0.5%", marginLeft: "1%" }} className="row">
                <div style={{ display: "flex" }}><h6 className="">Search via: <span>&nbsp;&nbsp;</span></h6>

                  <div className="radio-options">
                    <input
                      style={{ marginTop: "1%" }}
                      type="radio"
                      id="Hospital"
                      name="health"
                      value="name_suggest__completion"
                      checked={search === "name_suggest__completion"}
                      onChange={handleChange}
                    /><span>&nbsp;</span>
                    <small htmlFor="hospital">
                      Medicine Name
                    </small>

                    <span>&nbsp;&nbsp;</span>
                    <input
                      disabled
                      style={{ marginTop: "1%" }}
                      type="radio"
                      id="Hospital"
                      name="health"
                      value="generic_name"
                      checked={search === "generic_name"}
                      onChange={handleChange}
                    /><span>&nbsp;</span>
                    <small htmlFor="hospital" style={{ color: "grey" }}>
                      Generic Name
                    </small>
                  </div>
                  <span>&nbsp;&nbsp;</span>
                  <div className="radio-options">
                    <input
                      style={{ marginTop: "1%" }}
                      type="radio"
                      id="Pharmacy"
                      name="health"
                      value="composition_suggest__completion"
                      checked={search === "composition_suggest__completion"}
                      onChange={handleChange}
                    /><span>&nbsp;</span>
                    <small htmlFor="Pharmacy">Composition</small>
                  </div></div>





              </div>
            </div>

            <div className="col-1">
              <button disabled className="" onClick={() => setAddRentalShow(true)} style={{ boxShadow: "3px 5px 10px rgba(0,0,0,0.2)", height: "38px", marginTop: "3%", fontSize: "small", borderRadious: "2px", color: "white", backgroundColor: "#bdb5b5", border: "none" }}>
                Add New  <i class="fa fa-arrow-right" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="" style={{ marginTop: "1%" }}>
        <div className="text-center">
          <div className=""> {displayTable}</div>
          <br />
          {item1.length>0&&<button className="review-button" style={{padding:"1%"}} onClick={handleMedicine} disabled={load}>
            Preview
          </button>}{" "}
          &nbsp;&nbsp;&nbsp;&nbsp;
          {/* <Button variant="primary">History</Button> &nbsp;&nbsp;&nbsp;&nbsp; */}
          {/* <Button variant="success">Print</Button> */}
        </div>
      </div>
      <br />
      {/* <br />

      <br />
      <br /> */}



      {addShow ? (
        <AddPopup
          show={addShow}
          onHide={() => {
            setAddShow(false);
          }}
        />
      ) : null}
      {viewShow ? (
        <ViewPopup
          show={viewShow}
          onHide={() => {
            setViewShow(false);
          }}
          onSave={() => {
            if (sendData !== item1[cell].remarks) {
              setViewShow(false);
              // setEditSuccess(true);
              const list = item1;
              list[cell].remarks = sendData;
              setItem(list);
            }
          }}
        />
      ) : null}
 {viewShowMore ? (
        <ViewMorePopup
          show={viewShowMore}
          onHide={() => {
            setViewShowMore(false);
          }}
          onSave={() => {
            if (sendData["route"] !== item1[cell].route||sendData["intakeTime"] !==item1[cell].intake_time) {
              setViewShowMore(false);
              // setEditSuccess(true);
              const list = item1;
              list[cell].route = sendData["route"];
              list[cell].intake_time = sendData["intakeTime"];

              setItem(list);
            }
          }}
        />
      ) : null}

      {
        addRentalShow ?
          <AddRentalPopUp
            show={addRentalShow}
            onHide={() => setAddRentalShow(false)}
          /> : null
      }
 {
       review ?
          <ReviewPopUp
            show={review}
            onHide={() => setReview(false)}
          /> : null
      }

{infoShow ? (
                <InfoPopup1
                    show={infoShow}
                    onHide={() => {
                        setinfoShow(false);
                    }}
                />
            ) : (
                ""
            )}

    </>
  );
}

export default AddMedicinesPat;
