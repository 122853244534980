import React, { useState, useRef, useEffect, Fragment } from "react";
import axios from "axios";
import { APIURL } from "../../Global";
import { Link, useNavigate } from "react-router-dom";
function SideMenuWound(props) {
    const navigate = useNavigate();
    const [features, setFeatures] = useState(JSON.parse(sessionStorage.getItem("features")))
    const [imgUrl, setImgUrl] = useState();
    const [age, setAge] = useState();
    const [name, setName] = useState();
    const [gender, setGender] = useState();
    const [assignData, setAssignData] = useState([]);
    const login_datas = JSON.parse(sessionStorage.getItem("logindatas"))
    ? JSON.parse(sessionStorage.getItem("logindatas"))
    : null;
    const handleClick = (ref) => {
        ref.current.scrollIntoView({
          behavior: "smooth",
          block: "top",
        });
      };
      useEffect(() => {

        const tokenString = sessionStorage.getItem("usertoken");
        let str = tokenString.replace(/["]+/g, "");
    
        axios
          .get(`${APIURL}/api/v1/service-provider/assign-room/?patient=${props.id}`, {
            headers: {
              Authorization: "Token " + str,
            },
          })
          .then((res) => {
            if (res.status == 200) {
              setAssignData(res.data.data);
            }
          });
    
      }, []);
      const handleWound = () => {
        navigate("/wound_report", {
          state: { name: name, id: props.id, gender: gender, woundId: "", mobile_number: "1", room: assignData ? assignData.bedunit_code : "" },
        });
      };
    useEffect(() => {
        const tokenString = sessionStorage.getItem("usertoken");
    
        let str = tokenString.replace(/["]+/g, "");
         axios
          .get(`${APIURL}/api/v1/doctor/patient-dashboard/?patient_id=${props.id}`, {
            headers: {
              Authorization: "Token " + str,
            },
          })
          .then((res) => {
            if (res.data.status === "success") {
              setName(res.data.data.name);
              setAge(res.data.data.age);
              setGender(res.data.data.gender);
             
    
             
            
            
              setImgUrl(res.data.data.photo);
            }
          });
       
        },[]);
    return(<>
     <div className="" style={{ borderRight: "" ,paddingTop:"1%"}}>
         <div className="row">
         <div className="sub-section-img col-4" style={{paddingleft:"5%"}}>
       
         <img
           onClick={() => {
            navigate(`/patient-record/id/${props.id}/mob/${"1"}`, {
              state: { ref:"ref1" },
            });
          }}
                          style={{
                            borderRadius: "50%",
                            width: "40px",
                            height: "40px",
                            cursor:"pointer"
                           
                          }}
                    src={imgUrl}
                    alt="Profile Photo"
                    
                  />
                </div>
                <div style={{cursor:"pointer"}} onClick={() => {
            navigate(`/patient-record/id/${props.id}/mob/${"1"}`, {
              state: { ref:"ref1" },
            });
          }}className="col-8">
                <div className="recdiv" style={{borderRight:"none",textAlign:"left",paddingTop:"8%"}}>
                  <h6 className='text-style-here-patient-rec 'style={{color:"black"}} ><b>{name}</b></h6>
                  <h6 className='text-style-here-patient-rec'style={{color:"black"}} >{gender}, {age}</h6>
                  </div>
                </div>
         </div>
         <div className="">

<button className="patientRecbutton"
style={{width:"100%"}}
  onClick={() => {
    navigate(`/patient-record/id/${props.id}/mob/${"1"}`, {
      state: { ref:"ref1" },
    });
  }}
>
  &nbsp;
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M12 9a2 2 0 1 0 0 4a2 2 0 0 0 0-4Zm-4 8.5a3 3 0 0 1 3-3h2a3 3 0 0 1 3 3a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1Z" /><path fill="currentColor" fill-rule="evenodd" d="M7 2.25A2.75 2.75 0 0 0 4.25 5v14A2.75 2.75 0 0 0 7 21.75h10A2.75 2.75 0 0 0 19.75 19V8.198a1.75 1.75 0 0 0-.328-1.02L16.408 2.98a1.75 1.75 0 0 0-1.421-.73H7ZM5.75 5c0-.69.56-1.25 1.25-1.25h7.25v4.397c0 .414.336.75.75.75h3.25V19c0 .69-.56 1.25-1.25 1.25H7c-.69 0-1.25-.56-1.25-1.25V5Z" clip-rule="evenodd" /></svg>
  &nbsp;
  Profile{" "}

</button>



</div>
       
        
         
          
        
        </div>
       
    </>
      
    )
}
export default SideMenuWound;