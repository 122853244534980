import React, {useState} from 'react'

function EditMoreData(props) {
    const login_datas = JSON.parse(sessionStorage.getItem("logindatas"))
    ? JSON.parse(sessionStorage.getItem("logindatas"))
    : null;
console.log(props)
    const [form, setForm] = useState(props.data.form)
  const [route, setRoute] = useState(props.data.route)
  const [intakeTime, setIntakeTime] = useState(props.data.intakeTime)
 
    const handleSearchChange = (e) => {
        const val = e.target.value;
        const key = e.target.name;
    
        if (key === "intakeTime") {
          setIntakeTime(val)
        }
        if (key === "route") {
          setRoute(val)
        }
    
        if (key === "form") {
          setForm(val)
        }
    
      }
      const RouteOptions = [{ value: "", label: "" }, { value: 'oral', label: 'Oral' },
        { value: 'topical_skin', label: 'Topical skin' },
        { value: 'topical-eyes', label: 'Topical eyes' },
        { value: 'topical_ears', label: 'Topical ears' },
        { value: 'inhalation', label: 'Inhalation' },
        { value: 'injections', label: 'Injections' },
        { value: 'vaginal', label: 'Vaginal' },
        { value: 'anal', label: 'Anal' },
        { value: 'sub_lingual', label: 'Sub lingual' }
      
        ]
        const intakeTimeOptions = [
          { value: "", label: "" },
          { value: "before food", label: "Before food" },
          { value: "after food", label: "After food" },
        ];
    const handleShow =()=> {
       
            setRoute(props.infoData1.route)
            setForm(props.form)
            setIntakeTime(props.infoData1.intake_time)
           
        
    }

    let data = {route:route,intakeTime:intakeTime}
    props.update(data)
    const handleSave = ()=> {
      props.save()
  }
    return (
        <div>        
              <div style={{ marginBottom: "1%" }} className="row">
      <div style={{ backgroundColor: "rgb(13, 197, 197)", color: "white", border: "1px solid white", borderRadius: "5px", }} className="col-5"><div style={{ paddingTop: "2%", paddingBottom: "2%",textAlign: "center", }}>Dosage form</div></div>
      <div style={{ backgroundColor: "rgb(13, 197, 197)", color: "white", border: "1px solid white", borderRadius: "5px", }} className="col-4"><div style={{ paddingTop: "2%", paddingBottom: "2%", textAlign: "center", }}>Route </div></div>
      <div style={{ backgroundColor: "rgb(13, 197, 197)", color: "white", border: "1px solid white", borderRadius: "5px", }} className="col-3"><div style={{ paddingTop: "2%", paddingBottom: "2%", textAlign: "center", }}>Intake Time</div></div>
    </div>
    <div style={{ marginBottom: "1%" }} className="row">

      <div style={{ backgroundColor: "#e4dd9f", border: "1px solid white", borderRadius: "5px", }} className="col-5"><div >
        <input
          className='css-1s2u09g-control1'
          name="form"
          type="text"
style={{width:"100%"}}
          value={(form).charAt(0).toUpperCase() + (form).slice(1).toLowerCase().replace(/_/g, ' ')}
          />
          
        

      </div></div>
      <div style={{ backgroundColor: "#e4dd9f", border: "1px solid white", borderRadius: "5px", }} className="col-4"><div >
        <select

          name="route"
          type="text"
          style={{width:"100%"}}
          className='css-1s2u09g-control1'
          value={route}
          defaultInputValue={route}
          onChange={handleSearchChange}

        >
          {RouteOptions.map(item => (
            <option value={item.value}>
              {item.label}
            </option>
          ))}
        </select>
      </div></div>
      <div style={{ backgroundColor: "#e4dd9f", border: "1px solid white", borderRadius: "5px", }} className="col-3"><div >
        <select

          name="intakeTime"
          type="text"
          style={{width:"100%"}}
          className='css-1s2u09g-control1'
          value={intakeTime}
          defaultInputValue={intakeTime}
          onChange={handleSearchChange}

        >
          {intakeTimeOptions.map(item => (
            <option value={item.value}>
              {item.label}
            </option>
          ))}
        </select>
      </div></div>

    </div>

<button className="review-button"style={{ padding: "1%",width:"20%",marginTop:"1%" }} onClick={handleSave} >Update</button>
            
        </div>
    )
}

export default EditMoreData
