import { useEffect } from "react";
import Swal from "sweetalert2";
const SubmitConfirmationPopup = ({ message, onConfirm, onCancel }) => {
    const handleConfirm = () => {
        Swal.fire({
            text: message,
            icon: "warning",
            showCancelButton: false,
            confirmButtonText: "Logout",
            reverseButtons: true,
            confirmButtonColor: "#2b69a3",
            backdrop: "static",
            allowOutsideClick: false,
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.close();
                onConfirm();
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                onCancel();
            }
        });
    };

    useEffect(() => {
        handleConfirm();

        const handleBackButton = () => {
            Swal.close();
        };

        window.addEventListener("popstate", handleBackButton);

        return () => {
            window.removeEventListener("popstate", handleBackButton);
        };
    }, []);

    return null;
};

export default SubmitConfirmationPopup;
