import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const DeniedView = (props) => {

    return (
        <div>
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                keyboard={false}
                show={true}
            >
                <Modal.Body>
                    <h4 id="confirm-reject" style={{ color: "red" }}>
                        List of withdrawn patients
                    </h4>
                    <div className='queue-content1 denialview' style={{ border: 'none' }}>
                        {props.list.length > 0 && props.list.map((item, index) => (
                            <div
                                style={{ backgroundColor: item.is_inpatient ? '#e4dd9f80' : '' }}
                                className="dialysis_priorityqueue"
                            >
                                <div className="col-7 dename-content" style={{ textAlign: 'left' }}>
                                    {index + 1}. {item.applicant_details.full_name || ''}
                                </div>
                                <div className="col-4 eye-content">
                                    <div className="row eye-content-div">
                                        <div className="col-6 envelop">
                                            <span className="fa-stack fa-lg">
                                                <i className="fa fa-envelope" aria-hidden="true" style={{ fontSize: "20px" }}></i>
                                            </span>
                                        </div>
                                        <div className="col-6 eye">
                                            <i
                                                onClick={() => props.togglePopup(item)}
                                                className="fa fa-eye" style={{ fontSize: "18px" }} aria-hidden="true"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button style={{ marginRight: '10px' }} variant="secondary" onClick={() => { props.onHide(); }}>
                            {props.isOfficialAccess ? "Cancel" : "Ok"}
                        </Button>
                        {props.isOfficialAccess && <Button
                            variant="primary"
                            onClick={() => { props.updateQueue() }}                        >
                            Update queue
                        </Button>}
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default DeniedView;
