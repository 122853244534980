import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { LoginContext } from "../contexts/LoginContext";
import 'font-awesome/css/font-awesome.min.css';

import "./doctorDashboard.css";
import ConfirmationPopup from "./ConfirmationPopup";
import axios from "axios";
import { APIURL } from "../../Global";
import RenderTiles from "./RenderTiles";

function BDODashboard() {
  const [loginDatas, setLoginDatas] = useContext(LoginContext);
  const navigate = useNavigate();
  const [access, setAccess] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const storedAccess = sessionStorage.getItem("access");
    const storedLoginDatas = sessionStorage.getItem("logindatas");

    if (storedAccess) {
      const parsedAccess = JSON.parse(storedAccess);
      setAccess(parsedAccess);
    } else {
      setAccess([]);
    }

    if (storedLoginDatas) {
      setLoginDatas(JSON.parse(storedLoginDatas));
    }

    setLoading(false);
  }, []);

  const handleLogout = () => {
    callLogout();
    sessionStorage.removeItem("role");
    sessionStorage.removeItem("userid");
    sessionStorage.removeItem("usertoken");
    sessionStorage.removeItem("usertype");
    sessionStorage.removeItem("logindatas");
    sessionStorage.removeItem("access");
    console.log('UserToken' + sessionStorage.getItem("usertoken"))
    window.location.reload(false)//window reload
  };

  const callLogout = () => {
    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");

    axios
      .get(`${APIURL}/api/v1/account/logout/`, {
        headers: {
          Authorization: "Token " + str,
          "Content-Type": "application/json",
        },
      })

      .then((res) => {
        if (res.data.status === "success") {
        } else {
        }
      })
      .catch((err) => { });

    navigate("/login");
  };

  useEffect(() => {
    console.log(access)
  }, [access])

  return (
    <div>
      <div className="home">
        {loading ? (
          <div className="spinner"></div>
        ) : (!loading && access.length === 0) ? <ConfirmationPopup
          message="The user have no access for the features. Logout to continue!"
          onConfirm={handleLogout} /> :
          (
            <>
              <h2 className="title-of-page">{loginDatas?.name}</h2>
              <RenderTiles access={access} />
            </>
          )}
      </div>
    </div>
  );
}

export default BDODashboard;
