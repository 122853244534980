import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import "./pagination.css";
import './userlist.css';
import axios from "axios";
import { useAlert } from "react-alert";
import { APIURL } from '../../Global';
import { Modal, Tooltip, OverlayTrigger } from 'react-bootstrap';
import AddDoc from './UserListAddDoc';
import ZoomableImage from '../BDO/Admission/ZoomableImage';
import "simplebar-react/dist/simplebar.min.css";
import bed from "../../assets/images/icons/bed.png";
import ward from "../../assets/images/icons/ward.png";
import AssignPatient from './AssignPatient';
import { CgCloseR } from "react-icons/cg";
let lastPageCalled = 1
let lastPageCalled1 = 1
function UserListDr() {
  const [selected, setSelected] = useState('shelterhome');
  const [search, setSearch] = useState('');
  const [patientList, setPatientList] = useState([]);
  const [hasNext, setHasNext] = useState(false);
  const [page, setPage] = useState(1);
  const [hasNext1, setHasNext1] = useState(true);
  const [page1, setPage1] = useState(1);
  const [isAnimating, setIsAnimating] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState('');
  const navigate = useNavigate();
  const [assignID, setAssignID] = useState("");
  const [reassign, setReassign] = useState(false);
  const [show1, setShow1] = useState(false);
  const [reports, setReports] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [show, setShow] = useState(false);
  const [addShow, setAddShow] = useState(false);
  const [showDoc, setShowDoc] = useState(false)
  const [info, setInfo] = useState('');
  const [shelter, setShelter] = useState([])
  const [dialysis, setDialysis] = useState([])
  const [physio, setPhysio] = useState([])
  const alert = useAlert()
  const [general, setGeneral] = useState([])
  let lastScrollTop1 = 0;
  let lastScrollLeft1 = 0;
  let lastScrollTop = 0;
  let lastScrollLeft = 0;
  const login_datas = JSON.parse(sessionStorage.getItem("logindatas"))
    ? JSON.parse(sessionStorage.getItem("logindatas"))
    : null;

  useEffect(() => {
    setPatientList([]);
    setSelectedPatient('');
    lastPageCalled = 1
    lastPageCalled1 = 1
    setPage(1)
    setPage1(1)
    setReports([])
    setShowDoc(false);
    if (selected && search === '') {
      handleLoad(1);
    }
  }, [selected]);

  useEffect(() => {
    if (selectedPatient) {
      GetReport(1, selectedPatient);
    }
  }, [refresh])

  const handleScroll = (event) => {
    const scrollContainer = event.target;
    const { scrollTop, scrollLeft, clientHeight, scrollHeight, clientWidth, scrollWidth } = scrollContainer;

    const isVerticalScroll = Math.abs(scrollTop - lastScrollTop) > Math.abs(scrollLeft - lastScrollLeft);
    lastScrollTop = scrollTop;
    lastScrollLeft = scrollLeft;

    const isBottom = scrollTop + clientHeight >= scrollHeight - 10;
    console.log(scrollTop, clientHeight, scrollHeight)

    if (isBottom && hasNext && isVerticalScroll && (page !== lastPageCalled)) {
      handleLoad(page);
      lastPageCalled = page;
    }
  };


  useEffect(() => {
    const scrollContainer = document.getElementById('patients');
    if (scrollContainer && search === '') {
      scrollContainer.addEventListener('scroll', handleScroll);

      return () => {
        scrollContainer.removeEventListener('scroll', handleScroll);
      };
    }
  }, [page, hasNext]);

  const handleScroll1 = (event) => {
    const scrollContainer = event.target;
    const { scrollTop, scrollLeft, clientHeight, scrollHeight, clientWidth, scrollWidth } = scrollContainer;

    const isVerticalScroll = Math.abs(scrollTop - lastScrollTop1) > Math.abs(scrollLeft - lastScrollLeft1);
    lastScrollTop1 = scrollTop;
    lastScrollLeft1 = scrollLeft;

    const isBottom = scrollTop + clientHeight >= scrollHeight - 10;
    console.log(scrollTop, clientHeight, scrollHeight)

    if (isBottom && hasNext1 && isVerticalScroll && (page1 !== lastPageCalled1)) {
      GetReport(page1, selectedPatient);
      lastPageCalled1 = page1;
    }
  };


  useEffect(() => {
    const scrollContainer = document.getElementById('docScrollable');
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', handleScroll1);

      return () => {
        scrollContainer.removeEventListener('scroll', handleScroll1);
      };
    }
  }, [page1, hasNext1]);

  const handleLoad = async (currentPage) => {
    const tokenString = sessionStorage.getItem('usertoken');
    let str = tokenString.replace(/["]+/g, '')
    const type = selected === 'shelterhome' ? 'shelter_home' : selected === 'dialysis' ? 'dialysis' : selected === 'physiotherapy' ? 'physio' : selected === 'general' ? 'general' : 'palliative'
    axios.get(`${APIURL}/api/v1/patient/patient-record/?record_type=${type}&per_page=10&page=${currentPage}`, {
      headers: {
        "Authorization": 'Token ' + str
      }
    })
      .then(res => {
        if (res.data.status === "success") {
          let Data = []
          Data = res.data.patients
          if (currentPage === 1) {
            console.log('1')
            setPatientList(Data);
          } else {
            console.log('!=1')
            setPatientList([...patientList, ...Data]);
          }
          setHasNext(res.data.has_next)
          setPage(currentPage + 1)
        } else {
          // Handle error case
        }
      })
      .catch(err => {
        // Handle error case
      });
  };

  const handlePatientSearch = (e) => {
    const val = e.target.value;
    setSearch(val);
    if (val !== '') {
      const tokenString = sessionStorage.getItem('usertoken');
      let str = tokenString.replace(/["]+/g, '')
      const value = val || search
      let url = `${APIURL}/api/v1/patient/patient-record/?search=${value}&per_page=30`
      axios
        .get(url, {
          headers: {
            "Authorization": 'Token ' + str
          }
        })
        .then((res) => {
          if (res.data.status === "success") {
            const data = res.data.patients;

            const filteredData1 = data.filter(patient => { return patient.pat_type === 'shelter' });
            const filteredData2 = data.filter(patient => { return patient.pat_type === 'dialysis' });
            const filteredData3 = data.filter(patient => { return patient.pat_type === 'physiotherapy' });
            const filteredData4 = data.filter(patient => { return patient.pat_type === 'general' });

            setShelter(filteredData1)
            setDialysis(filteredData2)
            setPhysio(filteredData3)
            setGeneral(filteredData4)
            if (filteredData1.length > 0) {
              setSelected('shelterhome')
            } else if (filteredData2.length > 0) {
              setSelected('dialysis')
            } else if (filteredData3.length > 0) {
              setSelected('physiotherapy')
            } else if (filteredData4.length > 0) {
              setSelected('general')
            } else {
              setSelected('shelterhome')
            }
          }
        }).catch((error) => {
          // Handle error case
        });
    } else {
      setPhysio([])
      setDialysis([])
      setShelter([])
      setGeneral([])
      handleLoad(1)
    }
  };

  const handleDocView = (value) => {
    lastPageCalled1 = 1
    setPage1(1)
    setReports([])
    setSelectedPatient(value);
    setShowDoc(true);
    setRefresh(!refresh)
  }

  const ViewPopup = (props) => {
    return (
      <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}

      >
        <Modal.Header style={{ borderBottom: "none", height: "30px", marginTop: "2%", }}>
          <button style={{ backgroundColor: "white", marginLeft: "95%" }} variant="secondary" onClick={props.onHide}>
            <i class="fa fa-times-circle fa-2x" style={{ color: "#F0A639" }} aria-hidden="true"></i>
          </button>
        </Modal.Header>
        <Modal.Body style={{ justifyContent: "left", minHeight: "400px" }}>
          <AssignPatient from="admission" reassign={reassign} id={assignID} onSuccess={(data) => { setShow1(false); setPatientList([]); handleLoad(1) }} />
        </Modal.Body>
      </Modal>
    );
  };

  const GetReport = async (currentPage, patient) => {
    if (patient.id !== "") {
      const tokenString = sessionStorage.getItem("usertoken");

      let str = tokenString.replace(/["]+/g, "");
      const id = patient.id
      await axios
        .get(
          `${APIURL}/api/v1/doctor/patient-documents-view/?patient_id=${id}&per_page=10&page=${currentPage}`,
          {
            headers: {
              Authorization: "Token " + str,
            },
          }
        )
        .then((res) => {
          if (res.data.status === "success") {
            let Data = []
            Data = res.data.results
            setReports([...reports, ...Data]);
            setHasNext1(res.data.has_next)
            setPage1(currentPage + 1)
          } else {
          }
        });
    }
  };

  const deleteItem = (id1) => {
    const tokenString = sessionStorage.getItem('usertoken');
    let str = tokenString.replace(/["]+/g, '')

    axios.put(`${APIURL}/api/v1/doctor/patient-documents-view/?id=${id1}&patient_id=${selectedPatient.id} `, { is_deleted: true }, {
      headers: {
        'Content-Type': 'application/json',
        Accept: "application/json",
        Authorization: 'Token ' + str
      }
    })
      .then(res => {
        if (res.data.status === "success") {
          alert.success("Deleted successfully")
          setPage1(1)
          setReports([])
          setRefresh(!refresh)
        } else {
        }
      })
      .catch(err => {
      })
  }

  const InfoPopup = (props) => {
    return (
      <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header style={{ borderBottom: "none", height: "30px", marginTop: "2%", }}>
          <button style={{ backgroundColor: "white", marginLeft: "95%" }} variant="secondary" onClick={props.onHide}>
            <i class="fa fa-times-circle fa-2x" style={{ color: "#F0A639" }} aria-hidden="true"></i>
          </button>
        </Modal.Header>
        <Modal.Body style={{ paddingRight: "7%", textAlign: "center" }}>
          <ZoomableImage src={info} />
        </Modal.Body>
      </Modal>
    );
  };

  const AddPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        style={{ minHeight: "450px" }}
      >

        <Modal.Body style={{ width: "95%", minHeight: "450px", paddingRight: "5%" }}>
          <h6 style={{ marginLeft: "2%", textTransform: "capitalize" }}><b>{selectedPatient.full_name}</b>
            <button style={{ backgroundColor: "white", float: "right" }} variant="secondary" onClick={props.onHide}>
              <i class="fa fa-times fa-lg" style={{ color: "black" }} aria-hidden="true"></i>
            </button></h6>
          <AddDoc id={selectedPatient.id} onSuccess={onSuccess} onClose={props.onHide} />
        </Modal.Body>

      </Modal>
    )
  }


  const onSuccess = () => {
    setReports([])
    setPage1(1)
    setAddShow(false)
    setRefresh(!refresh)
  };

  const handleToggle = () => {
    setIsAnimating(true);
    setTimeout(() => {
      setShowDoc(false);
      setIsAnimating(false);
    }, 500);
  };

  return (
    <>
      <div className='row' style={{ padding: '0px 20px' }}>
        <div className='col-lg-2 col-md-2'>
          <label className='contents-head' style={{ margin: 0, fontSize: '22px' }}>Patient Directory</label>
          <div className="side-tabs" >
            <div className='sidebox-search-input-container' style={{ marginBottom: '10px' }}>
              <input
                className='sidebox-search-input'
                type='text'
                value={search}
                placeholder='Search Patient...'
                onChange={(e) => handlePatientSearch(e)} />
              <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.4374 15.7502H16.5487L16.2337 15.4464C16.9367 14.6296 17.4506 13.6675 17.7384 12.629C18.0262 11.5904 18.081 10.5011 17.8987 9.43891C17.3699 6.31141 14.7599 3.8139 11.6099 3.4314C10.5025 3.2913 9.37767 3.4064 8.32156 3.76788C7.26546 4.12936 6.30604 4.72765 5.51672 5.51697C4.72741 6.30628 4.12912 7.2657 3.76764 8.32181C3.40615 9.37792 3.29106 10.5027 3.43116 11.6102C3.81366 14.7602 6.31116 17.3702 9.43866 17.8989C10.5008 18.0812 11.5902 18.0265 12.6287 17.7386C13.6673 17.4508 14.6294 16.937 15.4462 16.2339L15.7499 16.5489V17.4377L20.5312 22.2189C20.9924 22.6802 21.7462 22.6802 22.2074 22.2189C22.6687 21.7577 22.6687 21.0039 22.2074 20.5427L17.4374 15.7502ZM10.6874 15.7502C7.88616 15.7502 5.62491 13.4889 5.62491 10.6877C5.62491 7.8864 7.88616 5.62515 10.6874 5.62515C13.4887 5.62515 15.7499 7.8864 15.7499 10.6877C15.7499 13.4889 13.4887 15.7502 10.6874 15.7502Z" fill="#5D5D5D" />
              </svg>
            </div>
            <button
              className={selected === "shelterhome" ? "selected-button" : "side-button"}
              onClick={e => { setSelected("shelterhome"); }}
            >
              Shelter Home
            </button>
            <button
              className={selected === "dialysis" ? "selected-button" : "side-button"}
              onClick={e => { setSelected("dialysis"); }}
            >
              Dialysis
            </button>
            <button
              className={selected === "physiotherapy" ? "selected-button" : "side-button"}
              onClick={e => { setSelected("physiotherapy"); }}
            >
              Physiotherapy
            </button>
            <button
              className={selected === "palliative" ? "selected-button" : "side-button"}
              onClick={e => { setSelected("palliative"); handlePatientSearch(search) }} disabled style={{ backgroundColor: '#8080809e' }}
            >
              Palliative
            </button>
            <button
              className={selected === "general" ? "selected-button" : "side-button"}
              onClick={e => { setSelected("general"); }}
            >
              General
            </button>
          </div>
        </div>
        <div id="patients" className={`${showDoc ? 'col-6 shrink-in' : 'col-10 shrink-out'}`} style={{ overflowY: "auto", overflowX: "hidden", maxHeight: '500px', marginTop: '32px' }}>
          <div className="">
            {(search === '' && patientList.length > 0) ?
              patientList.map((value, index) => {
                return (
                  <div className='list-item-new' style={{}}>
                    <div className='row' style={{ paddingTop: "1%", paddingBottom: "1%" }}>
                      <div className="col-1" style={{ alignContent: 'center', padding: 0 }}> <OverlayTrigger overlay={<Tooltip style={{ textTransform: "capitalize" }} id="tooltip-disabled">View Profile</Tooltip>}>
                        <button className="dir-button" style={{ backgroundColor: "white", width: "100%", overflow: 'hidden', textOverflow: 'ellipsis' }} onClick={() => navigate(`/patient-record/id/${value.id}/mob/${value.mobile_number}`, {
                          state: {},
                        })}>
                          {value.photo && <img src={value.photo} alt="Profile Photo" style={{
                            maxWidth: "none",
                            borderRadius: "50%",
                            width: showDoc ? "44px" : "50px",
                            height: showDoc ? "44px" : "50px",
                          }} />}</button></OverlayTrigger>
                      </div>
                      <div className='col-11' style={{ display: 'flex', flexDirection: showDoc ? 'column' : 'row' }}>
                        <div className={`${!showDoc && 'col-4'}`} style={{ alignContent: 'center' }}>
                          <div className={`col-${showDoc ? '12' : ''}`} style={{ alignContent: 'center' }}>
                            <button className="dir-button1" style={{ padding: 0, backgroundColor: "white", width: "100%" }} onClick={() => navigate(`/patient-record/id/${value.id}/mob/${value.mobile_number}`, {
                              state: {},
                            })}>
                              <h6 style={{ letterSpacing: "0px", fontSize: "medium", textTransform: "capitalize", lineHeight: "15px", color: "#4b9cdf" }} className="each-list-link2" >&nbsp;{value.full_name}</h6>
                            </button>
                          </div>
                        </div>
                        <div className={`${showDoc ? 'row' : 'col-8'}`} style={{ display: 'flex', flexDirection: 'row' }}>
                          <div className='col-2' style={{ display: "flex", alignItems: "center", alignContent: 'center', }} > <p className='each-list-link2' style={{ fontSize: "medium" }}><i className="fa fa-address-card fa-lg" aria-hidden="true" style={{ fontSize: "1rem" }}></i>&nbsp; {value.id} </p></div>
                          <div className='col-4' style={{ display: "flex", alignItems: "center", alignContent: 'center', }}> <p className='each-list-link2' style={{ fontSize: "medium" }}> <i className="fa fa-phone fa-lg" aria-hidden="true" style={{ fontSize: "1rem" }}></i>&nbsp; {value.mobile_number} </p>
                          </div>
                          <div className='col-4' style={{ display: "flex", alignItems: "center", alignContent: 'center', flexWrap: 'wrap' }}>
                            <p onClick={() => {
                              if (login_datas.user_type === 'hospital_bdo') {
                                setAssignID(value.id)
                                if (value.bedunit !== null) { setReassign(true) }
                                else { setReassign(false) }
                                setShow1(true)
                              }
                            }} className='each-list-link2' style={{ alignContent: 'center', fontSize: "medium", color: "#4b9cdf", cursor: login_datas.user_type === 'hospital_bdo' ? "pointer" : "default" }}>
                              {value.bedunit && value.bedunit !== null ? <img
                                src={
                                  value && value.bedunit && value.bedunit.bedspace_type === "ward"
                                    ? ward
                                    : value && value.bedunit && value.bedunit.bedspace_type === "room"
                                      ? bed
                                      : ""
                                }
                                alt="Ward"
                                style={{ width: "25%", backgroundColor: "white", marginTop: "-5px", padding: "1%" }}
                              /> : <>&nbsp;&nbsp;<svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 14 14"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M7 4v6M4 7h6m.5-6.5h-7a3 3 0 0 0-3 3v7a3 3 0 0 0 3 3h7a3 3 0 0 0 3-3v-7a3 3 0 0 0-3-3" /></svg>&nbsp; Add</>}&nbsp; {value && value.bedunit && value.bedunit !== null && value.bedunit.bedunit_code}
                            </p>
                          </div>
                          <div className='col-2' style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
                            <p onClick={() => { handleDocView(value) }} disabled={value.id === selectedPatient.id} style={{ alignContent: 'center', color: "#4b9cdf", height: "100%" }}>
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-width="1.5"><path d="M5 8c0-2.828 0-4.243.879-5.121C6.757 2 8.172 2 11 2h2c2.828 0 4.243 0 5.121.879C19 3.757 19 5.172 19 8v8c0 2.828 0 4.243-.879 5.121C17.243 22 15.828 22 13 22h-2c-2.828 0-4.243 0-5.121-.879C5 20.243 5 18.828 5 16V8Zm0-3.924c-.975.096-1.631.313-2.121.803C2 5.757 2 7.172 2 10v4c0 2.828 0 4.243.879 5.121c.49.49 1.146.707 2.121.803M19 4.076c.975.096 1.631.313 2.121.803C22 5.757 22 7.172 22 10v4c0 2.828 0 4.243-.879 5.121c-.49.49-1.146.707-2.121.803" /><path stroke-linecap="round" d="M9 13h6M9 9h6m-6 8h3" /></g></svg>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })
              : (search !== '' && shelter.length > 0 && selected === 'shelterhome') ? shelter.map((value, index) => {
                return (
                  <div className='list-item-new' style={{}}>
                    <div className='row' style={{ paddingTop: "1%", paddingBottom: "1%" }}>
                      <div className="col-1" style={{ alignContent: 'center', padding: 0 }}> <OverlayTrigger overlay={<Tooltip style={{ textTransform: "capitalize" }} id="tooltip-disabled">View Profile</Tooltip>}>
                        <button className="dir-button" style={{ backgroundColor: "white", width: "100%", overflow: 'hidden', textOverflow: 'ellipsis' }} onClick={() => navigate(`/patient-record/id/${value.id}/mob/${value.mobile_number}`, {
                          state: {},
                        })}>
                          {value.photo && <img src={value.photo} alt="Profile Photo" style={{
                            maxWidth: "none",
                            borderRadius: "50%",
                            width: showDoc ? "44px" : "50px",
                            height: showDoc ? "44px" : "50px",
                          }} />}</button></OverlayTrigger>
                      </div>
                      <div className='col-11' style={{ display: 'flex', flexDirection: showDoc ? 'column' : 'row' }}>
                        <div className={`${!showDoc && 'col-4'}`} style={{ alignContent: 'center' }}>
                          <div className={`col-${showDoc ? '12' : ''}`} style={{ alignContent: 'center' }}>
                            <button className="dir-button1" style={{ padding: 0, backgroundColor: "white", width: "100%" }} onClick={() => navigate(`/patient-record/id/${value.id}/mob/${value.mobile_number}`, {
                              state: {},
                            })}>
                              <h6 style={{ letterSpacing: "0px", fontSize: "medium", textTransform: "capitalize", lineHeight: "15px", color: "#4b9cdf" }} className="each-list-link2" >&nbsp;{value.full_name}</h6>
                            </button>
                          </div>
                        </div>
                        <div className={`${showDoc ? 'row' : 'col-8'}`} style={{ display: 'flex', flexDirection: 'row' }}>
                          <div className='col-2' style={{ display: "flex", alignItems: "center", alignContent: 'center', }} > <p className='each-list-link2' style={{ fontSize: "medium" }}><i className="fa fa-address-card fa-lg" aria-hidden="true" style={{ fontSize: "1rem" }}></i>&nbsp; {value.id} </p></div>
                          <div className='col-4' style={{ display: "flex", alignItems: "center", alignContent: 'center', }}> <p className='each-list-link2' style={{ fontSize: "medium" }}> <i className="fa fa-phone fa-lg" aria-hidden="true" style={{ fontSize: "1rem" }}></i>&nbsp; {value.mobile_number} </p>
                          </div>
                          <div className='col-4' style={{ display: "flex", alignItems: "center", alignContent: 'center', flexWrap: 'wrap' }}>
                            <p onClick={() => {
                              if (login_datas.user_type === 'hospital_bdo') {
                                setAssignID(value.id)
                                if (value.bedunit !== null) { setReassign(true) }
                                else { setReassign(false) }
                                setShow1(true)
                              }
                            }} className='each-list-link2' style={{ alignContent: 'center', fontSize: "medium", color: "#4b9cdf", cursor: login_datas.user_type === 'hospital_bdo' ? "pointer" : "default" }}>
                              {value.bedunit && value.bedunit !== null ? <img
                                src={
                                  value && value.bedunit && value.bedunit.bedspace_type === "ward"
                                    ? ward
                                    : value && value.bedunit && value.bedunit.bedspace_type === "room"
                                      ? bed
                                      : ""
                                }
                                alt="Ward"
                                style={{ width: "25%", backgroundColor: "white", marginTop: "-5px", padding: "1%" }}
                              /> : <>&nbsp;&nbsp;<svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 14 14"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M7 4v6M4 7h6m.5-6.5h-7a3 3 0 0 0-3 3v7a3 3 0 0 0 3 3h7a3 3 0 0 0 3-3v-7a3 3 0 0 0-3-3" /></svg>&nbsp; Add</>}&nbsp; {value && value.bedunit && value.bedunit !== null && value.bedunit.bedunit_code}
                            </p>
                          </div>
                          <div className='col-2' style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
                            <p onClick={() => { handleDocView(value) }} disabled={value.id === selectedPatient.id} style={{ alignContent: 'center', color: "#4b9cdf", height: "100%" }}>
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-width="1.5"><path d="M5 8c0-2.828 0-4.243.879-5.121C6.757 2 8.172 2 11 2h2c2.828 0 4.243 0 5.121.879C19 3.757 19 5.172 19 8v8c0 2.828 0 4.243-.879 5.121C17.243 22 15.828 22 13 22h-2c-2.828 0-4.243 0-5.121-.879C5 20.243 5 18.828 5 16V8Zm0-3.924c-.975.096-1.631.313-2.121.803C2 5.757 2 7.172 2 10v4c0 2.828 0 4.243.879 5.121c.49.49 1.146.707 2.121.803M19 4.076c.975.096 1.631.313 2.121.803C22 5.757 22 7.172 22 10v4c0 2.828 0 4.243-.879 5.121c-.49.49-1.146.707-2.121.803" /><path stroke-linecap="round" d="M9 13h6M9 9h6m-6 8h3" /></g></svg>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })
                : (search !== '' && dialysis.length > 0 && selected === 'dialysis') ? dialysis.map((value, index) => {
                  return (
                    <div className='list-item-new' style={{}}>
                      <div className='row' style={{ paddingTop: "1%", paddingBottom: "1%" }}>
                        <div className="col-1" style={{ alignContent: 'center', padding: 0 }}> <OverlayTrigger overlay={<Tooltip style={{ textTransform: "capitalize" }} id="tooltip-disabled">View Profile</Tooltip>}>
                          <button className="dir-button" style={{ backgroundColor: "white", width: "100%", overflow: 'hidden', textOverflow: 'ellipsis' }} onClick={() => navigate(`/patient-record/id/${value.id}/mob/${value.mobile_number}`, {
                            state: {},
                          })}>
                            {value.photo && <img src={value.photo} alt="Profile Photo" style={{
                              maxWidth: "none",
                              borderRadius: "50%",
                              width: showDoc ? "44px" : "50px",
                              height: showDoc ? "44px" : "50px",
                            }} />}</button></OverlayTrigger>
                        </div>
                        <div className='col-11' style={{ display: 'flex', flexDirection: showDoc ? 'column' : 'row' }}>
                          <div className={`${!showDoc && 'col-4'}`} style={{ alignContent: 'center' }}>
                            <div className={`col-${showDoc ? '12' : ''}`} style={{ alignContent: 'center' }}>
                              <button className="dir-button1" style={{ padding: 0, backgroundColor: "white", width: "100%" }} onClick={() => navigate(`/patient-record/id/${value.id}/mob/${value.mobile_number}`, {
                                state: {},
                              })}>
                                <h6 style={{ letterSpacing: "0px", fontSize: "medium", textTransform: "capitalize", lineHeight: "15px", color: "#4b9cdf" }} className="each-list-link2" >&nbsp;{value.full_name}</h6>
                              </button>
                            </div>
                          </div>
                          <div className={`${showDoc ? 'row' : 'col-8'}`} style={{ display: 'flex', flexDirection: 'row' }}>
                            <div className='col-2' style={{ display: "flex", alignItems: "center", alignContent: 'center', }} > <p className='each-list-link2' style={{ fontSize: "medium" }}><i className="fa fa-address-card fa-lg" aria-hidden="true" style={{ fontSize: "1rem" }}></i>&nbsp; {value.id} </p></div>
                            <div className='col-4' style={{ display: "flex", alignItems: "center", alignContent: 'center', }}> <p className='each-list-link2' style={{ fontSize: "medium" }}> <i className="fa fa-phone fa-lg" aria-hidden="true" style={{ fontSize: "1rem" }}></i>&nbsp; {value.mobile_number} </p>
                            </div>
                            <div className='col-4' style={{ display: "flex", alignItems: "center", alignContent: 'center', flexWrap: 'wrap' }}>
                              <p onClick={() => {
                                if (login_datas.user_type === 'hospital_bdo') {
                                  setAssignID(value.id)
                                  if (value.bedunit !== null) { setReassign(true) }
                                  else { setReassign(false) }
                                  setShow1(true)
                                }
                              }} className='each-list-link2' style={{ alignContent: 'center', fontSize: "medium", color: "#4b9cdf", cursor: login_datas.user_type === 'hospital_bdo' ? "pointer" : "default" }}>
                                {value.bedunit && value.bedunit !== null ? <img
                                  src={
                                    value && value.bedunit && value.bedunit.bedspace_type === "ward"
                                      ? ward
                                      : value && value.bedunit && value.bedunit.bedspace_type === "room"
                                        ? bed
                                        : ""
                                  }
                                  alt="Ward"
                                  style={{ width: "25%", backgroundColor: "white", marginTop: "-5px", padding: "1%" }}
                                /> : <>&nbsp;&nbsp;<svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 14 14"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M7 4v6M4 7h6m.5-6.5h-7a3 3 0 0 0-3 3v7a3 3 0 0 0 3 3h7a3 3 0 0 0 3-3v-7a3 3 0 0 0-3-3" /></svg>&nbsp; Add</>}&nbsp; {value && value.bedunit && value.bedunit !== null && value.bedunit.bedunit_code}
                              </p>
                            </div>
                            <div className='col-2' style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
                              <p onClick={() => { handleDocView(value) }} disabled={value.id === selectedPatient.id} style={{ alignContent: 'center', color: "#4b9cdf", height: "100%" }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-width="1.5"><path d="M5 8c0-2.828 0-4.243.879-5.121C6.757 2 8.172 2 11 2h2c2.828 0 4.243 0 5.121.879C19 3.757 19 5.172 19 8v8c0 2.828 0 4.243-.879 5.121C17.243 22 15.828 22 13 22h-2c-2.828 0-4.243 0-5.121-.879C5 20.243 5 18.828 5 16V8Zm0-3.924c-.975.096-1.631.313-2.121.803C2 5.757 2 7.172 2 10v4c0 2.828 0 4.243.879 5.121c.49.49 1.146.707 2.121.803M19 4.076c.975.096 1.631.313 2.121.803C22 5.757 22 7.172 22 10v4c0 2.828 0 4.243-.879 5.121c-.49.49-1.146.707-2.121.803" /><path stroke-linecap="round" d="M9 13h6M9 9h6m-6 8h3" /></g></svg>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })
                  : (search !== '' && physio.length > 0 && selected === 'physiotherapy') ? physio.map((value, index) => {
                    return (
                      <div className='list-item-new' style={{}}>
                        <div className='row' style={{ paddingTop: "1%", paddingBottom: "1%" }}>
                          <div className="col-1" style={{ alignContent: 'center', padding: 0 }}> <OverlayTrigger overlay={<Tooltip style={{ textTransform: "capitalize" }} id="tooltip-disabled">View Profile</Tooltip>}>
                            <button className="dir-button" style={{ backgroundColor: "white", width: "100%", overflow: 'hidden', textOverflow: 'ellipsis' }} onClick={() => navigate(`/patient-record/id/${value.id}/mob/${value.mobile_number}`, {
                              state: {},
                            })}>
                              {value.photo && <img src={value.photo} alt="Profile Photo" style={{
                                maxWidth: "none",
                                borderRadius: "50%",
                                width: showDoc ? "44px" : "50px",
                                height: showDoc ? "44px" : "50px",
                              }} />}</button></OverlayTrigger>
                          </div>
                          <div className='col-11' style={{ display: 'flex', flexDirection: showDoc ? 'column' : 'row' }}>
                            <div className={`${!showDoc && 'col-4'}`} style={{ alignContent: 'center' }}>
                              <div className={`col-${showDoc ? '12' : ''}`} style={{ alignContent: 'center' }}>
                                <button className="dir-button1" style={{ padding: 0, backgroundColor: "white", width: "100%" }} onClick={() => navigate(`/patient-record/id/${value.id}/mob/${value.mobile_number}`, {
                                  state: {},
                                })}>
                                  <h6 style={{ letterSpacing: "0px", fontSize: "medium", textTransform: "capitalize", lineHeight: "15px", color: "#4b9cdf" }} className="each-list-link2" >&nbsp;{value.full_name}</h6>
                                </button>
                              </div>
                            </div>
                            <div className={`${showDoc ? 'row' : 'col-8'}`} style={{ display: 'flex', flexDirection: 'row' }}>
                              <div className='col-2' style={{ display: "flex", alignItems: "center", alignContent: 'center', }} > <p className='each-list-link2' style={{ fontSize: "medium" }}><i className="fa fa-address-card fa-lg" aria-hidden="true" style={{ fontSize: "1rem" }}></i>&nbsp; {value.id} </p></div>
                              <div className='col-4' style={{ display: "flex", alignItems: "center", alignContent: 'center', }}> <p className='each-list-link2' style={{ fontSize: "medium" }}> <i className="fa fa-phone fa-lg" aria-hidden="true" style={{ fontSize: "1rem" }}></i>&nbsp; {value.mobile_number} </p>
                              </div>
                              <div className='col-4' style={{ display: "flex", alignItems: "center", alignContent: 'center', flexWrap: 'wrap' }}>
                                <p onClick={() => {
                                  if (login_datas.user_type === 'hospital_bdo') {
                                    setAssignID(value.id)
                                    if (value.bedunit !== null) { setReassign(true) }
                                    else { setReassign(false) }
                                    setShow1(true)
                                  }
                                }} className='each-list-link2' style={{ alignContent: 'center', fontSize: "medium", color: "#4b9cdf", cursor: login_datas.user_type === 'hospital_bdo' ? "pointer" : "default" }}>
                                  {value.bedunit && value.bedunit !== null ? <img
                                    src={
                                      value && value.bedunit && value.bedunit.bedspace_type === "ward"
                                        ? ward
                                        : value && value.bedunit && value.bedunit.bedspace_type === "room"
                                          ? bed
                                          : ""
                                    }
                                    alt="Ward"
                                    style={{ width: "25%", backgroundColor: "white", marginTop: "-5px", padding: "1%" }}
                                  /> : <>&nbsp;&nbsp;<svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 14 14"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M7 4v6M4 7h6m.5-6.5h-7a3 3 0 0 0-3 3v7a3 3 0 0 0 3 3h7a3 3 0 0 0 3-3v-7a3 3 0 0 0-3-3" /></svg>&nbsp; Add</>}&nbsp; {value && value.bedunit && value.bedunit !== null && value.bedunit.bedunit_code}
                                </p>
                              </div>
                              <div className='col-2' style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
                                <p onClick={() => { handleDocView(value) }} disabled={value.id === selectedPatient.id} style={{ alignContent: 'center', color: "#4b9cdf", height: "100%" }}>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-width="1.5"><path d="M5 8c0-2.828 0-4.243.879-5.121C6.757 2 8.172 2 11 2h2c2.828 0 4.243 0 5.121.879C19 3.757 19 5.172 19 8v8c0 2.828 0 4.243-.879 5.121C17.243 22 15.828 22 13 22h-2c-2.828 0-4.243 0-5.121-.879C5 20.243 5 18.828 5 16V8Zm0-3.924c-.975.096-1.631.313-2.121.803C2 5.757 2 7.172 2 10v4c0 2.828 0 4.243.879 5.121c.49.49 1.146.707 2.121.803M19 4.076c.975.096 1.631.313 2.121.803C22 5.757 22 7.172 22 10v4c0 2.828 0 4.243-.879 5.121c-.49.49-1.146.707-2.121.803" /><path stroke-linecap="round" d="M9 13h6M9 9h6m-6 8h3" /></g></svg>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })
                    : (search !== '' && general.length > 0 && selected === 'general') ? general.map((value, index) => {
                      return (
                        <div className='list-item-new' style={{}}>
                          <div className='row' style={{ paddingTop: "1%", paddingBottom: "1%" }}>
                            <div className="col-1" style={{ alignContent: 'center', padding: 0 }}> <OverlayTrigger overlay={<Tooltip style={{ textTransform: "capitalize" }} id="tooltip-disabled">View Profile</Tooltip>}>
                              <button className="dir-button" style={{ backgroundColor: "white", width: "100%", overflow: 'hidden', textOverflow: 'ellipsis' }} onClick={() => navigate(`/patient-record/id/${value.id}/mob/${value.mobile_number}`, {
                                state: {},
                              })}>
                                {value.photo && <img src={value.photo} alt="Profile Photo" style={{
                                  maxWidth: "none",
                                  borderRadius: "50%",
                                  width: showDoc ? "44px" : "50px",
                                  height: showDoc ? "44px" : "50px",
                                }} />}</button></OverlayTrigger>
                            </div>
                            <div className='col-11' style={{ display: 'flex', flexDirection: showDoc ? 'column' : 'row' }}>
                              <div className={`${!showDoc && 'col-4'}`} style={{ alignContent: 'center' }}>
                                <div className={`col-${showDoc ? '12' : ''}`} style={{ alignContent: 'center' }}>
                                  <button className="dir-button1" style={{ padding: 0, backgroundColor: "white", width: "100%" }} onClick={() => navigate(`/patient-record/id/${value.id}/mob/${value.mobile_number}`, {
                                    state: {},
                                  })}>
                                    <h6 style={{ letterSpacing: "0px", fontSize: "medium", textTransform: "capitalize", lineHeight: "15px", color: "#4b9cdf" }} className="each-list-link2" >&nbsp;{value.full_name}</h6>
                                  </button>
                                </div>
                              </div>
                              <div className={`${showDoc ? 'row' : 'col-8'}`} style={{ display: 'flex', flexDirection: 'row' }}>
                                <div className='col-2' style={{ display: "flex", alignItems: "center", alignContent: 'center', }} > <p className='each-list-link2' style={{ fontSize: "medium" }}><i className="fa fa-address-card fa-lg" aria-hidden="true" style={{ fontSize: "1rem" }}></i>&nbsp; {value.id} </p></div>
                                <div className='col-4' style={{ display: "flex", alignItems: "center", alignContent: 'center', }}> <p className='each-list-link2' style={{ fontSize: "medium" }}> <i className="fa fa-phone fa-lg" aria-hidden="true" style={{ fontSize: "1rem" }}></i>&nbsp; {value.mobile_number} </p>
                                </div>
                                <div className='col-4' style={{ display: "flex", alignItems: "center", alignContent: 'center', flexWrap: 'wrap' }}>
                                  <p onClick={() => {
                                    if (login_datas.user_type === 'hospital_bdo') {
                                      setAssignID(value.id)
                                      if (value.bedunit !== null) { setReassign(true) }
                                      else { setReassign(false) }
                                      setShow1(true)
                                    }
                                  }} className='each-list-link2' style={{ alignContent: 'center', fontSize: "medium", color: "#4b9cdf", cursor: login_datas.user_type === 'hospital_bdo' ? "pointer" : "default" }}>
                                    {value.bedunit && value.bedunit !== null ? <img
                                      src={
                                        value && value.bedunit && value.bedunit.bedspace_type === "ward"
                                          ? ward
                                          : value && value.bedunit && value.bedunit.bedspace_type === "room"
                                            ? bed
                                            : ""
                                      }
                                      alt="Ward"
                                      style={{ width: "25%", backgroundColor: "white", marginTop: "-5px", padding: "1%" }}
                                    /> : <>&nbsp;&nbsp;<svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 14 14"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M7 4v6M4 7h6m.5-6.5h-7a3 3 0 0 0-3 3v7a3 3 0 0 0 3 3h7a3 3 0 0 0 3-3v-7a3 3 0 0 0-3-3" /></svg>&nbsp; Add</>}&nbsp; {value && value.bedunit && value.bedunit !== null && value.bedunit.bedunit_code}
                                  </p>
                                </div>
                                <div className='col-2' style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
                                  <p onClick={() => { handleDocView(value) }} disabled={value.id === selectedPatient.id} style={{ alignContent: 'center', color: "#4b9cdf", height: "100%" }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-width="1.5"><path d="M5 8c0-2.828 0-4.243.879-5.121C6.757 2 8.172 2 11 2h2c2.828 0 4.243 0 5.121.879C19 3.757 19 5.172 19 8v8c0 2.828 0 4.243-.879 5.121C17.243 22 15.828 22 13 22h-2c-2.828 0-4.243 0-5.121-.879C5 20.243 5 18.828 5 16V8Zm0-3.924c-.975.096-1.631.313-2.121.803C2 5.757 2 7.172 2 10v4c0 2.828 0 4.243.879 5.121c.49.49 1.146.707 2.121.803M19 4.076c.975.096 1.631.313 2.121.803C22 5.757 22 7.172 22 10v4c0 2.828 0 4.243-.879 5.121c-.49.49-1.146.707-2.121.803" /><path stroke-linecap="round" d="M9 13h6M9 9h6m-6 8h3" /></g></svg>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })
                      : <h6 className='nodata'>No Patients Found</h6>
            }
          </div>
        </div>
        {(showDoc || isAnimating) &&
          <div className={` ${showDoc ? 'col-4 slide-in' : 'slide-out'}`} style={{ marginTop: '38px' }}>
            <div className='doc-right'>
              <div style={{ textAlign: 'right', paddingRight: '5px' }}><CgCloseR onClick={() => { handleToggle() }} style={{ cursor: 'pointer' }} /></div>
              <div style={{ minHeight: "400px", display: 'flex', flexDirection: 'column' }}>
                <label className=" title-of-tasks">{selectedPatient.full_name} </label>
                {selectedPatient === '' ?
                  <div className='text-center'><svg style={{ marginTop: "120px" }} xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 24 24"><path fill="rgba(158, 158, 158, 1)" d="M21.26 21.21L3.79 3.74L2.38 5.15l2.74 2.74l-.12.12V19c0 1.1.9 2 2 2h10c.35 0 .68-.1.97-.26l1.88 1.88l1.41-1.41zM7 19V9.77L16.23 19H7zm3.84-14H17v9.11l2 2V5c0-1.1-.9-2-2-2h-6.99L7.95 5.06l1.41 1.41L10.84 5z" /></svg>
                    <h4 style={{ color: "rgba(158, 158, 158, 1)", marginTop: "2%" }}>Select a profile</h4>
                  </div> :
                  <div id="docScrollable" style={{ overflowY: "auto", overflowX: "hidden", maxHeight: "380px", paddingRight: "2%", paddingLeft: "2%" }}>
                    <div >  {reports.length > 0 ? reports.map((item, index) => {
                      return (
                        <div className='row' style={{ marginBottom: "2%", marginLeft: "5%" }}>
                          <div className='col-9 row listofrep ' style={{ padding: "1%" }}>
                            <div className='col-6 text-center' style={{ borderRight: "1.5px solid rgba(0, 0, 0, 1)" }}>
                              <h6 style={{ textTransform: "capitalize" }}><b>{item.doc_type.replace("_", " ")}</b></h6>
                              <span className="">{item.uploaded_at.slice(0, 10)}</span>
                            </div>
                            <div style={{ backgroundColor: "rgba(240, 240, 240, 1)" }} className='col-6'>
                              <h6 style={{ color: item.comment ? "black" : "red" }}>{item.comment ? item.comment : "No Remarks!!"}</h6>
                            </div>
                          </div>
                          &nbsp; &nbsp; &nbsp;
                          <div className='col-2  ' style={{ padding: "1%", display: "flex", height: "45px" }}>
                            <button onClick={() => { setInfo(item.images); setShow(true) }} style={{ backgroundColor: "rgba(36, 86, 147, 1)", color: "white", width: "55px", borderRadius: "10px" }} className='dir-button '>View</button>
                            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                            <i onClick={() => { login_datas.user_type !== "hospital_nurse" && deleteItem(item.id) }} style={{ color: login_datas.user_type !== "hospital_nurse" ? "rgba(229, 89, 11, 1)" : "gray", cursor: login_datas.user_type !== "hospital_nurse" ? "pointer" : "not-allowed" }} class="fa fa-trash fa-2x" aria-hidden="true"></i>
                          </div>
                        </div>
                      )
                    }) : <div className='title-of-tasks' style={{ color: "lightgrey", marginTop: "25%" }}><svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 15 15"><path fill="none" stroke="currentColor" d="m9.5 5.5l-4 4m5-9h-8a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1v-10l-3-3Zm-3 10a3 3 0 1 1 0-6a3 3 0 0 1 0 6Z" /></svg>
                      <br /><span>--No Data--</span>
                    </div>}
                    </div>
                  </div>}
              </div>
              {selectedPatient !== '' && <div className='text-center'>
                <button disabled={login_datas.user_type === "hospital_nurse"} onClick={() => { setAddShow(true) }} style={{ width: "50%", backgroundColor: "rgba(9, 188, 255, 1)", height: "40px" }} className='cons-button'>
                  <svg style={{ paddingBottom: "1%" }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="white"><path fill-rule="evenodd" d="M12 15.75a.75.75 0 0 0 .75-.75V4.027l1.68 1.961a.75.75 0 1 0 1.14-.976l-3-3.5a.75.75 0 0 0-1.14 0l-3 3.5a.75.75 0 1 0 1.14.976l1.68-1.96V15c0 .414.336.75.75.75Z" clip-rule="evenodd" /><path d="M16 9c-.702 0-1.053 0-1.306.169a1 1 0 0 0-.275.275c-.169.253-.169.604-.169 1.306V15a2.25 2.25 0 1 1-4.5 0v-4.25c0-.702 0-1.053-.169-1.306a1 1 0 0 0-.275-.275C9.053 9 8.702 9 8 9c-2.828 0-4.243 0-5.121.879C2 10.757 2 12.17 2 14.999v1c0 2.83 0 4.243.879 5.122C3.757 22 5.172 22 8 22h8c2.828 0 4.243 0 5.121-.879C22 20.242 22 18.828 22 16v-1c0-2.829 0-4.243-.879-5.121C20.243 9 18.828 9 16 9Z" /></g></svg>&nbsp;&nbsp;<span style={{ paddingTop: "1%" }}>Upload Document</span></button>
              </div>}
            </div>
          </div>
        }
      </div>
      {
        addShow ?
          <AddPopUp
            show={addShow}
            onHide={() => setAddShow(false)}

          /> : null
      }
      {show ? (
        <InfoPopup
          show={show}
          onHide={() => {
            setShow(false);
          }}
        />
      ) : (
        ""
      )}
      {show1 ? (
        <ViewPopup
          show={show1}
          onHide={() => {
            setShow1(false);
          }}
        />
      ) : (
        ""
      )}
    </>
  )
}

export default UserListDr;
