import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';
import { APIURL } from "../../Global";
import { useAlert } from "react-alert";
import "./changepasswordmanager.css"
import { UserManagementContext } from "../contexts/UserManagementContext";
import { UserManagementRenderContext } from "../contexts/UserManagementRenderContext";

function ChangePassword({ id , handle}) {
    const [passwordType, setPasswordType] = useState("password");
    const [passwordType1, setPasswordType1] = useState("password");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState(null);
    const [error, setError] = useState({
        password: '',
        confirm_password: "",
    });
    const alert = useAlert();
    const navigate = useNavigate();
    const [selected] = useContext(UserManagementContext)
    const [rendered, setRendered] = useContext(UserManagementRenderContext)

    useEffect(() => {
        const pushState = () => {
            window.history.pushState(null, null, window.location.pathname);
        };

        const handlePopState = (e) => {
            pushState();
            setRendered('users');
        };
        pushState();
        window.addEventListener('popstate', handlePopState);

        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, []);

    const togglePasswordVisiblity = (e) => {

        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }
    const togglePasswordVisiblity1 = (e) => {

        if (passwordType1 === "password") {
            setPasswordType1("text")
            return;
        }
        setPasswordType1("password")
    }

    const handlePasswordChange = (e) => {


        setPassword(e.target.value);
    }
    const handleConfirmPassword = (e) => {

        setConfirmPassword(e.target.value);
    }

    const validate = async () => {

        let errors = {};
        let isValid = true;
        setError({});

        if (password !== "undefined") {
            let pattern = new RegExp(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*#?&^_.-]).{8,30}/);
            if (!pattern.test(password)) {
                isValid = false;
                errors["password"] = "The password should be 8-30 characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character (@$.!#^%*?&_). Spaces are not allowed.";
            }
        }
        if (!password || password === "" || password === null) {
            isValid = false;
            errors["password"] = "Enter password";
        }

        if (!confirmPassword || confirmPassword === "" || confirmPassword === null || typeof confirmPassword === "undefined") {
            isValid = false;
            errors["confirm_password"] = "Enter confirm password";
        }

        if (typeof password !== "undefined" && password !== "" && password !== null && typeof confirmPassword !== "undefined" && confirmPassword !== "" && confirmPassword !== null) {
            if (password !== confirmPassword) {
                isValid = false;
                errors["confirm_password"] = "Passwords don't match";
            }
        }

        await setError(currentstate => ({
            ...currentstate,
            ...errors
        }));


        return isValid;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (await validate()) {
            callSubmit();

        }
    }
    const callSubmit = () => {

        const tokenString = sessionStorage.getItem("usertoken");

        let v2 = tokenString.replace(
            /["]+/g,
            ""
        );
        const data = {
            password: password
        }
        const role = selected === 'carers' ? 'carer-detail' : selected === 'nurse' ? 'nurse-detail' : selected === 'allied-professional' ? 'allied-professional-detail' : selected === 'higher-officials' ? 'higherofficial-detail' : selected === 'front-offices' ? 'front-office' : selected
        axios
            .put(`${APIURL}/api/v1/service-provider/${role}/${id}/`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: "Token " + v2
                },
            })
            .then((res) => {
                if (res.data.status === "success") {
                    alert.success('Password changed successfully ')
                    handle()

                } else {

                    alert.error("error");
                }
            })
            .catch((err) => {

                alert.error("error");
            });
    }


    return (
        <div style={{ display: 'flex', flexDirection: 'column' }} >
            <form className="change-pw-form col-lg-6">
                <h4 className="title-of-page"> Change Password </h4>
                <h5 className="title-of-tasks" > {selected === 'bdo' ? 'Supervisor' : selected} ID : {id} </h5>
                <div className="form-group ">
                    <div className="form-control" style={{ backgroundColor: "rgb(232, 240, 254)" }}>
                        <input className="box1 " type={passwordType} maxLength='30' placeholder="*Enter new Password" value={password} onChange={handlePasswordChange} />
                        <span style={{ float: "right" }}>

                            {passwordType === "password" ? <i onClick={togglePasswordVisiblity} className="fa fa-eye-slash"></i> : <i onClick={togglePasswordVisiblity} className="fa fa-eye"></i>}
                        </span>
                    </div>
                    {error.password ? <div className="error-validation-msg"> {error.password}</div> : null}
                </div>
                <div className="form-group ">
                    <div className="form-control" style={{ backgroundColor: "rgb(232, 240, 254)" }}>
                        <input className="box1 " type={passwordType1} maxLength='30' placeholder="*Confirm Password" value={confirmPassword} onChange={handleConfirmPassword} />
                        <span style={{ float: "right" }}>
                            {passwordType1 === "password" ? <i onClick={togglePasswordVisiblity1} className="fa fa-eye-slash"></i> : <i onClick={togglePasswordVisiblity1} className="fa fa-eye"></i>}
                        </span>
                    </div>
                    {error.confirm_password ? <div className="error-validation-msg"> {error.confirm_password}</div> : null}
                </div>
                <div className="form-button ">
                    <button onClick={() => setRendered('user')} className="btn btn-primary btn-col" > Cancel</button>
                    <span>&nbsp;&nbsp;</span>
                    <button onClick={handleSubmit} className="btn btn-primary btn-col">Update password </button>
                </div>
            </form>
        </div>
    );
}
export default ChangePassword;