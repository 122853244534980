import React, { useState, useEffect } from 'react';
import './csc.css';
import { Modal } from 'react-bootstrap';
import axios from 'axios';
import { APIURL } from '../../../Global';
import Calendar from 'react-calendar';
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip';
import 'react-calendar/dist/Calendar.css';
import moment from 'moment';
import { useAlert } from 'react-alert';
import OpBookingConfirmPopup from './OpBookingConfirmPopup';
import OpBookedPopup from './OpBookedPopup';
import 'bootstrap/dist/css/bootstrap.min.css';
import SubmitConfirmationPopup from '../../common/SubmitConfirm';
import { useNavigate } from 'react-router-dom';

function GeneralOp({ selectedPatient, setSelectedPatient, selectedDoctor, setSelectedDoctor, selected }) {
    const [selectedDay, setSelectedDay] = useState('');
    const [selectedSlot, setSelectedSlot] = useState(null);
    const [selectedDayForCalendar, setSelectedDayForCalendar] = useState('');
    const [display, setDisplay] = useState(false)
    const [confirmationData, setConfirmationData] = useState({});
    const [bookInfo, setBookInfo] = useState('')
    const [booked, setBooked] = useState(false);
    const [data, setData] = useState([]);
    const [slots, setSlots] = useState([]);
    const [popStateEventFired, setPopStateEventFired] = useState(false);
    const [showBackPopup, setShowBackPopup] = useState(false);
    const startDate = new Date().toISOString().slice(0, 10)
    const endDate = getEndDate(new Date())
    const alert = useAlert()
    const [highlightedDaysCount, setHighlightedDaysCount] = useState(0);
    const navigate = useNavigate()
    const [onload, setOnload] = useState(false)

    function getEndDate(date) {
        const updated = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 30);
        return updated.toISOString().slice(0, 10);
    }

    useEffect(() => {
        setData([])
    }, [selected])

    const handlePopState = (e) => {
        if (!popStateEventFired) {
            console.log('User clicked back button');
            setPopStateEventFired(true);
            setShowBackPopup(true);
        }
    };

    useEffect(() => {
        if (display) {
            window.history.pushState(null, "", window.location.href);
            const handlePopStateOverride = () => {
                window.history.pushState(null, "", window.location.href);
            };
            window.addEventListener('popstate', handlePopState);
            window.addEventListener('popstate', handlePopStateOverride);

            return () => {
                window.removeEventListener('popstate', handlePopState);
                window.removeEventListener('popstate', handlePopStateOverride);
            };
        }
    }, [display]);

    useEffect(() => {
        setSlots([])
        setSelectedDay('')
        setSelectedDayForCalendar('')
        if (selectedDoctor === undefined || selectedDoctor === '') {
            setData([])
        } else {
            const doctorId = selectedDoctor.id;
            if (doctorId !== '' && doctorId !== null && doctorId !== undefined) {
                const tokenString = sessionStorage.getItem('usertoken');
                let str = tokenString.replace(/["]+/g, '');

                axios
                    .get(
                        `${APIURL}/api/v1/patient/appointments/availability/?start_date=${startDate}&end_date=${endDate}&doctor_id=${doctorId}&src=web`,
                        {
                            headers: {
                                Authorization: 'Token ' + str,
                            }
                        }
                    )
                    .then((res) => {
                        if (res.data.status === 'success') {
                            let data = res.data.data;
                            data = filterScheduleData(data);
                            setData(data);
                        }
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            }
        }
    }, [selectedDoctor, startDate, endDate]);

    const filterScheduleData = (data) => {
        return data
            .map(day => {
                const filteredSlots = day.slots.filter(slot => !slot.is_cancelled);
                return { ...day, slots: filteredSlots };
            })
            .filter(day => day.slots.length > 0);
    };

    const handleDayClick = (date) => {
        calculateHighlightedDays();
        const clickedDayForDisplay = moment(date).format('DD/MM/YYYY');
        const clickedDayForComparison = moment(date).format('YYYY-MM-DD');
        const today = moment().format('YYYY-MM-DD');
        const currentTime = moment().format('HH:mm:ss');

        console.log('Clicked Day:', clickedDayForComparison);

        setSelectedDay(clickedDayForDisplay);
        setSelectedDayForCalendar(date);

        let clickedDayData = data.find(item => item.day === clickedDayForComparison);

        if (clickedDayData) {
            let slots = clickedDayData.slots;
            if (clickedDayForComparison === today) {
                slots = slots.filter(slot => slot.to > currentTime);
            }
            setSlots(slots.length > 0 ? slots : []);
        } else {
            setSlots([]);
        }
    };


    const getTileClassName = ({ date, view }) => {
        if (view === 'month') {
            const day = moment(date).format('YYYY-MM-DD');
            const today = moment().format('YYYY-MM-DD');
            const currentTime = moment().format('HH:mm:ss');

            // Find the day's data
            const dayData = Array.isArray(data) && data.find(item => item.day === day);

            if (dayData && dayData.slots.length > 0) {
                // If the date is today, filter out past slots
                if (day === today) {
                    const futureSlots = dayData.slots.filter(slot => slot.to > currentTime);
                    if (futureSlots.length > 0) {
                        return 'highlight';
                    }
                } else {
                    return 'highlight';
                }
            }
        }
        return null;
    };

    const calculateHighlightedDays = () => {
        let count = 0;

        if (Array.isArray(data)) {
            const today = moment().format('YYYY-MM-DD');
            const currentTime = moment().format('HH:mm:ss');

            data.forEach(item => {
                const day = item.day;

                if (item.slots.length > 0) {
                    if (day === today) {
                        const futureSlots = item.slots.filter(slot => slot.to > currentTime);
                        if (futureSlots.length > 0) {
                            count += 1;
                        }
                    } else {
                        count += 1;
                    }
                }
            });
        }

        setHighlightedDaysCount(count);
    };

    const handleRadioClick = (e, slot) => {
        if (selectedSlot && selectedSlot.id === slot.id) {
            setSelectedSlot(null);
            e.target.checked = false;
        }
    };

    const handleRadioChange = (slot) => {
        setSelectedSlot(prevSlot => prevSlot && prevSlot.id === slot.id ? null : slot);
    };

    const handleContinue = async () => {
        setOnload(true);
        await new Promise(resolve => setTimeout(resolve, 0));
        if (selectedPatient === '') {
            alert.error('Select a patient')
            setOnload(false)
        } else if (selectedSlot === null) {
            alert.error('Select a slot')
            setOnload(false)
        } else {
            const tokenString = sessionStorage.getItem("usertoken");
            let str = tokenString.replace(/["]+/g, "");
            const pape_number = selectedPatient.applicant_details.pape_number
            const payload = {
                slot_id: selectedSlot.id,
                date: moment(selectedDayForCalendar).format('YYYY-MM-DD'),
                doctor_id: selectedDoctor.id
            }

            axios
                .post(`${APIURL}/api/v1/patient/palliative-appointment/?pape_number=${pape_number}`, payload, {
                    headers: {
                        Authorization: "Token " + str,
                        "Content-Type": "application/json",
                    },
                })
                .then((res) => {

                    if (res.data.status === "success") {
                        alert.success(res.data.message)
                        setBookInfo(res.data.booking_id)
                        handleContinueRest(res.data.booking_id, res.data.token)
                        // setScheduleId('')
                    }
                    else {
                        alert.error(res.data.message)
                    }
                    setOnload(false)
                })
                .catch((err) => {
                    alert.error('error occured')
                    setOnload(false)
                });
        }
    }


    const handleContinueRest = (appt, token_no) => {
        const appointment_id = appt
        const token = token_no
        const arriving_start = moment(selectedSlot.from, 'HH:mm:ss').subtract(1, 'hours').format('HH:mm:ss')
        const arriving_end = moment(selectedSlot.from, 'HH:mm:ss').subtract(30, 'minutes').format('HH:mm:ss')
        const patient_name = selectedPatient.applicant_details.full_name.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
        const doctor_id = selectedDoctor.id;
        const doctor_name = selectedDoctor.full_name.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
        const date = selectedDay;
        const time = `${selectedSlot.from} - ${selectedSlot.to}`;
        const arriving_time = `${arriving_start} - ${arriving_end}`;

        setConfirmationData({
            appointment_id,
            token,
            patient_name,
            doctor_id,
            doctor_name,
            date,
            time,
        });

        setDisplay(true);
    };

    const onConfirm = () => {
        if (!onload) {
            handleBooking()
        }
    }

    const handleBooking = async () => {
        setOnload(true);
        await new Promise(resolve => setTimeout(resolve, 0));
        const tokenString = sessionStorage.getItem("usertoken");
        let str = tokenString.replace(/["]+/g, "");

        const payload = {
            appt_id: bookInfo,
            status: 'success'
        }

        axios
            .put(`${APIURL}/api/v1/patient/palliative-appointment-detail/`, payload, {
                headers: {
                    Authorization: "Token " + str,
                },
            })
            .then((res) => {

                if (res.data.status === "success") {
                    alert.success("Booking completed successfully")
                    setDisplay(false)
                    setBooked(true)
                }
                else {
                    alert.error(res.data.message)
                }
                setOnload(false)
            })
            .catch((err) => {
                alert.error('error occured')
                setOnload(false)
            });
    }

    const handleSuccess = () => {
        setBooked(false)
        setSelectedPatient('');
        setSelectedDay('');
        setSelectedSlot('');
        setData([]);
        setSlots([]);
        setSelectedDayForCalendar(new Date());
        setSelectedDoctor('');
    };

    const onCancel = () => {
        const tokenString = sessionStorage.getItem("usertoken");
        let str = tokenString.replace(/["]+/g, "");
        const payload = {
            booking_id: bookInfo
        }
        axios
            .put(`${APIURL}/api/v1/service-provider/palliative-booking-detail/?cancel=True`, payload, {
                headers: {
                    Authorization: "Token " + str,
                    "Content-Type": "application/json",
                },
            })
            .then((res) => {

                if (res.data.status === "success") {
                    alert.success(res.data.message)
                    setDisplay(false)
                }
                else {
                    alert.error(res.data.message)
                }
            })
            .catch((err) => {
            });

    }

    const handleGoBack = () => {
        onCancel()
        navigate("../")
        setShowBackPopup(false)
        setPopStateEventFired(false)
        setDisplay(false)
    }

    const handleCancelGoBack = () => {
        setShowBackPopup(false)
        setPopStateEventFired(false)
    }

    return (
        <div className='csc-calendercontainer'>
            <div className='calender-app-main'>
                <Calendar
                    value={selectedDayForCalendar}
                    onClickDay={handleDayClick}
                    tileClassName={getTileClassName}
                    formatShortWeekday={(locale, date) => {
                        const options = { weekday: 'long' };
                        return new Intl.DateTimeFormat(locale, options).format(date);
                    }}
                />
            </div>
            &nbsp;&nbsp;&nbsp;
            <div className='slots-box'>
                <div className='slots-box-head'>
                    <label>Available Slots</label>
                </div>
                <div className='slots-box-content'>
                    {slots.length > 0 && (
                        <>
                            <div className='slots-box-subcontent'>
                                <label>{selectedDoctor.full_name}</label>
                                <label>{highlightedDaysCount} days</label>
                            </div>
                            <div style={{ display: "flex" }}>
                                <label className='blue-label'>{selectedDay}</label>
                            </div>
                        </>
                    )}
                    <div className='slots-list-wrapper'>
                        {slots.length > 0 ? (
                            slots.map((slot, index) => (
                                <div className='csc-slotlist' key={index}>
                                    <label className='col-6' style={{ padding: 0 }}>{slot.from} - {slot.to}</label>
                                    <label className='col-4'><span style={{ fontSize: '12px', fontWeight: 500 }}><b> {slot.remaining_token} {slot.remaining_token === 1 ? "Token" : "Tokens"}</b></span></label>
                                    <label className='col-2' style={{ padding: 0 }}>
                                        <input
                                            type='radio'
                                            name='slot'
                                            value={slot.id}
                                            onChange={() => handleRadioChange(slot)}
                                            onClick={(e) => handleRadioClick(e, slot)}
                                            checked={selectedSlot && selectedSlot.id === slot.id}
                                            disabled={slot.remaining_token < 1 || selectedPatient === ''}
                                            data-tooltip-id="my-tooltip"
                                            data-tooltip-content={!selectedPatient ? 'Select patient' : ''}
                                        />
                                    </label>
                                </div>
                            ))
                        ) : (
                            <label>No available slots for selected date</label>
                        )}
                    </div>
                    {slots.length > 0 &&
                        <>
                            <button
                                className='save-button'
                                disabled={!selectedPatient}
                                style={{ width: '200px', backgroundColor: !selectedPatient ? '#2456ae82' : '' }}
                                onClick={() => handleContinue()}
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content={!selectedPatient ? 'Select patient' : ''}
                            >
                                Book Appointment
                            </button>
                            <Tooltip id="my-tooltip" style={{ backgroundColor: "#04040466;", color: "#FFFFFF" }} />
                        </>}
                </div>
            </div>
            {display && <OpBookingConfirmPopup content={confirmationData} close={onCancel} confirm={onConfirm} />}
            {booked && <OpBookedPopup content={confirmationData} close={handleSuccess} />}
            {showBackPopup && (
                <SubmitConfirmationPopup
                    message="Are you sure you want to exit the page without confirming the appoinment? "
                    onConfirm={handleGoBack}
                    onCancel={handleCancelGoBack}
                />
            )}
        </div >
    );
}

export default GeneralOp;
