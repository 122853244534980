import React, { useState, useEffect, useRef } from 'react'
import { useParams, useNavigate, Link ,useLocation} from "react-router-dom";
import axios from "axios";
import { APIURL } from "../../../Global";
import { useAlert } from "react-alert";
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import { Modal } from 'react-bootstrap';
import "./inventory.css"
import AddInventory from './AddInventory';
import ChangeStatus from './ChangeStatus';
import InvDetails from './InvDetails';
import PatientSearch from '../../common/PatientSearch';
import ZoomableImage from '../Admission/ZoomableImage';
import SideMenu from '../../HospSearchPatient/SideMenuPR';
function PersonalInventory(props) {
    
    const location = useLocation();
    const alert = useAlert()
    const [patient, setPatient] = useState({id:props.patient?props.patient.patient:props.patId?props.patId: location.state ? location.state.id:"",full_name:props.patient?props.patient.patient_name:props.patName?props.patName: location.state ? location.state.name:"", room:(location.state&&location.state.room)? location.state.room :props.patient?props.patient.bedunit_code:props.patRoom?props.patRoom:"",mobile_number:location.state ? location.state.mobile_number:props.patient?props.patient.mobile_number:props.patPhone?props.patPhone:"",death_status:props.patient?props.patient.status:""})
    const [addShow, setAddShow] = useState(false);
    const [statusShow, setStatusShow] = useState(false)
    const [detailsShow, setDetailShow] = useState(false)
    const [data, setData] = useState({})
    const [refresh, setRefresh] = useState(false)
    const [show, setShow] = useState(false);
    const [info, setInfo] = useState("")
    const [admission,setAdmission]=useState(location.state&&location.state.admission?location.state.admission: props.admission?props.admission:"")
    const [inventoryData, setInventoryData] = useState([{
        item: "",
        id: "",
        deposited_at: "",
        deposited_by: "",
        returned_on: "",
        returned_to: "",
        status: "",
        value: "",



    },

    ])
 
    useEffect(() => {

        const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        const tokenString = sessionStorage.getItem("usertoken");

        let v2 = tokenString.replace(
            /["]+/g,
            ""
        ); /* REGEX To remove double quotes from tokenstring */


        axios
            .get(`${APIURL}/api/v1/service-provider/pers-inventory/?timeZone=${currentTimezone}&patient_id=${patient.id}`, {
                headers: { Authorization: "Token " + v2 },
            })
            .then((res) => {
                if (res.data.status === "success") {
                    if (res.data.message.length > 0) {
                        const collected = res.data.message.filter((item) => {
                            return item.status === "collected";
                          });
                        
                          const returned = res.data.message.filter((item) => {
                            return item.status === "returned";
                          });
                         
                      
                        setInventoryData([...collected,...returned])
                    }
                }
                else {

                }
            }).catch((error) => {

            })



    }, [patient,refresh])

    const onSuccess = () => {
        setDetailShow(false)
        setStatusShow(false)
        setAddShow(false)
        setRefresh(!refresh)
    };
    const AddPopUp = (props) => {
        return (
            <Modal
                {...props}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                keyboard={false}
                style={{ minHeight: "450px" }}
            >

                <Modal.Body style={{minHeight:"400px"}}>
                    <h6 style={{marginLeft:"2%",textTransform:"capitalize"}}><b>{patient.full_name}</b>
                        <button style={{ backgroundColor: "white",float:"right"}} variant="secondary" onClick={props.onHide}>
                            <i class="fa fa-times-circle fa-2x" style={{ color: "#F0A639" }} aria-hidden="true"></i>
                        </button></h6>
                    {props.from==="admission"?<AddInventory from="admission" patId={patient.id} admission={admission} onSuccess={onSuccess}/>:<AddInventory patId={patient.id} admission={admission} onSuccess={onSuccess} />}


                </Modal.Body>

            </Modal>
        )
    }
    const StatusPopUp = (props) => {
        return (
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                keyboard={false}
                
            >

                <Modal.Body style={{ width: "95%", paddingRight: "8%" }}>
                    <h6 style={{ paddingLeft: "4%" }}><b>{data.item}</b>
                        <button style={{ backgroundColor: "white", float: "right", marginRight: "2%", }} variant="secondary" onClick={props.onHide}>
                            <i class="fa fa-times-circle fa-2x" style={{ color: "#F0A639" }} aria-hidden="true"></i>
                        </button></h6><br />
                    <ChangeStatus data={data} onSuccess={onSuccess} />


                </Modal.Body>

            </Modal>
        )
    }
    const DetailsPopUp = (props) => {
        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                keyboard={false}
                style={{ minHeight: "550px" }}
            >

                <Modal.Body style={{ width: "95%", paddingRight: "5%" }}>
                    <h6 style={{ paddingLeft: "4%" }}><b>{data.item}</b>
                        <button style={{ backgroundColor: "white", float: "right", marginRight: "2%", }} variant="secondary" onClick={props.onHide}>
                            <i class="fa fa-times-circle fa-2x" style={{ color: "#F0A639" }} aria-hidden="true"></i>
                        </button></h6><br />
                    <InvDetails data={data} onSuccess={onSuccess} />


                </Modal.Body>

            </Modal>
        )
    }
    const handleStatusChange = (item) => {
        if(patient.death_status==="pending"){
            alert.error("Death is not approved by the authority")
        }else if(patient.death_status==="rejected"){
            alert.error("Death is rejected by the authority")
        }
        else{
        setData(item)
        setStatusShow(true)
        }
    }
    const handleDetails = (item) => {
        setData(item)
        setDetailShow(true)
    }
   
    const renderTable = (cellInfo) => {
        return (
            <div style={{ overflow: "wrap",textTransform:"capitalize" }}>{inventoryData[cellInfo.index][cellInfo.column.id]}</div>
        )
    }
    const renderDate = (cellInfo) => {
        let dateString = inventoryData[cellInfo.index][cellInfo.column.id]
        let dateObject = dateString !== null || dateString !== "" ? new Date(dateString) : "";

        const updatedDate = dateObject.toString();
        return (
            dateString ? <div style={{ color: "black" }}>
                {updatedDate.slice(4, 16)}

            </div> : ""
        )
    }
 
    const columns = [
        {
            Header: "No",
            accessor: "id",
            Cell: renderTable,

            sortable: true,
            filterable: true,
            filterMethod: (filter, row, column) => {
                const id = filter.pivotId || filter.id
                return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true
            },
            width: 100

        },
        {
            Header: " Item",
            accessor: "item",
            Cell: renderTable,

            sortable: true,
            filterable: true,
              filterMethod: (filter, row, column) => {
                const id = filter.pivotId || filter.id
                return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true
              },
            //  width: 220

        },
       
        {
            Header: " Date Received",
            accessor: "deposited_at",
            Cell: renderDate,

            sortable: true,
            filterable: true,
            filterMethod: (filter, row, column) => {
                const id = filter.pivotId || filter.id;
                let dateString = String(row[id]);
                let dateObject = new Date(dateString);
                const updatedDate = dateObject.toString();
                return row[id] !== undefined ? updatedDate.toLowerCase().includes(filter.value.toLowerCase()) : true
              },
             width: 150

        },
        {
            Header: "Deposited By",
            accessor: "deposited_by",
            Cell: renderTable,

            sortable: true,
            filterable: true,
            filterMethod: (filter, row, column) => {
                const id = filter.pivotId || filter.id
                return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true
            },
            //  width: 220

        },
        {
            Header: " Date Returned",
            accessor: "returned_on",
            Cell: renderDate,
            sortable: true,
            filterable: true,
            filterMethod: (filter, row, column) => {
                const id = filter.pivotId || filter.id;
                let dateString = String(row[id]);
                let dateObject = new Date(dateString);
                const updatedDate = dateObject.toString();
                return row[id] !== undefined ? updatedDate.toLowerCase().includes(filter.value.toLowerCase()) : true
              },
             width: 150

        },
        {
            Header: " Returned To",
            accessor: "returned_to",
            Cell: renderTable,

            sortable: true,
            filterable: true,
            filterMethod: (filter, row, column) => {
                const id = filter.pivotId || filter.id
                return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true
            },
            // width: 220

        },
        {
            Header: " Actions",
            accessor: "status",
             width: 220,
            style: { textAlign: "center"},
            Cell: (props) => {
                return (
                    inventoryData[props.index].status !== "" && <div style={{ color: "red" }}>

                        {inventoryData[props.index].status === "collected" ? <button onClick={() => { handleStatusChange(inventoryData[props.index]) }} style={{ color: "#33C300",border:"1px solid #33C300",width:"80px" ,backgroundColor:"white" }}  aria-hidden="true">Accepted</button>
                         : <button  style={{ color: "#ECBB0F",border:"1px solid #ECBB0F",width:"80px",backgroundColor:"white",}}  aria-hidden="true">Returned</button>}
                        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        <i onClick={() => {if(inventoryData[props.index].item_image!==null){
                setInfo(inventoryData[props.index].item_image);
                setShow(true)}
            }}
                        style={{fontSize:"20px",color:inventoryData[props.index].item_image!==null?"Highlight":"lightgrey" }}
                            className="fa fa-eye fa-lg"></i>
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        <i
                            style={{ color: "purple",fontSize:"20px"  }}
                            className="fa fa-info-circle fa-lg"
                            onClick={() => {
                                handleDetails(inventoryData[props.index]) 
                            }}
                        ></i>
                        <span>&nbsp;&nbsp;&nbsp;</span>

                      


                    </div>
                );
            },

            sortable: true,
            filterable: true,
            filterMethod: (filter, row, column) => {
                const id = filter.pivotId || filter.id
                return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true
            },
          

        },
    ]
    const InfoPopup = (props) => {
        return (
            <Modal
            {...props}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header style={{ borderBottom: "none", height: "30px", marginTop: "2%", }}>
              <button style={{ backgroundColor: "white", marginLeft: "95%" }} variant="secondary" onClick={props.onHide}>
                <i class="fa fa-times-circle fa-2x" style={{ color: "#F0A639" }} aria-hidden="true"></i>
              </button>
            </Modal.Header>
            <Modal.Body style={{ paddingRight: "7%", textAlign: "center" }}>
              <ZoomableImage src={info} />
            </Modal.Body>
          </Modal>
        );
    };
   
    return (
        <div className='row'>
            {location.state && location.state.from==="pr"&& <div className='col-2'>
                <SideMenu id={patient.id}/>
            </div>}
            <div className= {location.state && location.state.from==="pr"?'col-10':"col-12"}>
            <div className='container' style={{ paddingTop: "2%", paddingBottom: "2%" }}>
            <h6 style={{ textDecoration: "underline" }} className="enquiryheader">Residents' Item Inventory</h6>
           {!props.patient&&!location.state&&<PatientSearch  onSuccess={(data) => {setInventoryData([]);setPatient(data);setAdmission(data.admission_date)}}  onRadioChng={(data) => {setInventoryData([]);setPatient({ id: "",
        full_name:"",
        room:"",
        mobile_number: ""})}}  from="inv"/>}
            {patient.id&& <div className='row' style={{ marginTop: "2%" ,fontSize:"17px", justifyContent:"center"}}>
                        <div className='col-3'><label className='text-style-here ' ><i style={{color:"Highlight"}} class="fa fa-user" aria-hidden="true"></i></label>&nbsp;
                            <label  className='' style={{ backgroundColor: "white",fontWeight:"600", cursor: "default" ,textTransform:"capitalize"}} >{patient.full_name}</label></div>&nbsp;&nbsp;&nbsp;
                        <div className='col-2'><label className='text-style-here ' ><i style={{color:"Highlight"}}class="fa fa-id-card" aria-hidden="true"></i></label>&nbsp;
                        <label  className='' style={{ backgroundColor: "white", cursor: "default",fontWeight:"600" }} >{patient.id}</label></div>&nbsp;&nbsp;&nbsp;
                           
                        <div className='col-2'><label className='text-style-here '><i style={{color:"Highlight"}}class="fa fa-bed" aria-hidden="true"></i></label>&nbsp;
                        <label  className='' style={{ backgroundColor: "white", cursor: "default",fontWeight:"600" }} >{patient.room}</label></div>&nbsp;&nbsp;&nbsp;
                        {/* {patient.mobile_number!==""&&patient.mobile_number!==null ? <><div className='col-2'><label className='text-style-here '><i style={{color:"Highlight"}}class="fa fa-mobile" aria-hidden="true"></i></label>&nbsp;
                        <label  className='' style={{ backgroundColor: "white", cursor: "default",fontWeight:"600" }} >{patient.mobile_number}</label></div>&nbsp;&nbsp;&nbsp;</>: ""} */}
                     
                    </div>}
            {props.from!=="deathrep"&&!patient.deceased&&<button style={{ marginTop: "" }} disabled={!patient.id} onClick={() =>  setAddShow(true) } className='inv-button' >Add New Item</button>}<br />
            <ReactTable
                style={{ marginTop: "2%" }}
                columns={columns}
                data={inventoryData}
                defaultPageSize={10}
                className="inventory-table"
                resizable
                minRows={10}
                pageSizeOptions={[10, 20, 25, 50, 100]}
                pagination
                paginationServer
            ></ReactTable>
            {
                addShow ?
                    <AddPopUp
                        show={addShow}
                        onHide={() => setAddShow(false)}

                    /> : null
            }
            {
                statusShow ?
                    <StatusPopUp
                        show={statusShow}
                        onHide={() => setStatusShow(false)}

                    /> : null
            }
             {
                detailsShow ?
                    <DetailsPopUp
                        show={detailsShow}
                        onHide={() => setDetailShow(false)}

                    /> : null
            }
            {show ? (
                <InfoPopup
                    show={show}
                    onHide={() => {
                        setShow(false);
                    }}
                />
            ) : (
                ""
            )}

        </div>
            </div>
        </div>
       
    )
}
export default PersonalInventory