import React, { useState, createContext } from "react";
export const UserManagementRenderContext = createContext();

export const UserManagementRenderProvider = (props) => {
    const [rendered, setRendered] = useState('users');
    return (
        <UserManagementRenderContext.Provider value={[rendered, setRendered]}>
            {props.children}
        </UserManagementRenderContext.Provider>
    );
};
