import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "../doctor/createappuserdoctor.css";
import axios from 'axios';
import { useAlert } from "react-alert";
import { APIURL } from "../../Global";
import { UserManagementContext } from "../contexts/UserManagementContext";
import { UserManagementRenderContext } from "../contexts/UserManagementRenderContext";

function CreateUser({ data, handle }) {
    const [hospData,] = useState(JSON.parse(sessionStorage.getItem('logindatas')) ? JSON.parse(sessionStorage.getItem('logindatas')) : null);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState(null);
    const [submitMsg, setSubmitMsg] = useState("");
    const [passwordType, setPasswordType] = useState("password");
    const [passwordType1, setPasswordType1] = useState("password");
    const [error, setError] = useState({
        username: "",
        password: '',
        confirm_password: "",

    });
    const hospital_username = hospData.hospital_username;
    const navigate = useNavigate();
    const alert = useAlert();
    const { state } = useLocation()
    const [selected] = useContext(UserManagementContext)
    const [rendered, setRendered] = useContext(UserManagementRenderContext)
    const id = data.id;

    useEffect(() => {
        const pushState = () => {
            window.history.pushState(null, null, window.location.pathname);
        };

        const handlePopState = (e) => {
            pushState();
            setRendered('users');
        };
        pushState();
        window.addEventListener('popstate', handlePopState);

        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, []);


    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const handleUserNameChange = (e) => {

        setUsername(e.target.value);
    }

    const handlePasswordChange = (e) => {

        setPassword(e.target.value);
    }
    const togglePasswordVisiblity = (e) => {

        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }
    const togglePasswordVisiblity1 = (e) => {

        if (passwordType1 === "password") {
            setPasswordType1("text")
            return;
        }
        setPasswordType1("password")
    }

    const handleConfirmPassword = (e) => {

        setConfirmPassword(e.target.value);
    }
    const validate = async () => {

        let errors = {};
        let isValid = true;
        setError({});

        if (!username || username === "" || username === null) {
            isValid = false;
            errors["username"] = "Enter username prefix";
        }


        if (username !== "undefined") {
            var pattern1 = new RegExp(/^[a-zA-Z0-9]([._-](?![._-])|[a-zA-Z0-9]){0,18}[a-zA-Z0-9]$/);
            if (!pattern1.test(username) || username.length < 4) {
                isValid = false;
                errors["username"] = "Special characters and space are not allowed in username and characters limit is 4-20";
            }
        }

        if (typeof password !== "undefined") {


            let pattern = new RegExp(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*#?&^_.-]).{8,30}/);
            if (!pattern.test(password)) {
                isValid = false;
                errors["password"] = "The password should be 8-30 characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character (@$.!#^%*?&_). Spaces are not allowed.";
            }
        }
        if (!password || password === "" || password === null) {
            isValid = false;
            errors["password"] = "Enter password";
        }

        if (!confirmPassword || confirmPassword === "" || confirmPassword === null || typeof confirmPassword === "undefined") {
            isValid = false;
            errors["confirm_password"] = "Enter confirm password";
        }

        if (typeof password !== "undefined" && password !== "" && password !== null && typeof confirmPassword !== "undefined" && confirmPassword !== "" && confirmPassword !== null) {
            if (password !== confirmPassword) {
                isValid = false;
                errors["confirm_password"] = "Passwords don't match";
            }
        }

        await setError(currentstate => ({
            ...currentstate,
            ...errors
        }));


        return isValid;
    }



    const handleSubmit = async (e) => {
        e.preventDefault();
        if (await validate()) {
            callSubmit();

        }
    }
    const callSubmit = () => {


        const tokenString = sessionStorage.getItem("usertoken");

        let v2 = tokenString.replace(
            /["]+/g,
            ""
        );


        const data = {
            username: username + "@" + hospital_username,
            password: password
        }
        const role = selected === 'carers' ? 'carer-detail' : selected === 'nurse' ? 'nurse-detail' : selected === 'allied-professional' ? 'allied-professional-detail' : selected === 'higher-officials' ? 'higherofficial-detail' : selected === 'front-offices' ? 'front-office' : selected
        axios
            .post(`${APIURL}/api/v1/service-provider/${role}/${id}/`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: "Token " + v2
                },
            })
            .then((res) => {
                if (res.data.status === "success") {
                    alert.success('User credentials created successfully');
                    handle()
                } else if (res.data.message) {
                    alert.error(res.data.message);
                } else {
                    alert.error("error")
                }
            })
            .catch((err) => {
                alert.error("Try again!!");
            });
    }


    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'column' }} >
                <form className="change-pw-form col-lg-6" onSubmit={handleSubmit}>
                    <h5 className="dr-details title-of-tasks"> Name : {data.name} </h5>
                    <h5 className="dr-details title-of-tasks"> {selected === 'bdo' ? 'Supervisor' : selected} ID : {id} </h5> <br />
                    <div className="input-group mb-3">
                        <input className="form-control" type="text" maxLength='30' placeholder="*Username" value={username} onChange={handleUserNameChange} />
                        <div className="input-group-append">
                            <span className="input-group-text postfix-username" id="basic-addon2">@{hospital_username}</span>
                        </div>
                        {error.username ? <div className="error-validation-msg"> {error.username}</div> : null}
                    </div>
                    <div className="form-group ">
                        <div className="form-control" style={{ backgroundColor: "rgb(232, 240, 254)" }}>
                            <input className="box1" type={passwordType} maxLength='30' placeholder="*Password" value={password} onChange={handlePasswordChange} />
                            <span style={{ float: "right" }}>
                                {passwordType === "password" ? <i onClick={togglePasswordVisiblity} className="fa fa-eye-slash"></i> : <i onClick={togglePasswordVisiblity} className="fa fa-eye"></i>}
                            </span>
                        </div>
                        {error.password ? <div className="error-validation-msg"> {error.password}</div> : null}
                    </div>
                    <div className="form-group ">
                        <div className="form-control " style={{ backgroundColor: "rgb(232, 240, 254)" }}>
                            <input className="box1" type={passwordType1} maxLength='30' placeholder="*Confirm Password" value={confirmPassword} onChange={handleConfirmPassword} />
                            <span style={{ float: "right" }}>

                                {passwordType1 === "password" ? <i onClick={togglePasswordVisiblity1} className="fa fa-eye-slash"></i> : <i onClick={togglePasswordVisiblity1} className="fa fa-eye"></i>}
                            </span>
                        </div>
                        {error.confirm_password ? <div className="error-validation-msg"> {error.confirm_password}</div> : null}
                    </div>
                    {submitMsg !== "" ?
                        <div style={{ textAlign: 'center' }}>
                            <h5 style={{ color: "red" }}>
                                {submitMsg}
                            </h5>
                        </div>
                        :
                        null}

                    <div className="form-button " style={{ textAlign: 'center' }}>
                        <button type="submit" className="btn btn-primary btn-col" >Generate User Credentials </button>
                    </div>
                </form>
            </div>
        </>
    );
}
export default CreateUser;