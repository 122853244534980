import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
// import "./EditUser.css";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useAlert } from "react-alert";
import axios from 'axios';
import Select from 'react-select';
import { ConfirmationContext } from "../contexts/SubmitConfirmationContext";
import SubmitConfirmationPopup from "../common/SubmitConfirm";
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { APIURL } from "../../Global";
import BackButton from "../common/BackButton";
import { Switch } from "antd";
import { UserManagementContext } from "../contexts/UserManagementContext";
import { UserManagementRenderContext } from "../contexts/UserManagementRenderContext";
function EditUser({ id, handle }) {
    const [showConfirmation, setShowConfirmation, closeConfirmationPopup] = useContext(ConfirmationContext);
    const [userDatas, setUserDatas] = useState({
        name: "",
        phone: "",
        email: "",
        specializations: [],
        qualification: [],
        department: [],
        id: '',
        is_official_access: ""
    })
    const [error, setError] = useState({
        email: '',
        phone: "",
    });

    const [switchState, setSwitchState] = useState()
    const alert = useAlert();
    const [rawPhone, setRawPhone] = useState("");
    const [dialCode, setDialCode] = useState('');
    const [isValidEmail, setIsValidEmail] = useState(true);
    const [isValidPhone, setIsValidPhone] = useState(true);
    const [specializations, setSpecializations] = useState([]);
    const [specializationOptions, setSpecializationOptions] = useState([])
    const [department, setDepartment] = useState([]);
    const [departmentOptions, setDepartmentOptions] = useState([])
    const [selected] = useContext(UserManagementContext)
    const navigate = useNavigate();
    const [rendered, setRendered] = useContext(UserManagementRenderContext);
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        const pushState = () => {
            window.history.pushState(null, null, window.location.pathname);
        };

        const handlePopState = (e) => {
            pushState();
            setRendered('users');
        };
        pushState();
        window.addEventListener('popstate', handlePopState);

        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, []);

    useEffect(() => {
        const tokenString = sessionStorage.getItem("usertoken");

        let str = tokenString.replace(/["]+/g, "");
        axios

            .get(`${APIURL}/api/v1/service-provider/master-specializations/`, {
                headers: {
                    Authorization: "Token " + str,
                },
            })
            .then((res) => {
                if (res.status === 200) {
                    let data = []
                    res.data.length > 0 && res.data.map((item) => {
                        data = ([...data, { value: item.id, label: item.specialization_name }])
                    })
                    setSpecializationOptions(data)

                } else {
                    alert.error(res.data.message);
                }
            });
    }, []);

    useEffect(() => {
        const tokenString = sessionStorage.getItem("usertoken");

        let str = tokenString.replace(/["]+/g, "");
        axios

            .get(`${APIURL}/api/v1/service-provider/master-departments/`, {
                headers: {
                    Authorization: "Token " + str,
                },
            })
            .then((res) => {
                if (res.status === 200) {
                    let data = []
                    res.data.length > 0 && res.data.map((item) => {
                        data = ([...data, { value: item.id, label: item.department_name }])
                    })
                    setDepartmentOptions(data)

                } else {
                    alert.error(res.data.message);
                }
            });
    }, []);

    const formatPhoneNumber = (userData) => {
        if (userData && userData.phone && !userData.phone.startsWith('91')) {
            userData.phone = '91' + userData.phone;
        }
    };

    useEffect(() => {
        if (specializationOptions.length > 0 && departmentOptions.length > 0) {

            const tokenStr = sessionStorage.getItem('usertoken');
            if (tokenStr) {
                const v = tokenStr.replace(/["]+/g, '');
                const role = selected === 'higher-officials' ? 'higherofficial-detail' : selected === 'nurse' ? 'nurse-detail' : selected === 'carers' ? 'carer-detail' : selected === 'allied-professional' ? 'allied-professional-detail' : selected === 'front-offices' ? 'front-office' : selected
                axios.get(`${APIURL}/api/v1/service-provider/${role}/${id}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Token ' + v,
                    },
                })
                    .then(res => {
                        if (res.status === 200) {
                            let userData;
                            if (selected === 'doctors' && res.data.doctor) {
                                userData = res.data.doctor;
                            } else if (selected === 'bdo' && res.data.bdo) {
                                userData = res.data.bdo;
                            } else if (selected === 'pro' && res.data.pro) {
                                userData = res.data.pro;
                            } else if (selected === 'allied-professional' && res.data['allied professional']) {
                                userData = res.data['allied professional'];
                            } else if (selected === 'nurse' && res.data.nurse) {
                                userData = res.data.nurse;
                            } else if (selected === 'carers' && res.data.carer) {
                                userData = res.data.carer;
                            } else if (selected === 'manager' && res.data.manager) {
                                userData = res.data.manager;
                                setSwitchState(userData.is_official_access);
                            } else if (selected === 'front-offices' && res.data.frontoffice) {
                                userData = res.data.frontoffice;
                            } else if (selected === 'higher-officials' && res.data.higherOfficial) {
                                userData = res.data.higherOfficial;
                                setSwitchState(userData.is_official_access);
                            }

                            formatPhoneNumber(userData);

                            if (userData.specializations) {
                                let specializationsArray = [];
                                if (typeof userData.specializations[0] === 'string' &&
                                    userData.specializations[0].startsWith('[') &&
                                    userData.specializations[0].endsWith(']')) {
                                    try {
                                        // Parse the string as JSON after replacing single quotes with double quotes
                                        const jsonString = userData.specializations[0].replace(/'/g, '"');
                                        specializationsArray = JSON.parse(jsonString);
                                    } catch (e) {
                                        specializationsArray = userData.specializations;
                                    }
                                } else {
                                    specializationsArray = userData.specializations;
                                }
                                const selectedSpecs = mapSelectedSpecializations(specializationsArray);
                                setSpecializations(selectedSpecs);
                            }

                            if (userData.department && Array.isArray(userData.department)) {
                                const selectedDeps = mapSelectedDepartment(userData.department);
                                setDepartment(selectedDeps);
                            }

                            setUserDatas(userData);
                            window.original_value = userData;
                        } else if (res.data.status === "error") {
                            alert.error(res.data.message);
                        } else {
                            alert.error("Error in fetching data!");
                        }
                    })
                    .catch(err => {
                        alert.error("Error in fetching data!");
                    });
            }
        }
    }, [id, specializationOptions, departmentOptions]);

    const mapSelectedOptions = (selectedSpecs, options) => {
        return selectedSpecs.map((selectedSpec) => {
            const foundOption = options.find((option) => option.label === selectedSpec);
            if (foundOption) {
                return foundOption;
            }
            return { value: selectedSpec, label: selectedSpec };
        });
    };

    const mapSelectedSpecializations = (selectedSpecs) => {
        return mapSelectedOptions(selectedSpecs, specializationOptions);
    };

    const mapSelectedDepartment = (selectedSpecs) => {
        return mapSelectedOptions(selectedSpecs, departmentOptions);
    };

    const handleMultiSelectChange = (selectedOptions, stateSetter, stateName, valueType) => {
        setError(currentState => ({
            ...currentState,
            [stateName]: ""
        }));

        if (!selectedOptions) {
            stateSetter([]);
            setUserDatas(current => ({ ...current, [stateName]: [] }));
            return;
        }

        const selectedValues = selectedOptions.map(option => valueType === 'label' ? option.label : option.value);
        stateSetter(selectedOptions);
        setUserDatas(current => ({
            ...current,
            [stateName]: selectedValues
        }));
    };

    const handleSwitchChange = (checked) => {
        setUserDatas(current => ({
            ...current,
            is_official_access: checked ? "true" : "false"
        }));
        setSwitchState(checked)
    };

    const getChangedData = (userDatas) => {
        let objectByKeys = {};
        if (window.original_value) {
            let sda_changed_keys = Object.keys(userDatas).filter(key => userDatas[key] !== window.original_value[key]);
            sda_changed_keys.forEach(key => {
                objectByKeys[key] = userDatas[key]
            });
            delete window.original_value;
        }
        return objectByKeys;
    }

    const handleSubmit = () => {
        const tokenStr = sessionStorage.getItem('usertoken');

        if (tokenStr) {

            const v = tokenStr;
            let v2 = v.replace(/["]+/g, '');


            if ((selected === 'doctors' && userDatas.name.startsWith("Dr.")) || selected !== 'doctors') {

                let dataToSend = getChangedData(userDatas);
                const role = selected === 'higher-officials' ? 'higherofficial-detail' : selected === 'nurse' ? 'nurse-detail' : selected === 'carers' ? 'carer-detail' : selected === 'allied-professional' ? 'allied-professional-detail' : selected === 'front-offices' ? 'front-office' : selected
                axios.put(`${APIURL}/api/v1/service-provider/${role}/${id}/`, dataToSend, {
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: "application/json",
                        Authorization: 'Token ' + v2
                    }
                })
                    .then(res => {


                        if (res.data.status === "success") {
                            alert.success('Updated successfully')
                            handle()

                        } else if (res.data.status === "error") {
                            closeConfirmationPopup()
                            alert.error(res.data.message);
                        }
                        else {
                            closeConfirmationPopup()
                            alert.error("Error in data update!");
                        }


                    })
                    .catch(err => {
                        closeConfirmationPopup()
                        alert.error("Error in data update!");

                    })
            }

            else {
                closeConfirmationPopup()
                alert.error("Please add Dr. as prefix of your name");
            }
        }
    }

    const handleCheckEmail = (e) => {
        setIsValidEmail(true);
        if (e.target.value !== "") {
            setError(currentstate => ({
                ...currentstate,
                email: ''
            }));
            e.preventDefault();
            let key = e.target.name;

            const data = { [e.target.name]: e.target.value }
            axios
                .post(`${APIURL}/api/v1/account/check-user/`, data, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(res => {

                    if (res.data.status === "success") {
                        if (res.data.is_existing_user === true) {

                            setError(currentstate => ({
                                ...currentstate,
                                [key]: `This ${key} is already registered!`
                            }));
                            setIsValidEmail(false);
                        }
                        else {

                            setIsValidEmail(true);

                        }
                    }
                    else {
                        setIsValidEmail(true);
                    }
                })
                .catch(err => {
                })
        }
    }


    const handleCheckExistingPhone = (e) => {
        setIsValidPhone(true);
        if (e.target.value !== "") {

            setError(currentstate => ({
                ...currentstate,
                phone: ''
            }));



            const data = {
                code: dialCode,
                mobile_number: rawPhone
            }

            axios.post(`${APIURL}/api/v1/account/check-user/`, data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(res => {

                    if (res.data.status === "success") {
                        if (res.data.is_existing_user === true) {

                            setError(currentstate => ({
                                ...currentstate,
                                phone: "This phone number is already registered!"
                            }));
                            setIsValidPhone(false);
                        }
                        else {

                            setIsValidPhone(true);

                        }



                    }
                    else {

                        setIsValidPhone(true);

                    }

                })
                .catch(err => {


                })

        }


    }

    const handleContinue = async (e) => {
        e.preventDefault();
        if (validate() && isValidEmail && isValidPhone) {
            setShowConfirmation(true);
        }
    }
    const handleConfirm = () => {
        handleSubmit();
    };
    const handleCancel = () => {
        closeConfirmationPopup()
    };

    const handleChange = e => {
        e.preventDefault();
        const val = e.target.value;
        const key = e.target.name;



        setUserDatas(currentstate => ({
            ...currentstate,
            [key]: val
        }));

    }

    const handleQualification = e => {
        e.preventDefault();
        const val = e.target.value;
        const key = e.target.name;
        let arr = [];
        if (val && val.includes(',')) {
            arr = val.split(',');
        }
        else {
            arr.push(val);
        }
        setUserDatas(currentstate => ({
            ...currentstate,
            [key]: arr
        }));

    }
    const handlePhoneInput = (value, data, event, formattedValue) => {


        setRawPhone(value.slice(data.dialCode.length));
        setDialCode(value.slice(0, data.dialCode.length))
        setUserDatas(currentstate => ({
            ...currentstate,
            phone: value

        }));
        setError(currentstate => ({
            ...currentstate,
            phone: ''
        }));


    }

    const validate = () => {

        let input = userDatas
        let errors = {};
        let isValid = true;
        setError({});

        if (!input["name"] || input["name"] === "") {
            isValid = false;
            errors["name"] = "Enter first name";
        }
        if (selected !== 'bdo' && selected !== 'pro' && selected !== 'manager' && selected !== 'higher-officials') {
            if (!input["specializations"] || input["specializations"].length === 0 || input["specializations"][0]==="") {
                isValid = false;
                errors["specializations"] = "Enter specializations";
            }
        }

        if (!input["department"] || input["department"].length === 0 || input["department"][0] === ""
        ) {
            isValid = false;
            errors["department"] = "Select departments";
        }

        if (!input['qualification'] || input['qualification'].length === 0 || input['qualification'][0] === "") {
            isValid = false;
            errors["qualification"] = "Enter qualification";
        }

        if (!input['phone'] || input['phone'] === "91") {
            isValid = false;
            errors["phone"] = "Enter phone number";
        }

        if (rawPhone.length > 0 && rawPhone.length < 10) {
            isValid = false;
            errors["phone"] = "Please enter valid phone number ";
        }
        if (typeof input["email"] !== "undefined") {

            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(input["email"])) {
                isValid = false;
                errors["email"] = "Enter a valid email address.";
            }
        }

        if (typeof input["name"] !== "undefined") {
            var pattern1 = new RegExp(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?~]/);
            if (pattern1.test(input["name"])) {
                isValid = false;
                errors["name"] = "Special Characters are not allowed";
            }
        }

        if (!input["email"]) {
            isValid = false;

            errors["email"] = "Enter email address.";
        }

        if (isValidPhone === false) {
            isValid = false;
            errors["phone"] = "Phone number already registered!";
        }
        if (isValidEmail === false) {
            isValid = false;
            errors["email"] = "Email already registered!";
        }

        setError(currentstate => ({
            ...currentstate,
            ...errors
        }));

        return isValid;

    }


    return (<div className="dialysis-container" style={{ padding: '10vh' }}>
        <h4 className="title-of-page" style={{ textTransform: 'capitalize' }}> Add {selected === 'bdo' ? 'Supervisors' : selected === 'front-offices' ? 'Front Officers' : selected} Details</h4>
        <div className="className='dialysis-formelements'">
            <div className='row'>
                <div className='input-container col-6'>
                    <label className={error.name ? 'error-label' : ''}>
                        {error.name || <>Full Name<span> *</span></>}
                    </label>
                    <input
                        className={`input1 ${error.name ? 'error-border' : ''}`}
                        type="text"
                        maxLength='30'
                        name="name"
                        value={userDatas.name}
                        onChange={handleChange}
                    />
                </div>
                <div className='input-container col-6'>
                    <label className={error.email ? 'error-label' : ''}>
                        {error.email || <>Email<span> *</span></>}
                    </label>
                    <input
                        className={`input1 ${error.email ? 'error-border' : ''}`}
                        type="email"
                        maxLength='50'
                        value={userDatas.email}
                        name="email"
                        onChange={handleChange}
                        onBlur={handleCheckEmail}
                    />
                </div>
            </div>
            <div className="row">

                <div className='input-container col-6'>
                    <label className={error.phone ? 'error-label' : ''}>
                        {error.phone || <>Contact no<span> *</span></>}
                    </label>
                    <PhoneInput
                        style={{ border: error.phone ? '1px solid red' : 'none', borderRadius: '7px' }}
                        inputProps={{
                        }}
                        country={"in"}
                        onlyCountries={['in']}
                        countryCodeEditable={false}
                        value={userDatas.phone}
                        onChange={handlePhoneInput}
                        onBlur={handleCheckExistingPhone}
                    />
                </div>
                <div className='input-container col-6'>
                    <label className={error.qualification ? 'error-label' : ''}>
                        {error.qualification || <>Qualification<span> *</span></>}
                    </label>
                    <input
                        className={`input1 ${error.qualification ? 'error-border' : ''}`}
                        type="text" maxLength='150'
                        value={userDatas.qualification}
                        name="qualification"
                        onChange={handleQualification}
                    />
                </div>
            </div>
            <div className="row" style={{height:'fit-content'}}>
                <div className='input-container col-6' style={{height:'fit-content'}}>
                    <label className={error.department ? 'error-label' : ''}>
                        {error.department || <>Access department<span> *</span></>}
                    </label>
                    <Select
                        styles={{
                            control: (provided) => ({
                                ...provided,
                                minHeight: '44px',
                                border: error.department ? '1px solid red' : '1px solid #E3E3E3',
                            })
                        }}
                        isMulti
                        value={department}
                        onChange={(data, e) =>
                            handleMultiSelectChange(data, setDepartment, 'department', 'value')
                        }
                        options={departmentOptions}
                        name="department"
                    />
                </div>
                {(selected !== 'bdo' && selected !== 'pro' && selected !== 'manager' && selected !== 'higher-officials') &&
                    <div className='input-container col-6' style={{height:'fit-content'}}>
                        <label className={error.specializations ? 'error-label' : ''}>
                            {error.specializations || <>Speciality<span> *</span></>}
                        </label>
                        <Select
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    minHeight: '44px',
                                    border: error.specializations ? '1px solid red' : '1px solid #E3E3E3',
                                })
                            }}
                            isMulti
                            value={specializations}
                            onChange={(data, e) =>
                                handleMultiSelectChange(data, setSpecializations, 'specializations', 'label')
                            }
                            options={specializationOptions}
                            name="specializations"
                        />
                    </div>}
                {(selected === 'higher-officials' || selected === 'manager') &&
                    <div className='input-container col-6' >
                        <div className="input1" style={{ marginTop: '17px' }}>
                            <label >
                                Official Access
                            </label>&nbsp;
                            <Switch
                                checkedChildren="On"
                                unCheckedChildren="Off"
                                checked={switchState}
                                onChange={handleSwitchChange} />
                        </div>
                    </div>
                }
            </div>
            <div className="row">
                <button className="save" style={{marginTop:'10px'}} onClick={(e) => { handleContinue(e) }}>Submit</button>
            </div>
        </div>
        {showConfirmation && (
            <SubmitConfirmationPopup
                message="Are you sure you want to submit the form?"
                onConfirm={handleConfirm}
                onCancel={handleCancel}
            />
        )}
    </div>
    )
}

export default EditUser;