import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useAlert } from "react-alert";
import axios from 'axios';
import { APIURL } from "../../Global";
import { UserManagementContext } from "../contexts/UserManagementContext";

function RenderSelected({ node, data }) {
    const [selected, setSelected] = useContext(UserManagementContext)
    const [error, setError] = useState('');
    const [access, setAccess] = useState('');
    const [accessData, setAccessData] = useState('')
    const [flag, setFlag] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const alert = useAlert()
    const handleRadioButton = (e) => {
        let opted = e.target.value;
        setAccess(opted);
        setError('');
    };
    useEffect(() => {
        setFlag(false)
    }, [node])

    useEffect(() => {
        if (node.length > 0) {
            const lastNode = node[node.length - 1];
            const tokenString = sessionStorage.getItem("usertoken");
            const token = tokenString.replace(/["]+/g, "");
            const user_id = selected === 'doctors' ? data.doctor_id
                : selected === "bdo" ? data.bdo_id
                    : selected === "pro" ? data.pro_id
                        : selected === "front-offices" ? data.user_id
                            : data.user

            axios.get(`${APIURL}/api/v1/service-provider/user-access/?parent_node=${lastNode.node_access}&user_id=${user_id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: "Token " + token
                },
            })
                .then((res) => {
                    if (res.data.status === 'success') {
                        if (res.data.access_list) {
                            const datas = res.data.access_list;
                            setAccessData(datas)
                            setAccess(datas);
                        } else {
                            setAccessData('')
                            setAccess('')
                        }
                    } else if (res.data.status === 'error' && res.data.message === 'Access Denied') {
                        setAccessData('')
                        setAccess('')
                    } else {
                        alert.error('Error occured')
                    }
                })
                .catch((err) => {
                    console.log(err);
                    alert.error('Error occured')
                });
        }
    }, [node, refresh]);

    const onSubmit = () => {
        if (access !== '') {
            if (node.length > 0) {
                const lastNode = node[node.length - 1];
                const tokenString = sessionStorage.getItem("usertoken");
                const token = tokenString.replace(/["]+/g, "");
                const payload = {
                    user: selected === 'doctors' ? data.doctor_id
                        : selected === "bdo" ? data.bdo_id
                            : selected === "pro" ? data.pro_id
                                : selected === "front-offices" ? data.user_id
                                    : data.user,
                    access_list: {
                        [lastNode.id]: access
                    }
                };
                axios.post(`${APIURL}/api/v1/service-provider/user-access/`, payload, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: "Token " + token
                    },
                })
                    .then((res) => {
                        if (res.status === 200) {
                            alert.success(res.data.message ? res.data.message : 'successfully updated')
                            setRefresh(!refresh)
                            setFlag(false)
                        } else {
                            alert.error("error");
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        alert.error("error");
                    });
            }
        } else {
            setError('Select required access');
        }
    };

    return (
        <div className="render-div">
            <label className="render-content1">Preview</label>
            <label className="render-content2">
                {node.map((data, index) => (
                    <React.Fragment key={index}>
                        <div style={{ display: 'inline-block' }}>
                            {data.node_name}
                        </div>
                        {index < node.length - 1 && (
                            <span style={{ margin: '0 5px' }}> &gt;&gt; </span>
                        )}
                    </React.Fragment>
                ))}
            </label>
            <label className="render-content3" style={{ color: flag ? '' : accessData === '' ? '#ED4500' : '#007205' }}>{flag ? 'Modify Access' : accessData === '' ? 'No access defined' : 'Access granted'}</label>
            <div className="access-selection">
                <label className="access-selection-label col-12">{data && data.name}</label>
                <div style={{ display: 'flex' }}>
                    {error && <label className='error-label'>{error}</label>}
                    <div className="col-4" >
                        <input
                            style={{ width: '14px', height: '14px', accentColor: (!flag && accessData !== '' && access === 'admin') ? '#007205' : '' }}
                            type="radio"
                            id="admin"
                            name="access"
                            value="admin"
                            disabled={!flag}
                            checked={access === "admin"}
                            onClick={handleRadioButton}
                        />&nbsp;&nbsp;
                        <label htmlFor="admin" style={{ margin: 0, color: (!flag && accessData !== '' && access === 'admin') ? '#007205' : '' }}>Admin</label>
                    </div>
                    <div className="col-4">
                        <input
                            style={{ width: '14px', height: '14px', accentColor: (!flag && accessData !== '' && access === 'viewer') ? '#007205' : '' }}
                            type="radio"
                            id="viewer"
                            name="access"
                            value="viewer"
                            disabled={!flag}
                            checked={access === "viewer"}
                            onClick={handleRadioButton}
                        />&nbsp;&nbsp;
                        <label htmlFor="viewer" style={{ margin: 0, color: (!flag && accessData !== '' && access === 'viewer') ? '#007205' : '' }}>Viewer</label>
                    </div>
                    <div className="col-4">
                        <input
                            style={{ width: '14px', height: '14px', accentColor: (!flag && accessData !== '' && access === 'editor') ? '#007205' : '' }}
                            type="radio"
                            id="editor"
                            name="access"
                            value="editor"
                            disabled={!flag}
                            checked={access === "editor"}
                            onClick={handleRadioButton}
                        />&nbsp;&nbsp;
                        <label htmlFor="editor" style={{ margin: 0, color: (!flag && accessData !== '' && access === 'editor') ? 'green' : '' }}>Editor</label>
                    </div>
                </div>
                <div style={{ display: 'flex' }}>
                    {flag && <button onClick={onSubmit} className="sub-button">Submit</button>}
                    {!flag && <button onClick={() => { setFlag(true) }} className="sub-button">Modify</button>}

                </div>
            </div>
            <div></div>
        </div>
    );
}

RenderSelected.propTypes = {
    node: PropTypes.array.isRequired,
    data: PropTypes.object,
};

export default RenderSelected;